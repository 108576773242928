import React, { useMemo, useEffect, createContext, useReducer } from 'react'

const storageContext = createContext();
export default storageContext;

const PERSISTED_STORAGE = 'StorageProvider';

const read = () => {
	const localRaw = window.localStorage.getItem(PERSISTED_STORAGE);
	const sessionRaw = window.sessionStorage.getItem(PERSISTED_STORAGE);

	try {
		const local = JSON.parse(localRaw) ?? {};
		const session = JSON.parse(sessionRaw) ?? {};

		return {
			...local,
			...session,
		}
	}
	catch {}

	return {}
}

const save = (state) => {
	const stateArr = Object.entries(state);

	const local = stateArr.filter(([name, item]) => item.isSession === false);	
	const session = stateArr.filter(([name, item]) => item.isSession === true);
	
	window.localStorage.setItem(
		PERSISTED_STORAGE,
		JSON.stringify(Object.fromEntries(local))
	);

	window.sessionStorage.setItem(
		PERSISTED_STORAGE,
		JSON.stringify(Object.fromEntries(session))
	);
}


const reducer = (state, { action, name, value, isSession = false }) => {
	if (typeof isSession !== 'boolean')
		throw new TypeError('isSession must be a boolean');

	switch (action) {
		case 'set': {
			return {
				...state,
				[name]: {
					value,
					isSession: state[name]?.isSession ?? isSession
				}
			}
		}

		case 'delete': {
			return Object.fromEntries(
				Object.entries(state)
					.filter(([key]) => key !== name)
			)
		}

		default: {
			return state;
		}
	}
}


export const StorageProvider = React.memo(props => {
	const [store, dispatch] = useReducer(reducer, null, read);

	useEffect(() => {
		if (store !== null) save(store);
	}, [ store ]);

	const getItem = (name) => store[name]?.value;

	const setItem = (name, value, isSession = false) => {
		dispatch({
			action: 'set',
			name,
			value,
			isSession,
		});
	}

	const deleteItem = (name) => {
		dispatch({
			action: 'delete',
			name,
		})
	}
	
	const value = useMemo(() => ({
		getItem,
		setItem,
		deleteItem,
	}), [ store ]);

	return <storageContext.Provider {...props} value={ value } />
})
