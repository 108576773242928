import React from 'react'
import { Container, makeStyles } from '@material-ui/core';
import Typography from '@ui/Typography';
import Header from '@components/Header';
import Question from '../Question';

const useStyles = makeStyles(theme => ({
  instruction: {
    marginTop: theme.spacing(4)
  },
  questionsContainer: {
		'& > div:not(:last-child)': {
        borderBottom:`1px solid ${ theme.palette.color.grey[100] }`,
		},
  }
}))

const InstrumentScreen = (props) => {
  const classes = useStyles();

  const {
    instruction = '',
    questions = [],
    tabIndexed = true,
    healthDomain,
    
    answers = {},
    onChange = () => {},
  } = props;

  const handleAnswer = (id) => (value) => onChange(id, value);

  return (
    <>
      <Header/>
      <Container maxWidth='md'>
        <Typography disableMargin className={ classes.instruction }>
          { instruction }
        </Typography>
        <Typography type='heading' variant='primary'>
          { healthDomain }
        </Typography>

        {
          <div className={classes.questionsContainer}>
            {tabIndexed && (
              questions.map(question => (
                <Question
                  key={ question.id }
                  value={ answers[question.id]?.value }
                  onChange={ handleAnswer(question.id) }
                  order={ question.questionNumber }
                  disabled={ answers[question.id]?.isConditional && !answers[question.id]?.conditionResult }
                  tabIndexed={ tabIndexed }
  
                  { ...question }
                  { ...answers[question.id] }
                />
              ))
            )}
          </div>
        }
      </Container>
    </>
  )
}

export default InstrumentScreen
