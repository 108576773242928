/* eslint-disable no-console */
import mixpanel from 'mixpanel-browser';
import { appInsights } from '../applicationinsights/applicationinsights';

mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);

const envCheck =
  process.env.NODE_ENV === 'production' ||
  process.env.NODE_ENV === 'development';

const actions = {
  identify: (id) => {
    if (envCheck) mixpanel.identify(id);
  },
  alias: (id) => {
    if (envCheck) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (process.env.REACT_APP_MIXPANEL_DEBUG) {
      console.groupCollapsed(`[Mixpanel] Tracked event: ${name}`)
      console.log(props)
      console.groupEnd();
    }
    if (envCheck) {
      mixpanel.track(name, props);  // send the event off to mixpanel first
      const telemetryInitializer = (envelope) => {
        envelope.data.customProperties = props; // attach custom properties to telemetry item
      };
      // eslint-disable-next-line valid-typeof
      if (typeof props !== undefined) appInsights.addTelemetryInitializer(telemetryInitializer);
      appInsights.trackEvent({name});
    };
  },
  register: (props) => {
    if (envCheck) mixpanel.register(props)
  },
  people: {
    set: (props) => {
      if (envCheck) mixpanel.people.set(props);
    },
  },
};

export default actions;
