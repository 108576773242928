import React, { createContext, useContext } from 'react'

import { useAPI } from '@hooks/useRequest'
import WaitFor from '@components/WaitFor'

import Assessment, { AssessmentResult } from '@components/Assessment'

import modalContext from './ModalProvider'

const assessmentModalContext = createContext();
export default assessmentModalContext;

export const AssessmentModalProvider = (props) => {
  const { children } = props;
	const axios = useAPI();
  const modal = useContext(modalContext);

  const open = (assessmentId) => {
    modal.open({
      title: 'Assessment',
      name: 'Assessment',
      
      alwaysFullScreen: true,
      children: (
        <Assessment
          key='default'
          assessmentId={ assessmentId }
        />
      ),
    })
  }

  const openAnswers = (answerSetId, instrumentId) => {
    modal.open({
      name: 'results',

      alwaysFullScreen: true,
      onClose: () => modal.close(),
      children: (
        <WaitFor
          key='default'
          promise={() => axios.get(`/AssessmentResult/Id/${ answerSetId }`)}

          onSuccess={data => (
						<AssessmentResult
							answerSets={ data }
							initialInstrument={ instrumentId }
						/>
					)}
        />
      )
    })
  }

  //  TODO: Refactor global modals
  //  Removed memo here could cause some interesting, 'double render' type errors.
  //  Its probably going to cause downstream errors, but I can't predict what.
  const ctx = {
    ...modal,
    open,
    openAnswers,
  }

  return (
    <assessmentModalContext.Provider value={ ctx }>
      { children }
    </assessmentModalContext.Provider>
  )
}
