import React from 'react'
import { DialogActions as MuiDialogActions, makeStyles, withStyles } from '@material-ui/core'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Button from '@ui/Button'
import SegmentedBar from '@components/SegmentedBar'


const DialogActions = withStyles(theme => ({
  root: ({ maxWidth }) => ({
    position: 'sticky',
    bottom: 0,
    width: '100%',
    maxWidth: theme.breakpoints.values[maxWidth],
    margin: '0 auto',
    backgroundColor: theme.palette.color.white,
    padding: 0,
    zIndex: 10,
  })
}))((props) => <MuiDialogActions {...props}/>);

const useStyles = makeStyles(theme => ({
  collapse: ({ centered }) => ({
    display: 'flex',
    justifyContent: (centered) ? 'center' : 'space-between',
    alignItems: 'center',
    boxSizing: 'unset',
    width: '100%',
    margin: 0,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),

    transition: '.15s ease-in-out',
    transitionProperty: 'padding, height',
    height: theme.spacing(12),
  }),
  expand: {
    cursor: 'pointer',
  },
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
}))

const Footer = (props) => {
  const {
    isLast = false,
    disabled = false,
    submitting = false,
    isOpen = false,

    maxWidth = "sm",
    centered = false,
    progress = {},

    onOpen = () => {},
    onClickPrev,
    onClickNext,
  } = props;

  const classes = useStyles({centered});

  return (
    <DialogActions maxWidth={maxWidth}>
      <SegmentedBar
        className={ classes.progress }
        { ...progress }
      />

      <div className={ classes.collapse }>
        {
          isOpen ? (
            <>
              {
                onClickPrev && 
                <Button
                  onClick={ onClickPrev }
                  theme='secondary'
                >
                  Exit
                </Button>
              }

              {
                onClickNext && 
                <Button
                  onClick={ onClickNext }
                  disabled={ submitting || disabled }
                  isLoading={ submitting }
                >
                  { isLast ? 'Submit' : 'Next' }
                </Button>
              }
            </>
          ) : (
            <KeyboardArrowUpIcon className={ classes.expand } onClick={ onOpen } />
          )
        }
      </div>
    </DialogActions>
  )
}

export default Footer
