import { useContext, useEffect } from 'react'
import storageContext from '@context/StorageProvider';

const usePersistState = (name, initialValue, isSession = false) => {
  const { getItem, setItem } = useContext(storageContext);

  const value = getItem(name);
  const setValue = value => setItem(name, value, isSession);

  //  set initial value if not already @mount
  useEffect(() => {
    if (!value) setItem(name, initialValue, isSession);
  }, []);

  return [
    value,
    setValue
  ]
}

export default usePersistState