import React, { useState } from 'react'
import SwipeableViews from 'react-swipeable-views';

import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MobileStepper from '@material-ui/core/MobileStepper';
import Container from '@material-ui/core/Container';

import Typography from '@ui/Typography'
import Button from '@ui/Button'

import SlideButton from '../SlideButton'
import Mixpanel from '@utils/mixpanel'
import Header from '@components/Header';

import slides from '../slideData.json'

const slideData = slides.data

const sliderSidebar = {
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  top: 0,
  bottom: 0,
}

const useStyles = makeStyles((theme) => ({
  slide: {
    textAlign: 'center',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    margin: '0 auto',
  },
  slideImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 320,
    '& > img': {
      maxHeight: 300,
      maxWidth: 280,
    }
  },
  slideImage0: {
    transform: 'scale(.9)'
  },
  slideImage2: {
    transform: 'translateY(-30px)'
  },
  previous: {
    ...sliderSidebar,
    left: theme.spacing(2)
  },
  next: {
    ...sliderSidebar,
    right: theme.spacing(2),
  },
  stepper: {
    margin: theme.spacing(3.75, 0),
    justifyContent: 'space-around',
    backgroundColor: 'transparent',
    '& .MuiMobileStepper-dot': {
      width: '12px',
      height: '12px',
      margin: theme.spacing(0, 1.75),
      backgroundColor: theme.palette.color.grey[40],
    },
    '& .MuiMobileStepper-dotActive': {
      backgroundColor: theme.palette.color.navy[100],
    },
  },
  centeredButton: {
    margin: 'auto',
    display: 'block',
  }
}))

const Intro = (props) => {
  const {
    handleCompleteIntro
  } = props

  const classes = useStyles();

  const [activeSlide, setActiveSlide] = useState(0);


  const SLIDES_LENGTH = slideData.length;
  const START_OF_SLIDES = (activeSlide === 0)
  const END_OF_SLIDES = (activeSlide === SLIDES_LENGTH - 1)

  const theme = useTheme()
  const showSlideControls = useMediaQuery(theme.breakpoints.up('md'))

  const mixpanelTrackSlide = (slide, method) => {
    Mixpanel.track('View Explainer Slide', {
      slide: slide,
      method: method,
    })
  }

  const handleNext = () => {
    if (!END_OF_SLIDES) setActiveSlide(activeSlide + 1)
    mixpanelTrackSlide(activeSlide + 1, 'right arrow')
  }

  const handlePrev = () => {
    if (!START_OF_SLIDES) setActiveSlide(activeSlide - 1)
    mixpanelTrackSlide(activeSlide - 1, 'left arrow')
  }

  const handleSlideChange = (slide) => {
    setActiveSlide(slide)
    mixpanelTrackSlide(slide, 'swipe')
  }

  const handleGetStarted = () => {
    Mixpanel.track('Clicked Get Started', {
      slide: activeSlide
    })
    handleCompleteIntro()
  }

  return (
    <>
      <Header promoHeader disableMargin/>
      <Container maxWidth='md'>
          <SwipeableViews
            axis='x'
            index={activeSlide}
            onChangeIndex={handleSlideChange}
            enableMouseEvents
          >
            {
              slideData.map((slide, index) => {
                return (
                  <div key={slide.title} className={classes.slide}>
                    <figure className={classes.slideImage}>
                      <img alt={slide.title} className={classes[`slideImage${index}`]} src={`${process.env.REACT_APP_URL}/images/${slide.image}`}/>
                    </figure>
                    <Typography type='heading' variant='primary'>{slide.title}</Typography>
                    <Typography disableMargin type='body' variant='primary'>{slide.body}</Typography>
                  </div>
                )
              })
            }
          </SwipeableViews>
          <MobileStepper
            variant='dots'
            steps={SLIDES_LENGTH}
            position='static'
            activeStep={activeSlide}
            className={classes.stepper}
            nextButton={
              showSlideControls && <aside className={classes.next}>
                <SlideButton disabled={END_OF_SLIDES} theme='callout' aria-label='next slide' onClick={handleNext}><ChevronRightIcon/></SlideButton>
              </aside>
            }
            backButton={
              showSlideControls && <aside className={classes.previous}>
                <SlideButton disabled={START_OF_SLIDES} theme='callout' aria-label='previous slide' onClick={handlePrev}><ChevronLeftIcon/></SlideButton>
              </aside>
            }
          />
          <Button className={classes.centeredButton} onClick={handleGetStarted}>Let's Go</Button>
        </Container>
    </>
  )
}

export default Intro
