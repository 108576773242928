import { useMemo, useState, lazy } from 'react'

import resolveExpression from '@utils/conditionals'

const questionTypeLookup = {
	SingleSelectDropdown: 'int',
	RatingScale: 'int',
	IntegerInput: 'int',
	DurationInput: 'int',
}

export const QuestionTypeMap = {
	'SingleSelectDropdown': lazy(() => import('./Question/RadioInput')), //	NOTE: We get type of question as SingleSelectDropdown from BE so we do one to one mapping to RadioInput component, if in future BE type is changed to RadioImput then FE need to change accordingly
	'RatingScale': lazy(() => import('./Question/RatingScale')),
	'IntegerInput': lazy(() => import('./Question/IntegerInput')),
	'DurationInput': lazy(() => import('./Question/DurationInput')),
}


const useAnswers = (instrument) => {
	const [ answerValues, setAnswerValues ] = useState();

	const answers = useMemo(() => {
		if (!answerValues) return {};

		const entries = Object.entries(answerValues);

		const answerMap = entries.map(([id, value]) => {
			const q = instrument.questions.find(q => q.id === id);

			const o = {
				value,
				isConditional: Array.isArray(q.conditions),
				// TODO: isOptional; optional questions are not used
				//			 ignoring the BE until respurposed.
				isOptional: false,
			}

			if (o.isConditional) {
				o.conditionResult = resolveExpression(answerValues, q.conditions);

				if (!o.conditionResult && value !== null)
					Promise.resolve().then(() => 
						handleAnswer(id, null)
					)
			}
			
			return [id, o]
		});

		return Object.fromEntries(answerMap);

	}, [ answerValues ]);

	//	when instrument changes:
	//	get initial value if there is one,
	//	otherwise generate null values
	useMemo(() => {
		if (!instrument) return;

		const defaultAnswers = Object.fromEntries(
			instrument.questions.map(q => [q.id, q.initialValue ?? null])
		);

		setAnswerValues(defaultAnswers)
	}, [ instrument?.id ])

  //  handle changing the value for an answer
  const handleAnswer = (answer, value = null) => {
		setAnswerValues({
			...answerValues,
			[answer]: value
		});
	}

	//	calculate if the instrument is able to be submitted
	const canSubmit = useMemo(() => {
		if (!answers) return false;

		return Object.values(answers).every(answer => {
			if (answer.isConditional && !answer.conditionResult) return true
			if (answer.isOptional) return true
			if (answer.value !== null) return true

			return false
		});
	}, [ answers ])


	//	lookup and return question type
	const valueTypeFor = question => questionTypeLookup[question.type];


  return {
    answers,
		canSubmit,

    handleAnswer,
		valueTypeFor,
  }
}

export default useAnswers