import React from 'react';

import {
  makeStyles,
  Theme
} from '@material-ui/core';
import clsx from 'clsx'
import { WaveDataURI,SparkleDataURI } from '../../constants/assets/index';
import Typography from '@ui/Typography';

const isEmpty = (variable: React.ReactNode | string | undefined) => {
  if( typeof variable === 'undefined' || variable === null ){
    return true;
  }
  return false;
}

interface StyleProps { hasNoContent: boolean; disableMargin?: boolean;  promoHeader?: boolean; }

interface THeader extends Pick<StyleProps, 'disableMargin' | 'promoHeader' > {
  title?: string;
  subtitle?: string;
  disableSparkle?: boolean;
  className?: string;
  children?: React.ReactNode;
}


const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  header: {
    display: 'grid',
    gridColumn: theme.grid.page.fullBleed,
    gridTemplateColumns: theme.grid.page.containerMobile,
    position: 'relative',
    minHeight: ({hasNoContent, promoHeader}) => promoHeader ? theme.spacing(10) : (hasNoContent ? theme.spacing(26) : theme.spacing(40)),
    backgroundColor: theme.palette.color.daybreak[40],
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: theme.grid.page.containerDesktop,
      marginBottom: ({disableMargin}) => disableMargin ? theme.spacing(0) : theme.spacing(5),
    },
  },
  headerContent: {
    gridColumn: theme.grid.page.content,
    marginBottom: theme.spacing(3.75),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingBottom: theme.spacing(5.75),

  },
  wave: {
    backgroundImage: `url("${WaveDataURI}")`,
    gridColumn: 'inherit',
    minWidth: '100%',
    position: 'absolute',
    bottom: theme.spacing(-.25),
    right: 0,
    top: 0,
    left: theme.spacing(-3.75),
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '2%',
    backgroundPositionY: 'bottom',
    [theme.breakpoints.up('md')]: {
      left: 0,
      bottom: theme.spacing(-1.25),
      backgroundPositionX: 'center',
    },
    [theme.breakpoints.up('xl')]: {
      backgroundSize: '100vw',
    }
  },
  dataContent: {
    zIndex: 1,
    width: '100%'
  },
  sparkle:{
    backgroundImage: ({hasNoContent}) => hasNoContent ? `url("${SparkleDataURI}")` : 'none',
    minWidth: '100%',
    position: 'absolute',
    bottom: theme.spacing(-3.12),
    right: 0,
    top: 0,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'right',
    backgroundPositionY: 'bottom',
    backgroundSize: '30%',
    [theme.breakpoints.up('md')]: {
      backgroundImage: ({hasNoContent}) => hasNoContent ? 'none' : `url("${SparkleDataURI}")`,
      backgroundSize: '22%',
      bottom: theme.spacing(-5),
    },
    
  },
  title: {
    marginTop: theme.spacing(5.6),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
}))


const Header: React.FC<THeader> = (props) => {
  const {
    title,
    subtitle,
    disableMargin = false,
    disableSparkle = false,
    promoHeader = false,
    className,
    children,
  } = props;

  const hasNoContent = isEmpty(title) && isEmpty(subtitle) && isEmpty(children);

  const classes = useStyles({ disableMargin, hasNoContent, promoHeader });

  return (
    <div className={clsx(classes.header, className,{[classes.promoHeader]: promoHeader})}>
        <div className={classes.headerContent}>
          <div className={classes.dataContent}>
            <Typography className={ classes.title } type='heading' variant='primary'>
              { title }
            </Typography>
            <Typography type='body' variant='primary'>
              { subtitle }
            </Typography>
            { children }
          </div>
          {!disableSparkle && <div className={classes.sparkle}/>}
        </div>
      <div className={classes.wave}/>
    </div>
  );
}

export default Header;
