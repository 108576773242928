import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

import HorizontalScroll from '@components/HorizontalScroll';
import Typography from '@ui/Typography';

interface StyleProps {}
const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  root: {
    marginBottom: theme.spacing(4),
  },
  chip: {
    borderRadius: theme.spacing(2),
  },
  navList: {
    listStyle: 'none',
    display: 'flex',
    paddingInlineStart: 0,
    paddingBottom: theme.spacing(4),
    margin: 0,
    overflowY: 'auto',
    '& > *': {
      marginRight: theme.spacing(0.5),
    },
  },
}), { name: 'ChipNavigation' });

type ChipNavigationProps = {
  items: string[];
  selected?: string;
  onChange: (item: string) => void;
}

const ChipNavigation: React.FC<ChipNavigationProps> = (props) => {
  const {
    items = [],
    selected,
    onChange,
  } = props;

  const classes = useStyles();
  return (
    //  @ts-ignore forward ref errors
      <HorizontalScroll>
        <ul className={classes.navList}>
          {
            items.map((item) => {
              const isActive = (item === selected);

              return (
                <li key={item}>
                  <Chip
                    variant='outlined'
                    className={classes.chip}
                    color={ isActive ? 'primary' : 'default' }
                    label={(
                      <Typography disableMargin
                        type='label'
                        variant='secondary'
                        component='span'>
                        { item }
                      </Typography>
                    )}
                    onClick={ () => onChange?.(item) }
                  />
                </li>
              );
            })
          }
        </ul>
      </HorizontalScroll>
  );
}

export default ChipNavigation;