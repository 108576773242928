import {useMemo} from 'react';

//	finds nearest whitespace to char at [limit],
//	then trims and ignores trailing non-char chars.
//	so: 'Hello, world' with a limit of 4 would be 'Hello...'
const useLimit = (str: string, len: number, cap: string = '…') => {
	return useMemo(() => {
		if (str.length < len) return str;

		const prevSpace = str.substr(0, len).lastIndexOf(' ');
		const prevDelta = Math.abs(len - prevSpace);

		const nextSpace = len + str.substr(len).indexOf(' ');
		const nextDelta = Math.abs(len - nextSpace);

		let final = str.substr(0, prevDelta < nextDelta ? prevSpace : nextSpace);
		final = final.replace(/\W+$/i, '')

		return final + cap;
	}, [str, len, cap])
}

export default useLimit;