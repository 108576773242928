import React from 'react'
import { makeStyles } from '@material-ui/core'


import Suggestions from './Suggestions'
import FocusedRecommendations from './FocusedRecommendations'

import type { IRecommendationData, IRecommendationDataMark } from './Types'

const useStyles = makeStyles(theme => ({
	root: {
		paddingBottom: theme.spacing(4)
	},
}), { name: 'Recommendations' })


interface RecommendationsProps {
	data: IRecommendationData | IRecommendationDataMark;
	insideAssessment?: true;
	borderWidth?: number;
	onNavigate?: () => void;
	onViewAnswers?: (instrumentId: string, answerSetId: string) => void;
}

const Recommendations: React.FC<RecommendationsProps> = (props) => {
	const {
		data,
		insideAssessment,
		borderWidth,
		onNavigate,
		onViewAnswers,
	} = props;

	const classes = useStyles();

	return (
		<div className={classes.root}>
			{
				!data.recommendingMark && !data.assessmentMark ?
					<Suggestions
						borderWidth={borderWidth}
						focusDomain={data.focusedHealthDomain}
						recommendations={data.recommendations}
					/>
					: // fall back, if there is no grade/mark
					<FocusedRecommendations
						data={data as IRecommendationDataMark}
						insideAssessment={insideAssessment}
						onViewAnswers={onViewAnswers}
						onNavigate={onNavigate}
					/>
			}
		</div>
	)
}

export default Recommendations
