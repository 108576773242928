import React from 'react';
import { makeStyles } from '@material-ui/core';

import Header from '@components/Header';
import BackButton from '@components/BackButton';
import Typography from '@ui/Typography'

const useStyles = makeStyles(theme => ({
  layout: {
    display: 'flex',
  },
  left: {
    marginRight: 'auto',
  },
  dialogHero: {
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: `[bleedStart] 0 [menuEnd] minmax(2rem, 1fr) [mainStart] min(960px, calc(100% - 4rem - 200px)) [mainEnd] minmax(2rem, 1fr) [bleedEnd]`,
    },
  },
  icon: {
    marginLeft: theme.spacing(2),
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  heading: {
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
	back: {
		marginBottom: 0
	}
}))

const DialogHero = (props) => {
  const {
		handleBack,
    title,
    isBackToHistory
  } = props;

  const classes = useStyles();  
  return (
    <Header className={classes.dialogHero}>
      <section className={classes.articleHeader}>
        <BackButton handleBack={handleBack} includeBackArrow isBackToHistory={isBackToHistory}/>
        <Typography type='heading' variant='primary' className={classes.heading}>
          {title}
        </Typography>
      </section>
    </Header>
  )
}

export default DialogHero;
