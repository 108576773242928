import React from 'react'

import {
  CardContent,
  makeStyles,
} from '@material-ui/core';

import Typography from '@ui/Typography';

import Dial from '../DialResult/Dial';

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    }
  },
  text: {
    flex: '1 0',
  },
  title: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(3),
    }
  },
  grade: {
    lineHeight: '1.5rem',
    marginRight: theme.spacing(.5),
  },
  action: {
    textDecoration: 'underline',
    fontWeight: '800',
    display: 'block',
  }
}), {name:'InlineResult'});

const InlineResult = (props) => {
  const {
    title,
    grade,
    date,
    hasAction,
  } = props;

  const classes = useStyles({ hasDate: !!date });

  return (
    <>
      <CardContent className={classes.content}>
        <div className={classes.text}>
          <Typography data-test='InlineResult-Title' disableMargin type='heading' variant='tertiary' className={classes.title}>
            {title}
          </Typography>
          {
            (date) &&
            <Typography disableMargin
              component='span'
              type='body'
              variant='secondary'
              data-test='InlineResult-Date'
            >
              Last taken {date}
            </Typography>
          }
          {
            (hasAction) &&
            <Typography disableMargin
              className={classes.action}
              component='span'
              type='body'
              variant='secondary'
              data-test='InlineResult-Action'
            >
              View results
            </Typography>
          }
        </div>
        {
          (grade) &&
          <Dial
            grade={grade.value}
            label={grade.label}
          />
        }
      </CardContent>
    </>
  )
}

export default InlineResult
