import React from 'react'
import { makeStyles } from '@material-ui/core'

import { dateFormats, toDateString } from '@utils/date-time';

import Typography from '@ui/Typography'
import { ResultCardList } from '@components/ResultCard';

const useStyles = makeStyles(theme => ({
  spacing: {
    marginTop: theme.spacing(4)
  },
	card: {
		marginBottom: theme.spacing(2.5)
	}
}), {name:'HistoricAssessmentResults'})

const HistoricAssessmentResults = (props) => {
	const classes = useStyles();

	const {
		answerSetId,
		marks = [],
		assessmentTitle,
		assessmentSubmissionTime,

		onClick,
	} = props;

	return (
		<>
			{
				assessmentTitle && (
					<Typography disableMargin className={ classes.spacing } type='heading' variant='secondary'>
						{ assessmentTitle }
					</Typography>
				)
			}

			{
				assessmentSubmissionTime && (
					<Typography disableMargin type='body' variant='secondary'>
						Taken on { toDateString(assessmentSubmissionTime ?? '', dateFormats.DATE_LONG) }
					</Typography>
				)
			}


			<div className={ classes.spacing }>
				<ResultCardList
					className={ classes.card }
					answerSetId={ answerSetId }
					marks={ marks }
					onClick={ onClick }
					noDate
				/>
			</div>
		</>
	)
}

export default HistoricAssessmentResults
