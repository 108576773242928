import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
	base: {
		background: theme.palette.color.mint[20],
		padding: theme.spacing(3, 4.5, 0),
		borderRadius: theme.spacing(4, 4, 0, 4),
		overflow: 'hidden',
		marginBottom: theme.spacing(3),
	}
}), { name: 'FeatureCard' })

interface FeatureCardProps {
	children: React.ReactNode;
	className?: string;
}
const FeatureCard: React.FC<FeatureCardProps> = (props) => {
	const { className, children } = props;
	const classes = useStyles();

	return (
		<div data-test="FeatureCard" className={ clsx(classes.base, className) }>
		 	{children}
		</div>
	)
}

export default FeatureCard
