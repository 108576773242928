import React from 'react';

import { 
  HomeOutlined,
  CheckCircleOutlined,
  AssessmentOutlined,
  SchoolOutlined,
  BookOutlined
} from '@material-ui/icons';


import Offline from './pages/Offline';
import ErrorSplash from '@components/ErrorSplash';
import Button from '@ui/Button';

const Home = React.lazy(() => import('./pages/Home'));
const About = React.lazy(() => import('./pages/About'));
const Activities = React.lazy(() => import('./pages/Activities'));
const UncommittedList = React.lazy(() => import('./pages/Activities/UncommittedList'));
const UncommittedActivity = React.lazy(() => import('./pages/Activities/UncommittedActivity'));
const CustomActivity = React.lazy(() => import('./pages/Activities/CustomActivity'));
const HealthTracker = React.lazy(() => import('./pages/HealthTracker'));
const Wellbook = React.lazy(() => import('./pages/Wellbook'));
const Learn = React.lazy(() => import('./pages/Learn'));
const LearnResource = React.lazy(() => import('./pages/LearnResource'));
const SignupPage = React.lazy(() => import('./pages/Signup'));
const LocalSignInPage = React.lazy(() => import('./pages/Localsignin'));
const FindAccountPage = React.lazy(() => import('./pages/FindAccount'));
const KitchenSink = React.lazy(() => import('./pages/KitchenSink'));

const routes = [
  {
    path: '/',
    label: 'Home',
    icon: <HomeOutlined />,
    exact: true,
    component: Home,
  },
  {
    path: '/activities',
    label: 'Activities',
    icon: <CheckCircleOutlined />,
    exact: true,
    component: Activities,
  },
	{
		path: '/activities/uncommitted',
		exact: true,
		component: UncommittedList,
	},
	{
		path: '/activities/uncommitted/custom',
		exact: true,
		component: CustomActivity,
	},
	{
		path: '/activities/uncommitted/:activityId',
		exact: true,
		component: UncommittedActivity,
	},
	{
		path: '/activities/*',
    exact: true,
    render: () => (
      <ErrorSplash
        errorCode='404'
        heading='404'
        subheading='This page could not be found.'
        action={ <Button href='/activities'>Go back to your activities</Button> }
      />
    )
	},
  {
    path: '/health-tracker',
    label: 'Health Tracker',
    icon: <AssessmentOutlined />,
    exact: true,
    component: HealthTracker,
  },
  {
    path: '/offline',
    component: Offline,
  },
  {
    path: '/learn',
    label: 'Learn Library',
    icon: <SchoolOutlined />,
    exact: true,
    component: Learn,
  },
  {
    path: '/learn/:modality/:id',
    exact: true,
    component: LearnResource,
  },
  {
    path: '/learn/*',
    exact: true,
    render: () => (
      <ErrorSplash
        errorCode='404'
        heading='404'
        subheading='This page could not be found.'
        action={ <Button href='/learn'>Go back to the learn library</Button> }
      />
    )
  },
  {
    path: '/wellbook',
    label: 'Wellbook',
    icon: <BookOutlined />,
    exact: true,
    component: Wellbook,
  },
  {
    path: '/about',
    exact: true,
    component: About,
  },

  //  Testing routes
  //  TODO: remove these routes, when we implement storybook.
  {
    path: '/kitchensink/:example',
    component: KitchenSink,
    isPublic: true,
  },
  {
    path: '/kitchensink',
    component: KitchenSink,
    exact: true,
    isPublic: true,
  },

  {
    path: '/signup',
    component: SignupPage,
    isPublic: true,
  },
  {
    path: '/localsignin',
    component: LocalSignInPage,
    isPublic: true,
  },
  {
    path: '/findaccount',
    component: FindAccountPage,
    isPublic: true,
  },

  //  404 fallback
  {
    path: '*',
    exact: true,
    isPublic: true,
    render: () => (
      <ErrorSplash
        errorCode='404'
        heading='404'
        subheading='This page could not be found.'
        action={ <Button href='/'>Go back to the Home page</Button> }
      />
    )
  },
]

export default routes
