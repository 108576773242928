import React from 'react'

const WithView = (props, ref) => {
  const { component: Component, child, ...otherProps } = props;
  
  return (
    <Component ref={ref} { ...otherProps }>
      { child }
    </Component>
  )
}

export default React.forwardRef(WithView)
