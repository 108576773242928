import React, { useMemo } from 'react'
import {
	Collapse,
	Container,
	makeStyles,
	Theme
} from '@material-ui/core'

import Typography from '@ui/Typography'
import { QuestionTypeMap } from '../useAnswers';

import type { BasicQuestionType } from './Types'

interface styleProps { disabled?: true }
const useStyles = makeStyles<Theme, styleProps>(theme => ({
	root: {
		marginTop: theme.spacing(3.5),
		marginBottom: theme.spacing(3.5),
	},
	label: {
		marginBottom: theme.spacing(2),
  },
  helperText: {
    color: ({ disabled }) => (
      disabled ?
        theme.palette.grey[600]:
       	theme.palette.grey[700]
    )
	},
	gridAssessmentImages: {
		marginBottom: theme.spacing(3)
	},
	assessmentImages: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
		textAlign: 'center',
	},
	asset: {
		display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',

		'& img': {
			width: '100%'
		}
	}
}));



const UnknownQuestionType: React.FC<{ type: string }> = props => (
	<Typography disableMargin>
		Unknown Question Type '{props.type}'
	</Typography>
)

export type QuestionProps = BasicQuestionType;
const Question: React.FC<QuestionProps> = props => {
	const {
		type,
		disabled,
		questionNumber,
		text,
		helperText,
		assessmentImages = [],

		isConditional,
		conditionResult,
	} = props

	const isQuestionAvailable = useMemo(() => {
		if (!isConditional) return true;
		return conditionResult;
	}, [ conditionResult ]);

	const Comp:any = useMemo(() => {
		if (!QuestionTypeMap[type]) return UnknownQuestionType;
		return QuestionTypeMap[type];
	}, [ type ]);

	const classes = useStyles({ disabled });
	return (
		<Collapse in={ isQuestionAvailable }>
			<Container disableGutters className={ classes.root }>
				<div className={classes.label}>
						<Typography type='body' variant='primary'>
							{`${questionNumber ? (questionNumber + '. ') : ''}${text}`}
						</Typography>

					{
						helperText && (
							<Typography type='body' variant='secondary' className={classes.helperText}>
								{helperText}
							</Typography>
						)
					}
				</div>

				{
					(assessmentImages?.length ?? 0) > 0 && (
						<div className={classes.assessmentImages}>
							{
								assessmentImages.map(asset => (
									<div className={classes.asset}>
										<img
											className={classes.assetImage}
											src={`${process.env.REACT_APP_CONTENT_URL}${asset.imageUrl}`}
											alt={asset.altText}
										/>

										<Typography disableMargin component='p'>
											{asset.label}
										</Typography>
										<Typography disableMargin component='p'>
											{asset.description}
										</Typography>
									</div>
								))
							}
						</div>
					)
				}

				<React.Suspense fallback={<></>}>
					<Comp { ...props } />
				</React.Suspense>
			</Container>
		</Collapse>
	)
}

export default Question
