import React from 'react'

import {
  CardContent,
  CardActions,
  makeStyles
} from '@material-ui/core'
import Typography from '@ui/Typography'

import Dial from './Dial';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  dial: {
    height: '70px',
    marginBottom: theme.spacing(2)
  },
	cardContent: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
    padding: theme.spacing(3, 2, 2),
    
		userSelect: 'none',
	},
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(0, 2, 3)
  },
  action: {
    textDecoration: 'underline',
    fontWeight: '800',
  }
}), {name: 'DialResult'})

const DialResult = (props) => {
  const {
    title,
    grade,
    hasAction,
    date,
  } = props;
	const classes = useStyles();

	return (
    <article data-test='DialResult' className={classes.root}>
      <CardContent className={ classes.cardContent }>
        <div className={classes.dial}>
          <Dial grade={grade.value} label={grade.label} />
        </div>
        <Typography data-test='DialResult-Title' disableMargin type='heading' variant='tertiary'>{ title }</Typography>
      </CardContent>
      {
        (date) &&
        <CardActions
          disableSpacing
          className={ classes.actionsContainer }>
          <Typography disableMargin
            component='p'
            type='body'
            variant='secondary'
            data-test='DialResult-LastTaken'
          >
            Last taken {date}
          </Typography>
          {
            (hasAction) &&
            <Typography disableMargin
              className={classes.action}
              component='p'
              type='body'
              variant='secondary'
              data-test='DialResult-Action'
            >
              View results
            </Typography>
          }
        </CardActions>
      }
    </article>
	)
}

export default DialResult
