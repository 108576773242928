import authContext from '@context/AuthProvider';
import { dateAsIso } from '@utils/date-time';
import axios from 'axios';
import { useContext, useMemo } from 'react';

interface IRequest {
	method: 'GET' | 'POST'
	baseURL: string
	url: string
	data?: any,
	headers?: Record<string, string>
}

type AccessPromise = (() => Promise<string | void>) | undefined;

const hydrateConfig = async (config: IRequest, getAccess: AccessPromise): Promise<IRequest> => {
	if (config.baseURL === process.env.REACT_APP_API_URL) {
		const token = await getAccess?.();
		if (token) {
			return {
				...config,
				headers: {
					...config.headers,
					Authorization: 'Bearer ' + token,
					'X-Local-Time': dateAsIso(Date.now()),
				}
			}
		}
	}

	return config;
}

const request = async <T,>(config: IRequest, getAccess: AccessPromise, fallback?: T): Promise<T | undefined> => {	
	const hydratedConfig = await hydrateConfig(config, getAccess);
	const resp = await axios(hydratedConfig);
	switch (resp.status) {
		case 200: return resp.data as T;
		case 204: return fallback;
		default: {
			throw new Error('Unhandled HTTP status code: ' + resp.status);
		}
	}
}

const useRequest = <RespData = any>(baseURL: string) => {
	const { data } = useContext(authContext);
	const { getAccess } = data ?? {}

	const post = async (url: string, data: any, fallbackData?: RespData) => request<RespData>({
		method: 'POST',
		baseURL,
		url,
		data,
	}, getAccess, fallbackData)

	const get = async (url: string, fallbackData?: RespData) => request<RespData>({
		method: 'GET',
		baseURL,
		url,
	}, getAccess, fallbackData);

	return useMemo(() => ({
		post,
		get
	}), [baseURL, getAccess]);
}

// TODO: remove these any values. That would force the author to know what they are doing, lol.
//				but I dont, so not yet.
export const useAPI = <RespData = any>() => useRequest<RespData>(process.env.REACT_APP_API_URL);
export const useContent = <RespData = any>() => useRequest<RespData>(process.env.REACT_APP_CONTENT_URL);

export default useRequest;