import { MutableRefObject, useEffect, useMemo, useState } from "react";

type Fn = () => void;
const listeners = new Set<Fn>();

window.addEventListener('resize', () => (
	Array.from(listeners).forEach(cb => cb())
));

const useResponsively = (ref: MutableRefObject<HTMLElement | undefined | null>) => {
	const [bounds, setBounds] = useState<DOMRect>(new DOMRect());

	useEffect(() => {
		const listener = () => {
			if (!ref?.current){
				setBounds(document.body.getBoundingClientRect());
			} 
			else {
				setBounds(ref.current.getBoundingClientRect());
			}
		}

		listener();
		listeners.add(listener);
		return () => { listeners.delete(listener) };
	}, [ref?.current]);

	return useMemo(() => bounds, [bounds]);
}

export default useResponsively;