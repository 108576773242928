import React, { useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Typography from '@ui/Typography'
import { Button, ButtonGroup } from '@ui/Button'
import Header from '@components/Header';

import installableContext from '@context/InstallableProvider'

const useStyles = makeStyles((theme) => ({
  slide: {
    textAlign: 'center',
    margin: `0 auto ${theme.spacing(5)}px`,
    '& > img': {
      marginBottom: theme.spacing(5)
    }
  },

  slideImage: {
    marginBottom: theme.spacing(5),
    height: '320px',
    '& > img': {
      maxHeight: '300px',
      transform: 'scale(.8) translateY(10px)'
    }
  },

  buttonGroup: {
    width: 'auto',
    display: 'block',
    margin: 'auto',
    textAlign: 'center',
    '& button': {
      minWidth: 150,
			borderColor: theme.palette.color.navy[100],
			color: theme.palette.color.navy[100],
    }
  },

  textColor: {
    color: theme.palette.color.navy[100],
  },
}))

const InstallSlide = (props) => {
	const {
    handleNextView,
  } = props

	const { isInstallable, onInstall } = useContext(installableContext);

  const classes = useStyles();

	const handleClickInstall = () => onInstall(handleNextView);

	useEffect(() => {
		if (!isInstallable) handleNextView();
	}, []);

	return (
		<>
      <Header promoHeader disableMargin/>
      <div className={classes.slide}>
        <figure className={classes.slideImage}>
          <img alt='Lets get started' src={`${process.env.REACT_APP_URL}/images/pin.svg`}/>
        </figure>

        <Typography disableMargin className={classes.textColor} type='heading' variant='tertiary'>
					Add to home screen
				</Typography>

        <Typography disableMargin className={classes.textColor} type='body' variant='secondary'>
					Easily access Innowell by adding a shortcut to your home screen
				</Typography>
      </div>
      <ButtonGroup balance className={classes.buttonGroup}>
        <Button theme='callout' onClick={handleNextView}>Later</Button>
        <Button theme='primary' onClick={handleClickInstall}>Add</Button>
      </ButtonGroup>
    </>
	)
}

export default InstallSlide
