import React from 'react';

import {
  makeStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridColumn: theme.grid.page.fullBleed,
    gridTemplateColumns: theme.grid.page.containerMobile,
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: theme.grid.page.containerDesktop,
    },
  },
  content: {
    gridColumn: theme.grid.page.content,
  }
}), { name: 'FullBleed' });

const FullBleed = (props, ref) => {
  const {
    wrapper: Wrapper,
    children,
    className,
    noWrapper = false,
  } = props;

  const theme = useTheme();
  const isPermanentDrawer = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles({ isPermanentDrawer });

  const childContent = (
    <div className={classes.content}>
      { children }
    </div>
  );

  if (noWrapper) {
    return (
    <div ref={ref} className={clsx(classes.root, className)}>
      { children }
    </div>
    )
  }

  return (
    <div ref={ref} className={clsx(classes.root, className)}>
      {
        Wrapper ? <Wrapper>{ childContent }</Wrapper> : childContent
      }
    </div>
  );
}

export default React.forwardRef(FullBleed);
