import { useCallback, useEffect, useState } from 'react';

type CallbackMethod = () => unknown;
type CallbackTrigger = () => void;

//  this is useful when you want to trigger a call after the dom has been updated,
//  primarly used to call a function that requires a ref with NodeElements.
const useEffectCallback = (callback: CallbackMethod, deps: any[]): CallbackTrigger => {
  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ triggerVal, setTrigger ] = useState(0);

	const trigger: CallbackTrigger = () => { setTrigger(Math.random()) };
  
  useEffect(() => {
    if (isLoaded) callback();
    else setIsLoaded(true);
  }, [ triggerVal ]);

  return useCallback<CallbackTrigger>(trigger, deps);
}

export default useEffectCallback;