import React from 'react';
import useEmutableChildren from '@hooks/useEmutableChildren';
import WithView from '../WithView';

export const SnappyView = React.memo((props) => {
  const {
    isActive,
    children
  } = props;

  return (
    <>
      { isActive && children }
    </>
  )
})

const SnappyControl = React.memo((props) => {
  const {
    view,
    children
  } = props;

  const childList = useEmutableChildren(children, child => (
    <WithView
      key={ child.key }
      component={ SnappyView }
      child={ child }
      isActive={ child.key === view }
    />
  ));

  return (
    <>
      { childList }
    </>
  )
})

export default SnappyControl;
