import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { AsyncState, GenericAsyncState, PromiseFunction, useDeferredAsyncState } from '@hooks/useAsyncState'
import { useEventHandler } from '@hooks/useEvent';
import authContext from './AuthProvider';
import { createContext } from 'react';

export type AdditionalContext <Type = any, Err = any> = {
	[ctx: string]: (state: GenericAsyncState<Type, Err>) => any
}

type GenericProviderProps <Type, Err> = {
	children: React.ReactNode

	context: React.Context<GenericAsyncState<Type, Err>>
	promise: PromiseFunction<Type>
	
	selfInit?: true
	reloadEvents?: string[]		// TODO: deprecate

	additionalContext?: AdditionalContext
}

export const createAsyncContext = <Type, Err = Error>(): React.Context<GenericAsyncState<Type, Err>> => createContext<GenericAsyncState<Type, Err>>({
		state: AsyncState.LOADING,
		data: undefined,
		error: undefined,
		reload: undefined
});

export const GenericProviderWithTypes = <Type extends unknown = any, Err extends unknown = Error>() => {
	const GenericProviderComp: React.FC<GenericProviderProps<Type, Err>> = (props) => {
		const {
			children,

			context,
			promise,

			selfInit,
			reloadEvents = [],
	
			additionalContext = {}
		} = props;

		const { reload, state, data, error } = useDeferredAsyncState(promise);

		useEventHandler(reloadEvents, reload);	//	TODO: deprecate with reloadEvents on prop interface
		const authStore = useContext(authContext);
		
		const hasInited = useRef<boolean>(false);
		useEffect(() => {
			if (hasInited.current) return;
			if (!selfInit) return;
			if (!authStore.data?.isAuthenticated) return;

			hasInited.current = true;
			reload?.();
		}, [authStore.state, authStore.data?.hasAccount, authStore.data?.hasAccess, reload])
	
		//	TODO: only modify .data, not the top-level GenericAsyncState interface.
		//	this will allow the author to use the Data type to provide the types.
		const addCtx = useMemo(() => {
			const ctx = Object.entries(additionalContext)
				.map(([prop, method]) => [prop, method({ state, data, error })]);
			
			return Object.fromEntries(ctx);
		}, [state])
	
		return (
			<context.Provider
				value={{ ...addCtx, reload, state, data, error }}
				children={children}
			/>
		)
	}

	return GenericProviderComp;
}

const GenericProvider = GenericProviderWithTypes();
export default GenericProvider
