import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import backgroundImage from './../Promotion/images/background.svg';

import userProfileContext from '@context/UserProfileProvider'
import assessmentModalContext from '@context/AssessmentModalProvider'

import FullScreenDialog, { DialogSection } from '@components/FullScreenDialog'

import IntroSlides from './IntroSlides'
import Goal from './Goal'
import InstallSlide from './InstallSlide'
import IntroAssessment from './IntroAssessment'

import Mixpanel from '@utils/mixpanel'
import { useEventTrigger } from '@hooks/useEvent'
import { useAPI } from '@hooks/useRequest'
import questionnaireListContext from '@context/QuestionnaireListProvider'


const useStyles = makeStyles((theme) => ({
  slide: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))

const Onboarding = () => {
  const userProfile = useContext(userProfileContext);
  const history = useHistory();
	const axios = useAPI();
  const classes = useStyles();

  const assessmentModal = useContext(assessmentModalContext)
	const questionnaireList = useContext(questionnaireListContext)
  
  const [shouldOnboard, setShouldOnboard] = useState(false);

  const [onboardingView, setOnboardingView] = useState('introSlides');

	const emitOnboardingClosed = useEventTrigger('onboarding-complete');

  const completeOnboarding = async () => {
    axios.post('/UserHasSeenStreaksPromotion')
    await axios.post('/UserHasOnboarded')
  }

  const closeOnboarding = () => {
		setShouldOnboard(false);
		emitOnboardingClosed();
  }

  const handleSubmitGoal = () => {
    completeOnboarding();
    setOnboardingView('install');
  }

  const handleSkipIntroAssessment = () => {
    closeOnboarding();
    Mixpanel.track('Skipped Questionnaire in Onboarding');
  }

  const handleCompleteIntroAssessment = async () => {
    history.push('/');
    closeOnboarding();
    Mixpanel.track('Started Questionnaire in Onboarding');
    assessmentModal.open(questionnaireList.fullQuestionnaireId);
  }

  useEffect(() => {
    if (userProfile.hasLoaded) {
      setShouldOnboard(!userProfile.hasOnboarded);
    }
  }, [userProfile?.hasLoaded])


  if (!shouldOnboard) {
    return <></>
  } else {
    Mixpanel.track('Onboarding started');
    Mixpanel.track('View Explainer Slide', {
      slide: 0,
      method: 'load',
    });
  };

  return (
    <FullScreenDialog
      backgroundImage={backgroundImage}
      alwaysFullScreen
      name='onboarding'
      isOpen={shouldOnboard}
      view={onboardingView}
      hasToolbar={false}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogSection className={classes.slide} key='introSlides'>
        <IntroSlides handleCompleteIntro={() => setOnboardingView('goal')} />
      </DialogSection>
      <DialogSection className={classes.slide} key='goal'>
        <Goal handleCompleteGoal={handleSubmitGoal} />
      </DialogSection>
      <DialogSection className={classes.slide} key='install'>
        <InstallSlide handleNextView={() => setOnboardingView('introAssessment')} />
      </DialogSection>
      <DialogSection className={classes.slide} key='introAssessment'>
        <IntroAssessment handleSkipIntro={handleSkipIntroAssessment} handleCompleteIntro={handleCompleteIntroAssessment} />
      </DialogSection>
    </FullScreenDialog>
  )
}

export default Onboarding
