export const PageInfo = {
    home: {
        "subtitle": "We've got activities, content, and more ready for you to explore."
    },
    activities: {
        "title": "My Activities",
        "subtitle": "Time to untangle old habits and grow some new ones."
    },
    healthTracker: {
        "title": "Health Tracker",
        "subtitle": "Here's your personal health snapshot, just for you."
    },
    learnLibrary: {
        "title": "Learn Library",
        "subtitle": "Explore our expert resources and materials – no need for a library card."
    },
    wellbook: {
        "title": "Wellbook",
        "subtitle": "Your space for the things that keep you well."
    },
    about: {
        "title": "About",
        "subtitle": "A little bit about us and ours."
    },
} as const;

export const CreateYourOwnCard = {
    title: "Create your own activity",
    summary: "Build your own habit change goals and plan - just make sure it's simple, specific, and fun!",
    iconUrl: `${ process.env.REACT_APP_CONTENT_URL }/activity-content/CustomActivity/img.svg`
}
