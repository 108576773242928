import React, { useState, useEffect, useContext, createContext, useMemo } from 'react'
import { useAPI } from '@hooks/useRequest';

import authContext from './AuthProvider';
import Mixpanel from '../utils/mixpanel';

const welcomeLabels = [
  name => `Hello, ${name}`,
  name => `Hi, ${name}`,
  name => `How are you, ${name}?`,
  name => `It's nice to see you, ${name}!`,
  name => `How have you been, ${name}?`,
  name => `Good to see you, ${name}!`,
  name => `What's new, ${name}?`,
  name => `Hey, there you are ${name}!`,
  name => `How are you feeling today, ${name}?`,
]

//  TODO: Convert to TS
const userProfileContext = createContext();
export default userProfileContext;

export const UserProfileProvider = (props) => {
	const axios = useAPI();
  const { children } = props;
  
  const authStore = useContext(authContext);
  
  const { account } = authStore.data ?? {};
  const {
    given_name = 'undefined',
    family_name = 'undefined',
    email = 'undefined@problem.pog'
  } = account?.idTokenClaims ?? {};

  const user = useMemo(() => {
    return {
      firstName: given_name[0].toUpperCase() + given_name.substr(1),
      name: `${ given_name } ${ family_name }`,
      email,
    }
  }, [given_name, family_name, email]);

  const welcomeMsgFn = useMemo(() => {
    let rand = Math.floor(Math.random() * welcomeLabels.length);
    return welcomeLabels[rand];
  }, []);

  const [hasLoaded, setHasLoaded] = useState(false)
  const [userData, setUserData] = useState({})

  const getUserData = async () => {
    if (!authStore.data.isAuthenticated) return;

    const data = await axios.get('/UserProfile');
    
    setUserData(data);
    Mixpanel.register({'Organisation': data.organisations})
    setHasLoaded(true);
  }
  
  useEffect(getUserData, [authStore.data.isAuthenticated]);


  //  TODO: user + userData have similar values with different properties.
  //  We should be able to resolve some of these issues by cleaning up, and
  //  streamlining its values to stuff not already provided by id + access tokens.
  const userProfile = {
    welcome: welcomeMsgFn(user.firstName),
    hasLoaded,
    ...user,
    ...userData,
  }


  return (
    <userProfileContext.Provider value={userProfile}>
      { children }
    </userProfileContext.Provider>
  )
}