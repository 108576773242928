import React, { createContext, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom';

import {defaultSearchState} from '../pages/Learn/Learn';


const learnSearchContext = createContext()
export default learnSearchContext

export const LearnSearchProvider = (props) => {
	const { children } = props;
	
	const location = useLocation();

	const [searchResults, setSearchResults] = useState(null)

	const [searchState, setSearchState] = useState(defaultSearchState)

	const resetSearch = () => {
		setSearchState(defaultSearchState);
		setSearchResults(null);
	}

	React.useEffect(()=>{
		if (!location.pathname.includes('/learn')) {
			resetSearch();
		}
	},[location.pathname])

	const search = useMemo(() => ({
		searchResults,
		setSearchResults,
		searchState,
		setSearchState,
		resetSearch
	}), [ searchState, searchResults ])

	return (
		<learnSearchContext.Provider value={search}>
			{ children }
		</learnSearchContext.Provider>
	)
}
