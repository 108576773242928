import React, { useEffect, useContext, useState, useImperativeHandle } from 'react'
import { makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import Mixpanel from '@utils/mixpanel'
import goalContext from '@context/GoalProvider'

import AppNotification from '../AppNotification'
import Typography from '@ui/Typography'
import ReadMore from '../ReadMore'


const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(.75, 0, 2),
	},
	heading: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	dismiss: {
		cursor: 'pointer'
	},
	goal: {
		marginTop: theme.spacing(2)
	}
}))

const GoalNotification = (props, ref) => {
	const {
		onReady,
		startOpen = false,
	} = props;

	const classes = useStyles();
	const goalCtx = useContext(goalContext);
	const [ isOpen, setIsOpen ] = useState(startOpen);

	useEffect(() => goalCtx.isOpen ? onReady(true) : null, [ goalCtx.isOpen ]);

	useImperativeHandle(ref, () => ({
		open: () => {
			Mixpanel.track('View goal prompt')
			setIsOpen(true)
		}
	}))

	const handleDismiss = () => {
		setIsOpen(false);
		goalCtx.onDismiss();
		Mixpanel.track('Dismiss goal prompt')
	}

	return (
		<AppNotification isOpen={ isOpen } fullBleed>
			<div className={classes.root}>
				<div className={classes.heading}>
					<Typography component='p' type='label' variant='secondary' >
						What you are working toward...
					</Typography>

					<div
						className={classes.dismiss}
						onClick={ handleDismiss }
					>
						<CloseIcon fontSize='small' />
					</div>
				</div>

				<div className={classes.goal}>
					<ReadMore content={ goalCtx.goal } />
				</div>
			</div>
		</AppNotification>
	)
}

export default React.forwardRef(GoalNotification)
