import React, { useContext, createRef } from 'react'
import modalContext from './modalContext'
import FullscreenDialog from '@components/FullScreenDialog'

const ModalRenderer = (props, ref) => {
  const {
    isOpen,
    modalProps,
  } = useContext(modalContext);

  const modalRoot = createRef();

  const {
    children,
    view = 'default',
    ...otherProps
  } = modalProps;

  return (
    <>
      <div ref={ modalRoot } className='modal-root' />

      <FullscreenDialog
        { ...otherProps }

        view={ view }
        container={ modalRoot.current }
        isOpen={ isOpen }
      >
        { children }
      </FullscreenDialog>
    </>
  )
}

export default React.forwardRef(ModalRenderer);
