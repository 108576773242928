const add = {
  symbol: '+',
  method: operands => {
    return operands.reduce((sum, val) => sum + val, 0);
  }
}

const sub = {
  symbol: '-',
  method: operands => {
    return operands.reduce((sum, val) => sum - val, 0);
  }
}

const multi = {
  symbol: '*',
  method: operands => {
    const [first, ...others] = operands;
    return others.reduce((sum, val) => sum * val, first);
  }
}
  
const divide = {
  symbol: '/',
  method: operands => {
    const [first, ...others] = operands;
    return others.reduce((sum, val) => sum / val, first);
  }
}

const remainder = {
  symbol: '%',
  args: 2,
  method: operands => {
    const [first, ...others] = operands;
    return others.reduce((sum, val) => sum % val, first);
  }
}

const not = {
  symbol: '!',
  args: 1,
  method: ([operand]) => !operand,
}

const equal = {
  symbol: '=',
  args: 2,
  //  we want loose equality here, specifically because the BE requested it.
  method: ([left, right]) => left == right, // eslint-disable-line eqeqeq
}

const moreThan = {
  symbol: '>',
  max: 2,
  method: ([left, right]) => left > right,
}

const lessThan = {
  symbol: '<',
  max: 2,
  method: ([left, right]) => left < right,
}

const moreThanEqual = {
  symbol: '>=',
  max: 2,
  method: ([left, right]) => left >= right,
}

const lessThanEqual = {
  symbol: '<=',
  max: 2,
  method: ([left, right]) => left <= right,
}

const AND = {
  symbol: '&',
  max: 2,
  method: ([left, right]) => !!left && !!right,
}

const OR = {
  symbol: '|',
  max: 2,
  method: ([left, right]) => !!left || !!right,
}

const XOR = {
  symbol: '^',
  max: 2,
  method: ([left, right]) => !!(!!left ^ !!right),
}

export const plugins = [
  add,
  sub,
  multi,
  divide,
  remainder,
  
  not,
  equal,
  moreThan,
  lessThan,
  moreThanEqual,
  lessThanEqual,

  AND,
  OR,
  XOR,
]

export const operators = plugins.map(p => p.symbol);

if (operators.length !== new Set(operators).size)
  throw new Error('There are duplicate symbols!');