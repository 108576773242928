import { validate as isUUID } from 'uuid';
import { plugins, operators } from './plugins'

const resolveReference = (lookup, ref) => {
  if (Array.isArray(ref)) return resolveEquation(lookup, ref);
	if (isUUID(ref)) return lookup[ref];
  return ref;
}

const resolveEquation = (lookup, eq) => {
	//	requires an array
	if (!Array.isArray(eq))
		throw new Error('eq must be an array');

	//	requires items in the array
	if (!eq.length)
  	throw new Error(`eq has no items`);
  
	//	only one item means its probs a lookup.
	//	we can just yeet that.
  if (eq.length === 1)
  	return resolveReference(lookup, eq[0]);
  
	//	more than one means its an operation
  const [op, ...refs] = eq;

	//	check it is supported
  if (!operators.includes(op))
  	throw new Error(`Unexpected operator '${op}'`);

  
  const {
    args,
    method
  } = plugins.find(p => p.symbol === op);

	if (args && refs.length !== args) {
		throw new Error(`${op} expects exactly ${args} operands`);
	}

	if (typeof method !== 'function')
		throw new Error('Method is not a function');
  
  const operands = refs.map(ref => resolveReference(lookup, ref));
  return method(operands);
}

export default resolveEquation