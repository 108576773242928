import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container';

import ContextMenu from '@components/ContextMenu';
import { SnappyControl } from '@components/ViewControl';
import Typography from '@ui/Typography';

import Mixpanel from '@utils/mixpanel';

const useStyles = makeStyles((theme) => ({
  mainBackground: {
    background: ({bgImg}) => (bgImg) ? 
    `url('${bgImg}') center bottom/${theme.spacing(100)}px no-repeat ${theme.palette.background.default}`
    : theme.palette.background.default,
  },
  backButton: {
    padding: theme.spacing(2, 2.5),
  },
  containerRoot: {
    display: 'flex',
    justifyContent: 'center',
    flex: 'auto',
    overflow: 'auto',
    maxWidth: 'unset',
    height: '100%',
  },
  toolBar: {
    justifyContent: 'space-between',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

// TODO: The FullScreenDialog component should be a generic dialog component
//       which decides behaviour (e.g. full screen, full screen on mobile etc) based on props/state

const FullScreenDialog = (props) => {
  const {
    name,
    title,
    view,
    alwaysFullScreen = false,
    condenseOnViewport = 'md',
    hasToolbar = true,
    contextMenuItems,
    backgroundImage,

    isOpen = false,
    onClose,
    onBack,

    viewControl: ViewControl = SnappyControl,

    children,
    ...otherProps
  } = props;

  const theme = useTheme();
  const classes = useStyles({ bgImg: backgroundImage });

  const handleClose = () => {
    Mixpanel.track('Exit modal', {
      modalName: name,
      page: window.location.pathname,
    })
    onClose();
  }

  const shouldCondense = useMediaQuery(theme.breakpoints.up(condenseOnViewport))
  const isFullScreen = (alwaysFullScreen) || (!shouldCondense)

  return (
    <Dialog
      fullScreen={isFullScreen}
      fullWidth={!isFullScreen}
      PaperProps={{ className: classes.mainBackground }}
      TransitionComponent={ Transition }
      open={ isOpen }
      onClose={ onClose }
      { ...otherProps }>
      {
        (hasToolbar) &&
        <AppBar position='sticky' color='inherit'>
          <Toolbar className={ classes.toolBar }>
            <div className={ classes.buttonGroup }>
              {
                onBack &&
                <IconButton className={ classes.backButton } edge='start' color='inherit' onClick={ onBack } aria-label='back'>
                  <ArrowBack />
                </IconButton>
              }
              {
                title && (
                  <Typography disableMargin type='body' variant='secondary'>
                    { title }
                  </Typography>
                )
              }
            </div>

            <div className={ classes.buttonGroup }>
              {
                !!contextMenuItems && (
                  <ContextMenu>
                    { contextMenuItems }
                  </ContextMenu>
                )
              }

              {
                onClose &&
                <IconButton edge='end' onClick={ handleClose } aria-label='close'>
                  <CloseIcon />
                </IconButton>
              }
            </div>
          </Toolbar>
        </AppBar>
      }

      <Container classes={{ root: classes.containerRoot }} component='section' maxWidth={false} disableGutters>
        <ViewControl view={ view }>
          { children }
        </ViewControl>
      </Container>
    </Dialog>
  );
};

export default FullScreenDialog;