import React, { useContext, useState, useMemo, useRef, useEffect } from 'react'
import { v4 as uuid } from 'uuid'

import useTimer from '@hooks/useTimer';
import { useAPI } from '@hooks/useRequest';
import { useEventTrigger } from '@hooks/useEvent';

import { dateAsIso } from '@utils/date-time';
import Mixpanel from '@utils/mixpanel';

import WaitFor from '@components/WaitFor'
import assessmentModalContext from '@context/AssessmentModalProvider';
import WithConfirmation from '@components/WithConfirmation';
import { Carousel } from '@components/ViewControl'

import useAssessment from './useAssessment';
import useAnswers from './useAnswers';

import InstrumentScreen from './InstrumentScreen';
import Footer from './Footer';
import ExitModal from './ExitModal';
import TimeoutModal from './TimeoutModal';

import AssessmentResult from './AssessmentResult';
import { makeStyles } from '@material-ui/core';


const ConfirmExitModal = WithConfirmation(ExitModal);
const ConfirmTimeoutModal = WithConfirmation(TimeoutModal);


const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    width: '100%'
  }
}, {name:'Assessment'})


const Assessment = (props) => {
  const { assessmentId: externalAssessmentId } = props;

  const exitModal = useRef();
  const timeoutModal = useRef();
  const trackInfo = useRef({});

  const axios = useAPI();
  const classes = useStyles();
  const modal = useContext(assessmentModalContext);


  const [results, setResults] = useState();
  const [assessment, setAssessment] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [isFooterOpen, setIsFooterOpen] = useState(true);


  const {
    assessmentId = externalAssessmentId,
    instrumentIndex,
    instrumentId,
    instruments,
    instrument,
    healthDomain,

    isAssessmentCompleted,
    showResult,

    nextInstrument,
  } = useAssessment(assessment ?? {});

  const {
    answers,
    canSubmit,
    handleAnswer,
    valueTypeFor,
  } = useAnswers(instrument);


  const assessmentStartTime = useMemo(() => dateAsIso(Date.now()), []);
  const assessmentAnswerId = useMemo(() => uuid(), []); //  TODO: Rename to something more consistent

  const progress = useMemo(() => ({
    max: instruments.length,
    filled: instrumentIndex,
  }), [instruments, instrumentIndex]);

  
  const handleScroll = () => setIsFooterOpen(false);
  const emitCompleteAssessment = useEventTrigger('assessment-complete');
  const trackUserLeaving = () => Mixpanel.track('Exit Instrument', trackInfo.current);

  //  used in WaitFor comp
  const loadAssessment = async () => {
    const assessment = await axios.get(`/Assessment/${assessmentId}`);
    setAssessment(assessment);
    startTimer(20); //  TODO: 20 mins. It should come from BE.
  }

  const closeFromResultsPage = () => {
    trackUserLeaving();
    modal.close();
  }

  const closeFromAssessment = async () => {
    const userConfirmedExit = await exitModal.current.getConfirmation();
    
    if (userConfirmedExit) {
      trackUserLeaving();
      modal.close();
    }
  }
  
  const onClose = () => {
    if (showResult) closeFromResultsPage();
    else closeFromAssessment();
  }


  const closeFromTimeout = async () => {
    trackUserLeaving();
    await timeoutModal.current.getConfirmation();
    modal.close();
  }


  const handleSubmit = async () => {
    if (!assessmentStartTime) throw new Error('missing start time');
    if (!assessmentAnswerId) throw new Error('missing answer id');
    if (!assessment.id) throw new Error('missing assessment id');
    if (!instrumentId) throw new Error('missing intrument id');
    if (!healthDomain) throw new Error('missing health domain');

    Mixpanel.track('Submit Instrument', trackInfo.current);

    setSubmitting(true);
    setIsFooterOpen(true);

    const answerset = Object.entries(answers).map(([questionId, value]) => {
      const question = instrument.questions.find(q => q.id === questionId);
      return {
        valueType: valueTypeFor(question),
        questionId,
        value: value.value,
      }
    })

    try {
      const data = await axios.post('/AnswerSet', {
        assessmentStartTime,
        assessmentAnswerId,
        assessmentId,
        instrumentId,
        healthDomain,
        isAssessmentCompleted,
        answers: answerset,
      })

      setSubmitting(false);

      if (isAssessmentCompleted) {
        Mixpanel.track('Submit Assessment', trackInfo.current);
        setResults(data)
        emitCompleteAssessment();
        cancelTimer();
      };
      nextInstrument();
    }
    catch (err) {
      console.error(err);
      closeFromTimeout();
    }
  }

  useMemo(() => {
    trackInfo.current = {
      Assessment: assessment?.type,
      healthDomain,
    };
  }, [ assessment, healthDomain ]);


  useEffect(() => modal.updateModalProps({ onClose }), [ healthDomain ]);

  const { startTimer, cancelTimer } = useTimer(closeFromTimeout);
  
  return (
    <WaitFor
      promise={ loadAssessment }

      onSuccess={() => (
        <div className={classes.root}>
          <Carousel position={ instrumentIndex }>
            {
              instruments.map(instrument => {
                const isActiveInstrument = instrument.id === instrumentId;
                
                return (
                  <div key={ instrument.id } onScroll={ handleScroll }>
                    <InstrumentScreen
                      {...instrument}
                      
                      answers={ answers }
                      tabIndexed={ isActiveInstrument }
                      onChange={ handleAnswer }
                    />
                  </div>
                )
              })
            }
    
            
            <div key='results'>
              {
                results && (
                  <AssessmentResult
                    answerSets={ results }
                    insideAssessment
                  />
                )
              }
            </div>
          </Carousel>
    
          {
            !showResult && (
              <Footer
                onClickPrev={onClose}
                onClickNext={handleSubmit}
                isLast={isAssessmentCompleted}
                disabled={!canSubmit}
                submitting={submitting}
                progress={{ ...progress, filled: progress.filled + 1 }}
                maxWidth='md'
                isOpen={ canSubmit || isFooterOpen }
                onOpen={ () => setIsFooterOpen(true) }
              />
            )
          }
    
          <ConfirmExitModal ref={exitModal} />
          <ConfirmTimeoutModal ref={timeoutModal} />
        </div>
      )}
    />
  )
}

export default Assessment
