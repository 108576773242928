import React from 'react'
import { makeStyles } from '@material-ui/core'

import { dateFormats, toDateString } from '@utils/date-time'
import WaitFor from '@components/WaitFor';
import Typography from '@ui/Typography'

import Question from '../Question'
import { useAPI } from '@hooks/useRequest';

const useStyles = makeStyles(theme => ({
  spacing: {
    marginTop: theme.spacing(4)
  },
}))

const ReviewAnswers = (props) => {
	const classes = useStyles();

	const {
		answerSetId,
		instrumentId,
	} = props;

	const axios = useAPI();

	const getInstrumentResults = async () => {
		const answerPromise = axios.get(`/AnswerSet/${ answerSetId }/${ instrumentId }`)
		const instrumentPromise = axios.get(`/AssessmentInstrument/${ instrumentId }`)

		const [
			answerSet,
			instrument
		] = await Promise.all([ answerPromise, instrumentPromise ]);

		const {
			answerSetSubmissionTime,
			healthDomain,
		} = answerSet;

		const answers = Object.fromEntries(
			answerSet.answers
				.map((answer) => [ answer.questionId, answer ])
		)

		return {
			instrument,
			answers,
			answerSetSubmissionTime,
			healthDomain,
		}
	}

	return (
		<WaitFor
			promise={ getInstrumentResults }
			onSuccess={
				({ instrument, answers, answerSetSubmissionTime, healthDomain }) => {
					return (
						<>
							<Typography disableMargin className={ classes.spacing } type='heading' variant='secondary'>
								{ healthDomain }
							</Typography>

							<Typography disableMargin type='body' variant='secondary'>
								Taken on { toDateString(answerSetSubmissionTime, dateFormats.DATE_LONG) }
							</Typography>

							{
								instrument.instruction && (
									<Typography disableMargin className={ classes.spacing }>
										{ instrument.instruction }
									</Typography>
								)
							}

							{
								instrument.questions
									.filter(question => answers[question.id]?.value)
									.map(question => (
										<Question
											key={ question.id }
											value={ answers[question.id]?.value }
											order={ question.questionNumber }

											{ ...question }
											{ ...answers[question.id] }

											disabled
											tabIndexed={ false }
										/>
									))
							}
						</>
					)
				}
			}
		/>
	)
}

export default ReviewAnswers
