import { useContext,useRef } from 'react'
import { makeStyles, Theme, useTheme, Container, Accordion,AccordionSummary, AccordionDetails } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PageInfo } from '../../constants';

import Mixpanel from '@utils/mixpanel';
import { useAPI } from '@hooks/useRequest';

import healthDomainContext from '@context/HealthDomainProvider';
import learnSearchContext from '@context/LearnSearchProvider';
import useAsyncState, { useAsyncStateAll } from '@hooks/useAsyncState';
import usePageFilter from '@hooks/usePageFilter';

import Typography from '@ui/Typography';

import PageLoad from '@components/PageLoad';
import { WaitForWithTypes } from '@components/WaitFor';
import Header from '@components/Header';
import ContentCard from '@components/ContentCard';
import GroupList from '@components/GroupList';
import type { IContent } from '@components/Recommendations/Types';
import FullBleed from '@components/FullBleed';

import LearnCardListHeader from './LearnCardListHeader/LearnCardListHeader';
import EmptySearchContainer from './EmptySearchContainer';
import SearchResults from './SearchResults';


interface ILearningResources {
	featured: string[],
	learnContent: IContent[],
}
export interface ISearchResource {
	id: string
	modality: 'Article' | 'Podcast';
	title: string;
	subtitle: string;
	healthDomains: string[];
	source: string;
	image: {
		smallImageUrl:	string
		largeImageUrl:	string
	}
	isBookmark:boolean;
	bookmarkId?: string;
	bookmarkDate?:string;
}
export interface TModality {
	modality: 'All' | 'Article' | 'Podcast';
}
export interface TSearchState extends TModality{
	searchToggleActive: boolean;
	searchText: string;
	isSearching: boolean;
}

export const defaultSearchState: TSearchState = {
	searchToggleActive: false,
	searchText: '',
	modality: 'All',
	isSearching: false,
};


const WaitFor = WaitForWithTypes<[ILearningResources, string[]], Error[]>();

const getContentVariantColor = (theme: Theme, index: number): string | undefined => {
	switch (index % 5) {
		case 1: return theme.palette.color.daybreak[100];
		case 2: return theme.palette.color.purple[80];
		case 3: return theme.palette.color.grey[60];
		case 4: return theme.palette.color.pink[60];
		default: return undefined;
	}
}

const getContentVariantResource = (resource: IContent, index: number): string | undefined => {
	if (index % 8 === 0 || index % 8 === 1) return `${process.env.REACT_APP_CONTENT_URL}${resource.image.largeImageUrl}`;
	else return undefined;
}

const useStyles = makeStyles(theme => ({
	featured: {
		backgroundColor: theme.palette.color.mint[20],
		paddingTop: theme.spacing(3.5),
		paddingBottom: theme.spacing(5),
		marginBottom: theme.spacing(7),
	},
	libraryList: {
		marginBottom: theme.spacing(6.5),
		'& > div:first-child':{
			paddingTop: '0',
		},
	},
	libraryItem: {
		'& > div:not(:first-child)': {
			padding: theme.spacing(0.625)
		}	
	}
}), { name: 'LearnLibrary' });


const Disclaimer = () => {
	const scrollRef = useRef<HTMLElement>();

	const scrollView = () => () => {
		scrollRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
	};

	return (
		<Container disableGutters >
			<Accordion
				ref={scrollRef}
				TransitionProps={{ onEntered: scrollView() }}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}>
					<Typography type='heading' variant='tertiary' disableMargin >Content Disclaimer</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography type='body' variant='secondary' disableMargin>
						The material on the Innowell platform may refer to or contain widgets and hyperlinks to external websites and
						applications. The material may also contain references to information external to the Website. These websites, applications or services do not form part of the Website and are not under control of Innowell.  Any embedded content from third parties or reference to any content (including images) from third parties remains the exclusive property and copyright of the relevant third party and Innowell makes no warranties or representations regarding the quality, accuracy, merchantability, or fitness for purpose of any material on websites, applications or services linked or referred to.
					</Typography>
				</AccordionDetails>
			</Accordion>
		</Container>
	)
}

const Learn = () => {

	const thriveApi = useAPI();
	const history = useHistory();
	const {learnLibrary: infoLearnLibrary} = PageInfo;
	
	const learnSearchCtx = useContext(learnSearchContext);
	const {searchResults,setSearchResults,searchState,setSearchState,resetSearch} = learnSearchCtx;

	const handleOpenResource = (resource: IContent) => () => {
		Mixpanel.track('Access Learn Content', {
			entryPoint: 'Learn',
			modality: resource.modality,
			name: resource.title,
			healthDomains: resource.healthDomains,
			id: resource.id,
		});

		history.push(`/learn/${resource.modality}/${resource.id}`, { isBackToHistory:true });
	}

	const resourcesStore = useAsyncState(() => thriveApi.get('/v2/LearningResources'));
	const healthDomainsStore = useContext(healthDomainContext);

	const store = useAsyncStateAll<[ILearningResources, string[]]>([resourcesStore, healthDomainsStore]);

	const [filter, setFilter] = usePageFilter('domain', 'All');

	const theme = useTheme();
	const classes = useStyles();



	return (
		<>
			<Header title={infoLearnLibrary.title} subtitle={infoLearnLibrary.subtitle}>
			</Header>
				<WaitFor
					store={store}

					onSuccess={
						([resources, domains]) => {
							// featured resources
							const featuredResources = resources.learnContent.filter(resource => resources.featured.includes(resource.id)).sort((a,b)=>(resources.featured.indexOf(a.id)-resources.featured.indexOf(b.id)))
							// resources that aren't featured
							const otherResources = resources.learnContent.filter(resource => !resources.featured.includes(resource.id))
							// featured resources for this filter
							const hasFeatured = featuredResources.filter(resource => resource.healthDomains.includes(filter))
							
							return (
							<>
								<LearnCardListHeader  setSearchResults={setSearchResults} filter={filter} setFilter={setFilter} setSearchState={setSearchState} searchState={searchState} resetSearch={resetSearch}  domains={domains} />

								{
									searchState.isSearching ? <section><PageLoad/></section> : 
									
										searchResults ? !(searchResults?.length > 0) ? (
											<EmptySearchContainer handleClick={resetSearch}/>
										) : <SearchResults handleReset={resetSearch} searchResults={searchResults}/>
										: 
										<>
											{
												// IF there are featured resources,
												// AND the current filter has featured resources OR there is no filter
												(featuredResources.length !== 0 && (hasFeatured.length !== 0 || filter === 'All')) && 
												<FullBleed className={classes.featured}>
													<Typography type='heading' variant='section'>
														Featured
													</Typography>
													<section>
														<GroupList
															simple
															items={featuredResources}
															groups={domains}
															selectedGroup={(filter === 'All') ? false : filter}
															isItemInGroup={(group, item: IContent) => item.healthDomains.includes(group)}
															className={ classes.libraryItem }
															renderItem={(resource, key, index) => (
																<ContentCard
																	key={key}
																	id={ (resource as IContent).id }
																	title={ (resource as IContent).title }
																	mode={ (resource as IContent).modality }
																	image={ getContentVariantResource((resource as IContent), index) }
																	subtitle={ (resource as IContent).subtitle }
																	source={ (resource as IContent).source }
																	isBookmark={ (resource as IContent).isBookmark }
																	bookmarkId={ (resource as IContent).bookmarkId }
																	color={ getContentVariantColor(theme, index) }
																	onClick={ handleOpenResource((resource as IContent)) }
																/>
															)}
		
															renderEmptyGroup={() => (
																<Typography>
																	It looks like there aren't any learning resources in the { filter } category
																</Typography>
															)}
		
															renderEmptyList={() => (
																<Typography>
																	It looks there aren't any learning resources to display
																</Typography>
															)}
														/>
													</section>
												</FullBleed>
											}
												<section>
													<GroupList
														items={otherResources}
														groups={domains}
														selectedGroup={(filter === 'All') ? false : filter}
														isItemInGroup={(group, item: IContent) => item.healthDomains.includes(group)}
														className={clsx(classes.libraryList, classes.libraryItem)}
		
														renderItem={(resource, key, index) => (
															<ContentCard
																key={key}
																id={ (resource as IContent).id }
																title={ (resource as IContent).title }
																mode={ (resource as IContent).modality }
																image={ getContentVariantResource((resource as IContent), index) }
																subtitle={ (resource as IContent).subtitle }
																source={ (resource as IContent).source }
																isBookmark={ (resource as IContent).isBookmark }
																bookmarkId={ (resource as IContent).bookmarkId }
																color={ getContentVariantColor(theme, index) }
																onClick={ handleOpenResource((resource as IContent)) }
															/>
														)}
		
														renderEmptyGroup={() => (
															<Typography>
																It looks like there aren't any learning resources in the { filter } category
															</Typography>
														)}
		
														renderEmptyList={() => (
															<Typography>
																It looks there aren't any learning resources to display
															</Typography>
														)}
													/>
												<Disclaimer/>
												</section>
										</>
									}
							</>
						)}
					}
				/>
		</>
		
	)
}

export default Learn