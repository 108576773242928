import React from 'react';
import { createTheme, useTheme, ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { color, grid, font as brandyFont, GenericType, thriveTypography } from '../ui-kit/brandy';

const elevation: GenericType = {
  1: '0px 1px 3px rgba(0, 0, 0, 0.2), inset 0px 0px 0px 1px rgba(0, 0, 0, 0.12)',
  2: '0px 1px 3px 1px rgba(0, 0, 0, 0.12), 0px 2px 1px 0px rgba(0, 0, 0, 0.08)',
  7: '0px 2px 4px rgba(0, 0, 0, 0.07), 0px 0px 2px rgba(0, 0, 0, 0.2)',
  8: '0px 5px 5px rgba(0, 0, 0, 0.2), 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12)',
}

type TTheme = {
  children: React.ReactNode;
}

const Theme: React.FC<TTheme> = (props) => {
  const { children } = props;

  const muiTheme = useTheme();
  const thriveTheme = createTheme({
    ...muiTheme,
    elevation,
    grid,
    typography: {
      allVariants: {
        fontFamily: brandyFont,
        color: color.navy[100],
      },
      thriveTypography: thriveTypography,
    },
    palette: {
      color,
      primary: {
        light: color.navy[60],
        main: color.navy[100],
        dark: color.navy[80],
        contrastText: color.white,
      },
      secondary: {
        light: color.daybreak[60],
        main: color.daybreak[100],
        dark: color.daybreak[80],
        contrastText: color.navy[100],
      },
      tertiary: {
        light: color.mint[60],
        main: color.mint[100],
        dark: color.mint[80],
        contrastText: color.black,
      },
      background: {
        default: color.white,
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          //  TODO: resolve this pestering bug. why did it come back!?
          'iframe[style*="position: fixed"]': {
            display: 'none'
          },
          html: {
            WebkitFontSmoothing: 'antialiased',
          },
          body: {
            
            backgroundColor: color.white,
          },
          a: {
            color: color.navy[100],
          },
          button: {
            '&[type=button].link': {
              color: color.navy[100],
              userSelect: 'none',
              backgroundColor: 'transparent',
              padding: 0,
              border: 0,
              textAlign: 'left',
            }
          },
          hr: {
            display: 'block',
            border: 'none',
            backgroundColor: color.grey[60],
            height: 1,
            margin: muiTheme.spacing(0, 0),

            '&.sectioning-5': {
              margin: muiTheme.spacing(5, 0),
            },
            '&.sectioning-3': {
              margin: muiTheme.spacing(3, 0),
            }
          },
        },
      },
      // TODO: need to figure out how to store these overrides in context of component
      MuiContainer: {
        root: {
          paddingLeft: muiTheme.spacing(2.5),
          paddingRight: muiTheme.spacing(2.5),
          maxWidth: muiTheme.spacing(110), // TODO: This needs to be centralised into a reusable value

          [muiTheme.breakpoints.up('sm')]: {
            paddingLeft: muiTheme.spacing(5),
            paddingRight: muiTheme.spacing(5),
          },
        },
        maxWidthXl: {
          width: '100%',
          maxWidth: '100%',

          [muiTheme.breakpoints.up('md')]: {
            paddingLeft: muiTheme.spacing(5),
            paddingRight: muiTheme.spacing(5),
          },
          [muiTheme.breakpoints.up('lg')]: {
            maxWidth: 1200,
            paddingLeft: muiTheme.spacing(10),
            paddingRight: muiTheme.spacing(10),
          }
        }
      },
      MuiInputBase: {
        root: {
					/**
					 * TODO: Handle disabled inputs consistently both in designs and code.
					 * this fix hardcodes the colour from MUI's disabled input, which does not
					 * include a fix for safari.
					 */
					'&.Mui-disabled': {
						'-webkit-text-fill-color': 'rgb(127, 124, 138)'
					},
          ...thriveTypography.paragraphLg,
          [muiTheme.breakpoints.up('sm')]: {
            ...thriveTypography.paragraphXl
          }
        },
        input: {
          color: color.navy[100],
          ...thriveTypography.paragraphLg,
          [muiTheme.breakpoints.up('sm')]: {
            ...thriveTypography.paragraphXl
          }
        }
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: muiTheme.spacing(1.25),
          '&.Mui-focused .MuiOutlinedInput-notchedOutline' : {
            borderColor: color.navy[100],
            borderWidth: '1px'
          },
          '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':{
            borderColor: color.navy[100],
          }
        },
        notchedOutline: {
          borderColor: color.grey[100],
        },
      },
      MuiFormHelperText: {
        contained: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
      MuiButton: {
        root: {
          borderRadius: muiTheme.spacing(4),
          textTransform: 'none',
          color: color.navy[100],
          lineHeight: '27px',
          fontWeight: 400,
          fontSize: 16,
          fontFamily: brandyFont,
          padding: muiTheme.spacing(1, 3)
        },
        sizeSmall: {
          padding: muiTheme.spacing(1.5, 3),
        },
        sizeLarge: {
          padding: muiTheme.spacing(1, 3),
          fontSize: '20px',
          lineHeight: '38px'
        },
        contained: {
          boxShadow: elevation[2],
        },
        containedPrimary: {
          background: color.dawn,
          backgroundColor: color.pink[100],
          color: color.navy[100],
          boxShadow: 'none',
          '&$disabled': {
            background: color.grey[80],
          }
        },
        containedSecondary: {
          backgroundColor: color.white,
          color: color.navy[100],
          border: `1px solid ${color.navy[100]}`,
          '&:hover': {
            backgroundColor: color.navy[100],
            color: color.white,
          }
        },
        outlined: {
          background: 'transparent',
          border: `1px solid ${color.navy[100]}`
        },
        text: {
					padding: muiTheme.spacing(.75, 1),
					margin: muiTheme.spacing(-.75, -1),
          textDecoration: 'underline',
        }
      },
      MuiButtonBase: {
        root:{
          fontFamily: brandyFont,
        },
      },
      MuiFab: {
        root: {
          zIndex: muiTheme.zIndex.speedDial,
          textTransform: 'none',
          lineHeight: '1.333px',
          letterSpacing: .1,
          fontWeight: 500,
          fontSize: 15,
        },
      },
      MuiToolbar: {
        gutters: {
          paddingLeft: muiTheme.spacing(2.5),
          paddingRight: muiTheme.spacing(2.5),
        }
      },
      MuiPaper: {
        root: {
          color: color.navy[100],
        },
        rounded: {
          borderRadius: muiTheme.spacing(1.25),
        },
        elevation1: {
          boxShadow: elevation[1],
        },
        elevation8: {
          boxShadow: elevation[8]
        }
      },
      MuiChip: {
        root: {
          border: 'none',
          borderWidth: 0,
          color: color.navy[100],
          padding: muiTheme.spacing(1, 0),
          background: color.dawn,
          fontFamily: brandyFont,
        },
        outlined: {
          background: color.white,
          border: `1px solid ${color.navy[100]}`,

          '&$outlined, &$deletable': {
            '&:hover': {
              background: color.navy[100],
              color: color.white,
            }
          },

          '&:focus': {
            boxShadow: `0 0 1px 1px ${color.navy[100]}`,
          },
        },
        outlinedPrimary: {
          background: color.navy[100],
          color: color.white,

          '&$outlined, &$deletable': {
            '&:hover': {
              background: color.navy[100],
              color: color.white,
            }
          },

          '&:focus': {
            boxShadow: `0 0 1px 1px ${color.navy[100]}`,
            backgroundColor: `${color.navy[100]} !important`,
          },
        },
        label: {
          paddingLeft: muiTheme.spacing(2),
          paddingRight: muiTheme.spacing(2),
          fontSize: 14,
          lineHeight: '24px',
        },
      },
      MuiSlider: {
        root: {
          color: color.navy[100],
        },
        rail: {
          color: color.grey[100],
          height: muiTheme.spacing(0.8),
          borderRadius: muiTheme.spacing(1),
          opacity: 1
        },
        track: {
          height: muiTheme.spacing(0.8),
          borderRadius: muiTheme.spacing(1)
        },
        mark: {
          width: muiTheme.spacing(0),
          height: muiTheme.spacing(0),

        },
        thumb:{
          width: muiTheme.spacing(2.5),
          height: muiTheme.spacing(2.5),
          marginTop: muiTheme.spacing(-0.75),
        }
      },
      MuiAppBar: {
        root: {
          backgroundColor: color.white,
          boxShadow: elevation[7],
        },
      },
			MuiMenuItem: {
				root: {
					whiteSpace: 'unset',
          ...thriveTypography.paragraphLg,
          [muiTheme.breakpoints.up('sm')]: {
            ...thriveTypography.paragraphXl
          }
				}
			},
      MuiInputLabel: {
        outlined: {
          whiteSpace: 'unset',
          ...thriveTypography.paragraphLg,
          [muiTheme.breakpoints.up('sm')]: {
            ...thriveTypography.paragraphXl
          },
          '&.MuiInputLabel-shrink':{
            fontSize: '18px',
            transform: 'translate(14px, -13px) scale(0.75)'
          }
        },
      },
			MuiSelect: {
				selectMenu: {
					whiteSpace: 'unset',
          ...thriveTypography.paragraphLg,
          [muiTheme.breakpoints.up('sm')]: {
            ...thriveTypography.paragraphXl
          }
				}
			},
      MuiMobileStepper: {
        dotActive: {
          backgroundColor: color.purple[100]
        }
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: color.navy[100] ,
          textAlign: 'left',
          borderRadius: muiTheme.spacing(1),
          maxWidth: muiTheme.spacing(38),
          padding: muiTheme.spacing(1, 2,1.5),
          [muiTheme.breakpoints.up('sm')]: {
            maxWidth: muiTheme.spacing(48),
          }
        }
      },
      MuiFormControlLabel:{
        root:{
          marginBottom: muiTheme.spacing(1.25),
          '& .MuiRadio-colorSecondary.Mui-checked':{
            color: color.navy[100],
          },
          '& .MuiRadio-colorSecondary.Mui-disabled':{
            color: 'rgba(0, 0, 0, 0.26)',
          },
          '& .MuiRadio-root':{
            color: color.navy[100],
            paddingRight: muiTheme.spacing(2)
          },
          '& .MuiSvgIcon-root':{
            fontSize: muiTheme.spacing(3.5),
            color: color.navy[100],
          }
        },
        label:{
          ...thriveTypography.paragraphLg,
          [muiTheme.breakpoints.up('sm')]: {
            ...thriveTypography.paragraphXl,
          }
        }
      },
    },
    props: {
      MuiContainer: {
        maxWidth: false,
      },
      MuiChip: {
        clickable: true,
      }
    }
  });

  return (
    <ThemeProvider theme={thriveTheme}>
      <CssBaseline />
      { children }
    </ThemeProvider>
  )
}

export default Theme;