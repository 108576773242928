import React, { useState, useEffect, useMemo, createContext, useContext } from 'react'
import { useMediaQuery } from '@material-ui/core';

import usePersistState from '@hooks/usePersistState';
import storageContext from './StorageProvider';

const installableContext = createContext();
export default installableContext;

export const InstallableProvider = (props) => {
  const [ deferred, setDeferred ] = useState();
  const { deleteItem } = useContext(storageContext);

  const isInstalled = useMediaQuery('(display-mode: standalone)');
  const isInstallable = useMemo(() => !isInstalled && !!deferred, [ deferred, isInstalled ]);
  
  const [ dismissedThisSession, setDismissedThisSession ] = usePersistState('a2hs-dismissed', false, true);
  
  const [ holidayEndDate, setHolidayEndDate ] = usePersistState('a2hs-holiday', Date.now());
  const isOnHoliday = useMemo(() => holidayEndDate > Date.now(), [ holidayEndDate ]);

  const onDismiss = () => {
    setHolidayEndDate(Date.now() + (30 * 24 * 60 * 60 * 1000));
    setDismissedThisSession(true);
  }

  const a2hsHandler = e => {
    e.preventDefault();
    setDeferred(e);
  }

  const onInstall = async (callback) => {
    if (!deferred) return;

    deferred.prompt();
    const { outcome } = await deferred.userChoice;
    setDeferred(undefined);

    if (outcome !== 'accepted')
      throw outcome;

    if (typeof callback === 'function') callback();
    deleteItem('a2hs-holiday')
    setDismissedThisSession(true);
  }

  useEffect(() => {
    if (deferred) return;

    window.addEventListener('beforeinstallprompt', a2hsHandler);
    return () => window.removeEventListener('beforeinstallprompt', a2hsHandler);
  }, [ deferred ]);

  const canShow = useMemo(() => {
    if (!isInstallable) return false;
    if (isInstalled) return false;
    if (dismissedThisSession) return false;
    if (isOnHoliday) return false;
    return true;
  }, [ isInstallable, isInstalled, dismissedThisSession, isOnHoliday ])

  const value = {
    isInstallable,
    canShow,
    onInstall,
    onDismiss,
  }
  
  return (
    <installableContext.Provider {...props} value={ value } />
  )
}
