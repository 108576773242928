import React from 'react'

import { useAPI } from '@hooks/useRequest'
import GenericProvider, { createAsyncContext } from '@context/GenericProvider'

import type { IRecommendationData, IRecommendationDataMark } from '@components/Recommendations/Types'

type RecData = IRecommendationData | IRecommendationDataMark | undefined

const recommendationContext = createAsyncContext<RecData>();

interface RecommendationProviderProps { children: React.ReactElement }
export const RecommendationProvider: React.FC<RecommendationProviderProps> = ({ children }) => {
	const { get } = useAPI<RecData>();
	const promise = () => get('/Recommendation/Items/6')

	return (
		<GenericProvider
			selfInit
			children={children}
			context={recommendationContext}
			promise={promise}
			reloadEvents={[
				'goal-updated',
				'onboarding-complete',
				'assessment-complete'
			]}
		/>
	)
}

export default recommendationContext
