import { DependencyList, useCallback, useEffect, useMemo, useState } from "react";

export enum AsyncState {
	LOADING = 'LOADING',
	FULFILLED = 'FULFILLED',
	REJECTED = 'REJECTED'
}

type GenericAsyncStore <Data = any, Err = Error> = {
	state: AsyncState
	data?: Data
	error?: Err
}

type ReloadFunction = () => Promise<void>;
export type GenericAsyncState <Data, Err> = GenericAsyncStore<Data, Err> & {
	reload?: ReloadFunction
}

export type PromiseFunction <Data> = () => Promise<Data | never>

const useAsyncState = <Data extends unknown = any, Err extends unknown = Error>(
	promise: PromiseFunction<Data>,
	defer?: true
): GenericAsyncState<Data, Err> => {
	const [store, setStore] = useState<GenericAsyncStore<Data, Err>>({
		state: AsyncState.LOADING,
		data: undefined,
		error: undefined
	});

	const reload = useCallback(async () => {
		setStore({
			...store,
			state: AsyncState.LOADING,
			error: undefined
		});

		try {
			const data = await promise();
			setStore({
				...store,
				state: AsyncState.FULFILLED,
				data,
			});
		}
		catch (e) {
			const error = e as Err;
			setStore({
				...store,
				state: AsyncState.REJECTED,
				error
			});
		}
	}, [promise])

	//	initialises the promise, to get data on mount.
	//	to control timing yourself,
	//	`useAsyncState(promise, true)`
	//	or `useDeferredAsyncState(promise)`
	useEffect(() => { !defer && reload() }, [/*promise?*/]);

	return useMemo(() => ({
		...store,
		reload,
	}), [store.state, promise]);
}

export const useDeferredAsyncState = <Data, Err>(promise: PromiseFunction<Data>) => useAsyncState<Data, Err>(promise, true);
export default useAsyncState



export const useAsyncStateMap = <Data, Err>(
	fn: () => GenericAsyncState<Data, Err>,
	deps: DependencyList = []
): GenericAsyncState<Data, Err> => useMemo(() => fn(), deps);


type StateList = AsyncState[];
export const useAsyncStateAll = <Data extends (any|undefined)[], Err extends (any|undefined)[] = Error[]>(asyncStores: GenericAsyncState<unknown, unknown>[] = []): GenericAsyncState<Data, Err> => {
	const allStates: StateList = asyncStores.map(store => store.state);
	const allValues: Data = asyncStores.map(store => store.data) as Data;
	const allErrors: Err = asyncStores.map(store => store.error) as Err;
	
	return useMemo(() => {
		if (allStates.includes(AsyncState.REJECTED)) {
			return {
				state: AsyncState.REJECTED,
				data: allValues,
				error: allErrors,
			}
		}

		if (allStates.includes(AsyncState.LOADING)) {
			return {
				state: AsyncState.LOADING,
				data: allValues,
				error: allErrors,
			}
		}
		
		return {
			state: AsyncState.FULFILLED,
			data: allValues,
			error: allErrors,
		}
	}, [...allStates, ...allValues, ...allErrors]);
}
