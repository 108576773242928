import React, { useMemo } from 'react'
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import { thriveTypography } from '../brandy';

import CoolBean from '../../components/CoolBean'

type StyleProps = {
  disableMargin: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(theme => {
  // Heading H1
  const headingH1 = {
    ...thriveTypography.headingH1,
    marginBottom: ({ disableMargin }: StyleProps) => disableMargin ? 0 : 40,
  };

  // Heading H2
  const headingH2 = {
    ...thriveTypography.headingH2,
    marginBottom: ({ disableMargin }: StyleProps) => disableMargin ? 0 : 24,
  };

  // Heading H3
  const headingH3 = {
    ...thriveTypography.headingH3,
    marginBottom: ({ disableMargin }: StyleProps) => disableMargin ? 0 : 16,
  };

  // Heading H4
  const headingH4 = {
    ...thriveTypography.headingH4,
    marginBottom: ({ disableMargin }: StyleProps) => disableMargin ? 0 : 16,
  };

  // Paragraph/xLarge
  const paragraphXl = {
    ...thriveTypography.paragraphXl,
    marginBottom: ({ disableMargin }: StyleProps) => disableMargin ? 0 : 20,
  };

  // Paragraph/Large
  const paragraphLg = {
    ...thriveTypography.paragraphLg,
    marginBottom: ({ disableMargin }: StyleProps) => disableMargin ? 0 : 20,
  };

  // Paragraph/Medium
  const paragraphMd = {
    ...thriveTypography.paragraphMd,
    marginBottom: ({ disableMargin }: StyleProps) => disableMargin ? 0 : 16,
  };

  // Label/Large
  const labelLg = {
    ...thriveTypography.labelLg,
    marginBottom: ({ disableMargin }: StyleProps) => disableMargin ? 0 : 12,
  };

  // Label/Medium
  const labelMd = {
    ...thriveTypography.labelMd,
    marginBottom: ({ disableMargin }: StyleProps) => disableMargin ? 0 : 12,
  };
  
  // Label/Small
  const labelSm = {
    ...thriveTypography.labelSm,
    marginBottom: ({ disableMargin }: StyleProps) => disableMargin ? 0 : 12,
  };


  return {
    root: {
      margin: 0,
    },

    link: {
      textDecoration: 'underline',
    },

    disableMargin: {
      marginBottom: 0,
    },
    headingPrimary:{
      ...headingH1,
      [theme.breakpoints.only('xs')]: {
        ...headingH2
      }
    },
    headingSecondary:{
      ...headingH2,
      [theme.breakpoints.only('xs')]: {
        ...headingH3
      }
    },
    headingTertiary:{
      ...headingH3,
      [theme.breakpoints.only('xs')]: {
        ...headingH4
      }
    },
    headingSection: {
      ...headingH2,
      [theme.breakpoints.only('xs')]: {
        ...headingH3
      }
    },
    bodyPrimary: {
      ...paragraphXl,
      [theme.breakpoints.only('xs')]: {
        ...paragraphLg
      }
    },
    bodySecondary: {
      ...paragraphLg,
      [theme.breakpoints.only('xs')]: {
        ...paragraphMd,
      }
    },
    labelPrimary: {
      ...labelLg,
      [theme.breakpoints.only('xs')]: {
        ...labelMd
      }
    },
    labelSecondary: {
      ...labelMd,
      [theme.breakpoints.only('xs')]: {
        ...labelSm
      }
    },
  }
}, { name: 'ThriveTypography' });

const componentMap: Record<string, keyof HTMLElementTagNameMap> = {
  headingPrimary: 'h1',
  headingSecondary: 'h2',
  headingTertiary: 'h3',
  headingSection: 'h2',
  bodyPrimary: 'p',
  bodySecondary: 'p',
  labelPrimary: 'label',
  labelSecondary: 'label'
}

type HeadingTypographyProps = {
  type: 'heading';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'section'
}

type BodyTypographyProps = {
  type: 'body';
  variant?: 'primary' | 'secondary'
}
type LabelTypographyProps = {
  type: 'label';
  variant?: 'primary' | 'secondary'
}

export type TypographyProps = (
  (
    | HeadingTypographyProps
    | BodyTypographyProps
    | LabelTypographyProps
  ) & {
    disabled?: boolean
    link?: boolean
    coolBean?: boolean

    disableMargin?: boolean
    component?: keyof HTMLElementTagNameMap
    className?: string

    children?: React.ReactNode
    [otherProps: string]: any
  }
)



const Typography = React.forwardRef<HTMLElement, TypographyProps>((props, ref) => {
  const {
    type = 'body',
    variant = 'primary',

    disabled,
    link,
    coolBean,

    disableMargin = false,
    component,
    className,

    children,
    ...otherProps
  } = props;

  const classStyles = useStyles({ disableMargin });

  const internalVariant = useMemo(() => variant && `${type}${variant[0].toUpperCase() + variant.slice(1)}`, [type, variant]);
  const htmlElement = useMemo(() => component ?? componentMap[internalVariant] ?? 'p', [internalVariant, component]);

  const classList = clsx(
    classStyles.root,
    classStyles[internalVariant],

    disabled === true ? classStyles.disabled : {},
    link === true ? classStyles.link : {},

    disableMargin && classStyles.disableMargin,
    className ?? ''
  );

  return React.createElement(
    htmlElement,
    {
      ref,
      className: classList,
      ...otherProps,
      children: (
        <>
          {children}
          { (coolBean || (type === 'heading' && variant === 'section')) && <>&nbsp;<CoolBean /></> }
        </>
      )
    }
  )
})

export default Typography
