import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'

import Button from '@ui/Button'
import Typography from '@ui/Typography'

const ExitModal = (props) => {
  const { isOpen = false, onAccept } = props;

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" disableTypography>
        <Typography disableMargin type='heading' variant='tertiary'>
          Looks like our questionnaire timed out.
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Typography disableMargin >
          Not to worry, when you're ready let's try taking it again.
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button theme='link' onClick={ onAccept }>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ExitModal
