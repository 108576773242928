import React from 'react'
import {
  Snackbar,
  SnackbarContent,
  Button,
  Slide,
  makeStyles,
} from '@material-ui/core';

const SlideTransition = (props) => (
  <Slide {...props} direction="up" />
);

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    width: theme.spacing(43),
    height: theme.spacing(8),
  },
  message: {
    color: theme.palette.color.white,
  }
}), {name:'SnackToast'});
const SnackToast = (props) => {
  const {
    isOpen,
    onClose,
    actionLabel,
    onAction,
    message
  } = props;
  
  const classes = useStyles();

  const onClick = () => {
    onClose();
    onAction();
  }

  const action = !actionLabel ? null : (
    <Button color='secondary' size='small'
    onClick={ onClick }>
      { actionLabel }
    </Button>
  );
  
  return (
    <Snackbar
      open={ isOpen }
      action={ action }
      TransitionComponent={ SlideTransition }
    >
      <SnackbarContent
        classes={{
          root: classes.root,
          message: classes.message,
        }}
        message={message}/>
    </Snackbar>
  )
}

export default SnackToast
