import { useState } from 'react';

export default function useSnackTime(time = 2500) {
  const [isSnackOpen, setSnackOpen] = useState(false);
  const [timer, setTimer] = useState(undefined);

  const closeSnack = () => setSnackOpen(false);

  const openSnack = () => {
    if (timer) clearTimeout(timer);
    const t = setTimeout(closeSnack, time);
    setTimer(t);
    setSnackOpen(true);
  }
  
  return {
    isSnackOpen,
    openSnack,
    closeSnack
  }
}

