import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { Collapse as MuiCollapse, makeStyles, withStyles, useMediaQuery } from '@material-ui/core';

import { useContent } from '@hooks/useRequest'

import useToggle from '@hooks/useToggle';
import Typography from '@ui/Typography';
import WaitFor from '@components/WaitFor';
import Markdown from '@components/MarkdownToJsx';

const Collapse = withStyles(theme => ({
	root: {
    position: 'relative',
    marginBottom: ({ pad }) => pad === true ? theme.spacing(3) : 0,
  }
}))(MuiCollapse)

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    marginBottom: ({ pad }) => pad === true ? theme.spacing(3) : 0,
  },
  overflow: {
    position: 'absolute',
    bottom: 0,
    background: 'linear-gradient(90deg, #fff0 0%, var(--bgColor, #fff) 15%)',
    transition: '0.3s',
    whiteSpace: 'nowrap',
    right: 0,
    paddingLeft: 15,
    opacity: ({ isShowing }) => isShowing ? 0 : 1,
  },
  link: {
    lineHeight: theme.typography.pxToRem(24),
  },
  overflowLink: {
    '&[type=button].link': {
      paddingLeft: 5,
      lineHeight: 0,
    }
  },
  hiddenLink: {
    marginTop: theme.spacing(2)
  }
}));


const ReadMore = (props) => {
  const isLarge = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const {
    useParagraphStyles = false,
    pad,
    content = [],
    contentUrl,
    // TODO: Need to fix and reference the typography settings
    minHeight = (useParagraphStyles ? (isLarge ? (38 * 3) : (27 * 3)) : (24 * 3)), // line height * 3 lines
    disableReadMore = false,
    ...typographyProps
  } = props;

  const { isShowing, open, close } = useToggle(false);
  const [ enoughContent, setEnoughContent ] = useState(false);
  const [ targetHeight, setTargetHeight ] = useState(minHeight);

  const classes = useStyles({ isShowing, pad });

  const axios = useContent();
  const contentRef = useRef();

  const getContent = useCallback(async () => {
    if (contentUrl) return axios.get(contentUrl).then(content => <Markdown noWrapper children={content} />);

    return [content].flat(Infinity).map((paragraph, i) => (
      <Typography disableMargin
        key={`paragraph-${i}`}
        {...typographyProps}
        children={paragraph}
      />
    ))
  }, [content, contentUrl])

  const checkContentSize = () => {
    if (!contentRef.current) return;
    const enough = contentRef.current.offsetHeight > minHeight;

    if (!enough && !!enoughContent) close();
    setEnoughContent(enough);
    if (!contentRef.current.children[0]) setTargetHeight(minHeight);
    else {
      const firstParaHeight = contentRef.current.children[0]?.offsetHeight;
      setTargetHeight( Math.min(minHeight, firstParaHeight) );
    }
  }

  useEffect(() => {
    window.addEventListener('resize', checkContentSize);
    return () => window.removeEventListener('resize', checkContentSize);
  }, [ ]);

  return useMemo(() => (
    <WaitFor
      promise={getContent}
      onSuccess={resp => {
        Promise.resolve().then(checkContentSize);

        if(disableReadMore) 
          return (
            <div className={ classes.container }>
            { resp }
            </div>
          ) 
          else if (enoughContent) return (
            <Collapse
              collapsedSize={ targetHeight }
              in={ isShowing }
              className={ classes.container }>
  
              <div ref={ contentRef }>
                { resp }
              </div>
  
              <button type='button' className={ clsx('link', classes.link, classes.hiddenLink) } onClick={ close }>
                <Typography disableMargin  {...typographyProps} {...(useParagraphStyles && { type:'body', variant:'primary'})} {...(!useParagraphStyles && { component:'span'})} >
                  See less
                </Typography>
              </button>
  
              <div className={ classes.overflow }>
                <button type='button' className={ clsx('link', classes.link, classes.overflowLink) } onClick={ open }>
                <Typography disableMargin  {...typographyProps} {...(useParagraphStyles && { type:'body', variant:'primary'})} {...(!useParagraphStyles && { component:'span'})} >
                    See more
                  </Typography>
                </button>
              </div>
            </Collapse>
          )
  
          else return (
            <div ref={ contentRef } className={ classes.container }>
              { resp }
            </div>
          )
      }}
    />
  ), [content, contentUrl, enoughContent, isShowing, isLarge, minHeight, targetHeight])
}

export default ReadMore
