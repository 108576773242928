import { useState, useRef, useEffect } from 'react';
import useEffectCallback from './useEffectCallback';

//  maths, lol.
const clamp = (val, max = 1, min = 0) => Math.min(max, Math.max(min, val));
const lerp = (val, end = 1, start = 0) => (val - start) / (end - start);
const easeOutSine = (x) => Math.sin((x * Math.PI) / 2);

export const relerp = (pos, start = 0, end = 100) => ((end - start) * pos) + start;


const useTransition = (options) => {
  const {
    duration = 800,
    isAnimating,

    onStart = () => {},
    onStep = () => {},
    onStop = () => {},
  } = options ?? {};

  const [ timer, setTimer ] = useState(undefined);
  const startTime = useRef();
  
  const [ progress, setProgress ] = useState(0);
  const [ value, setValue ] = useState(0);
  
  const triggerStart = useEffectCallback(() => onStart());
  const triggerStep = useEffectCallback(() => onStep());
  const triggerStop = useEffectCallback(() => onStop());

  const step = () => {
    const delta = Math.round((performance.now() - startTime.current) * 100) / 100;
    
    const progress = clamp(lerp(delta, duration));
    setProgress(progress);
    
    const value = easeOutSine(progress);
    setValue(isNaN(value) ? 0 : value);

    triggerStep();

    if (delta >= duration) {
      stop();
      triggerStop();
    } else {
      setTimer(requestAnimationFrame(step));
    }
  }

  const start = () => {
    if (timer) stop();
    startTime.current = performance.now();
    if (isAnimating) isAnimating.current = true;
    triggerStart();

    setTimer(requestAnimationFrame(step));
  }

  const stop = () => {
    cancelAnimationFrame(timer);
    setTimer(undefined);
    startTime.current = undefined;
    if (isAnimating) isAnimating.current = false;
  }

  const reset = () => {
    cancelAnimationFrame(timer);
    setTimer(undefined);
    setProgress(0);
    setValue(0);
  }

  useEffect(() => timer ? stop : undefined, []);

  return {
    progress,
    value,

    start,
    stop,
    reset,
  }
}

export default useTransition