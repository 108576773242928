import React from 'react';
import GenericProvider, { createAsyncContext } from '@context/GenericProvider'

import { useAPI } from '@hooks/useRequest';

type HealthDomain = {
  title: string;
}

type THealthProvider = {
  children?: React.ReactNode;
}

const healthDomainContext = createAsyncContext<string[]>();
export default healthDomainContext;


export const HealthDomainProvider: React.FC<THealthProvider> = (props) => {
  const {children} = props;
  const thriveAPI = useAPI<HealthDomain[]>();

  const promise = async () => {
    const data = await thriveAPI.get('/HealthDomains', []);
    return (data as HealthDomain[]).map(t => t.title);
  }

  return (
    <GenericProvider
      selfInit
      context={healthDomainContext}
      promise={promise}
      children={children}
    />
  )
}