import React, { useMemo } from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { CardActionArea } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'

import Typography from '@ui/Typography'
import BaseCard from '../BaseCard'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import Mixpanel from '@utils/mixpanel'

const useStyles = makeStyles<Theme>(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		width: theme.spacing(50),
		height: theme.spacing(41),
	},

	card: {
		display: 'flex',
		width: '50%',
		flex: 'auto 1 0',
		flexDirection: 'column',
		justifyContent: 'space-between',
		padding: theme.spacing(4, 1),
	},

	leftCard: {
		background: theme.palette.color.daybreak[20]
	},
	rightCard: {
		background: theme.palette.color.daybreak[60]
	},
	cardDetails:{
		lineHeight: '1.2rem',
	},
	title: {
		display: 'inline-block',
		width: theme.spacing(50),
	},
	chevron: {
		whiteSpace: 'nowrap',
		'& svg': {
			margin: theme.spacing(-.75, -.375),
		}
	},
	gap: {
		height: theme.spacing(.75),
		backgroundColor: theme.palette.color.white
	}
}), { name: 'NextAction' })


const HeadingWithChevron: React.FC<{ children: string }> = ({ children }) => {
	const [first, last] = useMemo(() => {
		const index = children.lastIndexOf(' ');
		const first = children.substring(0, index);
		const last = children.substring(index + 1);
		return [first, last];
	}, [children]);

	const classes = useStyles();
	return (
		<Typography type='heading' variant='tertiary'>
			{first}
			<span className={classes.chevron}>
				&nbsp;
				{last}
				<ChevronRight
					fontSize='medium'
					className={classes.chevron}
				/>
			</span>
		</Typography>
	)
}

type NextActionProps = {
	focusDomain?: string;
	onClick?: () => void;
}
const NextAction: React.FC<NextActionProps> = (props) => {
	const { focusDomain, onClick } = props;
	const classes = useStyles();
	const history = useHistory();

	const handleActivities = () => {
		onClick?.();
		Mixpanel.track('Browse Recommended Activities', {
			origin: window.location.pathname,
			healthDomains: focusDomain
		});
		if (focusDomain) history.push(`/activities/uncommitted?domain=${focusDomain}`, { isBackToHistory:true });
		else history.push('/activities/uncommitted', { isBackToHistory:true });
	}

	const handleContent = () => {
		onClick?.();
		Mixpanel.track('Browse Recommended Content', {
			origin: window.location.pathname,
			healthDomains: focusDomain
		});
		if (focusDomain) history.push(`/learn?domain=${focusDomain}`, { isBackToHistory:true });
		else history.push('/learn', { isBackToHistory:true });
	}

	return (
		<BaseCard className={classes.root}>
			<CardActionArea onClick={ handleActivities } className={clsx(classes.card, classes.leftCard)}>
				<HeadingWithChevron>
					{
						focusDomain ?
							`Browse activities for ${focusDomain}` :
							'View all our activities'
					}
				</HeadingWithChevron>

				<Typography className={classes.cardDetails} disableMargin type='label' variant='primary' >
					Try our activities designed to build healthy lifestyle habits
				</Typography>
			</CardActionArea>

			<CardActionArea onClick={ handleContent } className={clsx(classes.card, classes.rightCard)}>
				<HeadingWithChevron>
					{
						focusDomain ?
							`Explore content for ${focusDomain}` :
							'Explore our learn library'
					}
				</HeadingWithChevron>

				<Typography className={classes.cardDetails} disableMargin type='label' variant='primary'>
					Explore our hints and tips to learn more about your wellbeing
				</Typography>
			</CardActionArea>
		</BaseCard>
	)
}

export default NextAction
