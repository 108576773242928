import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@ui/Button'

const useStyles = makeStyles((theme) => ({
  slideButton: {
    minWidth: 'auto',
    padding: theme.spacing(1),
    marginTop: theme.spacing(30),
  }
}))

const SlideButton = (props) => {
  const {
    children,
    ...otherProps
  } = props;
  const classes = useStyles()

  return (
    <Button className={classes.slideButton} {...otherProps}>
      {children}
    </Button>
  )
}

export default SlideButton
