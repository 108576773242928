import React, { useEffect, createRef, useState, useRef, useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import useEmutableChildren from '@hooks/useEmutableChildren'
import WithView from '../WithView'
import useScrollEvents from '@hooks/useScrollEvents'
import useTransition, { relerp } from '@hooks/useTransition'
import useEffectCallback from '@hooks/useEffectCallback'

const closestIndexOf = (childPositions, scrollTop) => {
  if (!childPositions || !childPositions.length) return -1;
  if (childPositions.length === 1) return 0;
  
  let closest = { dist: 99999999, index: -1 };

  for (let index = 0; index < childPositions.length; index++) {
    const av = Math.floor(childPositions[index]);
    const v = Math.floor(scrollTop);
    const dist = Math.abs(av - v);

    if (dist < closest.dist) closest = { index, dist };
  }

  return closest.index;
}

const useStyles = makeStyles({
  scrollArea: {
    height: '100%',
    overflow: 'auto'
  },
  
  scrollSnapper: {
    display: ({ isActive }) => isActive ? '' : 'none',
    height: '100%'
  }
})

export const ScrollView = React.memo(React.forwardRef((props, ref) => {
  const { isActive, children } = props;
  const classes = useStyles({ isActive });

  return (
    <div ref={ref} className={ classes.scrollSnapper }>
      { children }
    </div>
  )
}));

const ScrollControl = (props) => {
  const {
    view,
    nextIndex,
    onViewChange = () => {},
    onClosestChange = () => {},
    children,
  } = props;

  const classes = useStyles();
  const scrollRef = createRef();

  
  const [ positions, setPositions ] = useState([]);

  const [ closestIndex, setClosestIndex ] = useState(0);
  const updateClosestIndex = index => {
    setClosestIndex(index);
    onClosestChange(index);
  }
  
  //  get a simplified list of the child elements`
  const childList = useEmutableChildren(children, (child, i) => (
    <WithView
      key={ child.key }
      component={ ScrollView }
      child={ child }
      isActive={ i <= nextIndex }
    />
  ));

  //  get/update positions of the elements in the scroll area
  const recalculatePositions = useEffectCallback(() => {
    const offset = scrollRef.current.offsetTop;
    const children = Array.from(scrollRef.current.children);
    const positions = children.map(child => child.offsetTop - offset);

    setPositions(positions);
  })

  useEffect(recalculatePositions, [ nextIndex ]);

  const [ internalView, setInternalView ] = useState(childList[0].key);

  //  position refs for transition
  const isAnimating = useRef(false);
  const startPos = useRef(0);
  const endPos = useRef(0);

  const transition = useTransition({
    duration: 300,
    isAnimating,  //  updates this ref; true when animating, otherwise false

    onStop: () => {
      if (!childList[closestIndex]) return;
      onViewChange(childList[closestIndex].key, closestIndex);
    },
  });

  const scrollTo = (pos) => {
    startPos.current = scrollRef.current.scrollTop;
    endPos.current = pos;
    transition.start();
  }

  //  handle uers scrolling
  useScrollEvents({
    scrollRef,

    shouldIgnoreEvents: () => isAnimating.current,  //  when true; ignores scroll events

    onScroll: () => {
      const scrollPos = scrollRef.current.scrollTop;
  
      const closestIndex = closestIndexOf(positions, scrollPos);
      if (closestIndex === -1) return;
      updateClosestIndex(closestIndex);
    },
    onStart: () => transition.stop(),
    onStop: () => scrollTo(positions[closestIndex]),
  });

  //  update position when animating
  useEffect(
    () => {
      const newPos = relerp(transition.value, startPos.current, endPos.current);
      scrollRef.current.scrollTop = newPos;
    },
    [ transition.value ]
  )

  //  trigger transition to new view
  useEffect(() => {
    const indexOfView = childList.reduce((index, item, i) => item.key === internalView ? i : index, -1);
    if (indexOfView === -1) return;
    if (indexOfView === closestIndex) return;
    updateClosestIndex(indexOfView);
    scrollTo(positions[indexOfView]);
  }, [ internalView ]);

  //  update internal view if external view changes
  useMemo(() => {
    if (view === internalView) return;
    transition.stop();
    setInternalView(view);
    const indexOfView = childList.reduce((index, item, i) => item.key === view ? i : index, 0);
    onViewChange(view, indexOfView);
  }, [ view ]);


  return (
    <div className={ classes.scrollArea } ref={ scrollRef }>
      { childList }
    </div>
  )
}

export default React.memo(ScrollControl)
