import React, { useCallback, useMemo } from 'react'
import { makeStyles, Theme } from '@material-ui/core';
import Typography from '@ui/Typography'
import HorizontalScroll from '@components/HorizontalScroll';
import NextAction from '../NextAction';

import ContentCard from '@components/ContentCard';

import type { IRecommend, IActivity, IContent } from '../Types';
import { useHistory } from 'react-router-dom';
import { ModalityType } from '@ui/Modality/Modality'


interface styleProps {
	contained?: boolean;
	borderWidth: number;
}
const useStyles = makeStyles<Theme, styleProps>(theme => ({
	head: {
		marginBottom: theme.spacing(.625),
	},
	body: {
		marginBottom: theme.spacing(2.5),
	},
	scrollOuter: ({ borderWidth }) => ({
		margin: theme.spacing(0, -borderWidth, 4.5),
		minHeight: theme.spacing(42)
	}),
	scrollInner: ({ borderWidth }) => ({
		margin: theme.spacing(0, borderWidth),
	}),
	recommendation: {
		flexShrink: 0,
		flexGrow: 0,
		width: theme.spacing(50),	//	396 -> 49.5. but no one likes that.
	},
	purple: {
		color: theme.palette.color.navy[100],
	}
}), { name: 'Suggestions' })

interface SuggestionsProps {
	borderWidth?: number;
	allGood?: boolean
	contained?: boolean
	focusDomain?: string
	onNavigate?: () => void
	recommendations: IRecommend[]
	insideAssessment?: boolean
}

const Suggestions: React.FC<SuggestionsProps> = (props) => {
	const {
		borderWidth = 0,
		allGood,
		contained,
		focusDomain,
		recommendations,
		onNavigate,
	} = props;

	const history = useHistory();

	const goto = useCallback((rec: IRecommend) => () => {
		onNavigate?.();
		if (rec.modeltype === 'Activity') return history.push(`/activities/uncommitted/${rec.id}`, { isBackToHistory:true });
		return history.push(`/learn/${(rec as IContent).modality}/${rec.id}`, { isBackToHistory:true });
	}, []);

	const getImage = useCallback((rec: IRecommend) => {
		if (rec.modeltype === 'Activity') return `${process.env.REACT_APP_CONTENT_URL}${(rec as IActivity).imageUrl}`;
		return `${process.env.REACT_APP_CONTENT_URL}${(rec as IContent).image.largeImageUrl}`;
	}, []);

	const getMode = useCallback((rec: IRecommend): ModalityType => {
		if (rec.modeltype === 'Activity') return rec.modeltype;
		return (rec as IContent).modality;
	}, [])

	const classes = useStyles({ contained, borderWidth });

	const heading = useMemo<JSX.Element | string>(() => {
		if (focusDomain) return <>Let's focus on your <span data-test="Heading-FocusDomain" className={classes.purple}>{focusDomain}</span></>
		return 'Suggested activities and resources'
	}, [focusDomain, allGood]);

	const subheading = useMemo<string>(() => {
		if (allGood) return 'Based on your check-in let\'s look at these recommendations from another domain'
		if (contained) return 'Based on your answers let\'s look at these recommendations'
		if (focusDomain) return 'Based on your personal goal let\'s look at these recommendations'
		return 'Improve your wellbeing using our recommendations'
	}, [contained, focusDomain]);

	return (
		<>
			<Typography data-test="Suggestions-Heading"  className={classes.head} type='heading' variant="tertiary" disableMargin={false}>
				{heading}
			</Typography>

			<Typography data-test="Suggestions-Subheading" className={classes.body} >
				{subheading}
			</Typography>

			<HorizontalScroll margin={borderWidth} outerClassName={classes.scrollOuter} innerClassName={classes.scrollInner}>
				{
					recommendations?.map<JSX.Element>((rec: IRecommend) => {
						return (
							<ContentCard
								id={rec.id}
								key={rec.id}
								title={rec.title}
								{...(rec.modeltype !== 'Activity' && { subtitle: (rec as IContent).subtitle })}
								{...(rec.modeltype !== 'Activity' && { source: (rec as IContent).source })}
								{...(rec.modeltype !== 'Activity' && { isBookmark: (rec as IContent).isBookmark })}
								image={getImage(rec)}
								mode={getMode(rec)}
								onClick={goto(rec)}
								className={classes.recommendation}
							/>
						)
					})
				}

				<NextAction onClick={ onNavigate } focusDomain={focusDomain} />
			</HorizontalScroll>
		</>
	)
}

export default Suggestions
