import React, { useContext, useState, useEffect } from 'react'

import userProfileContext from '@context/UserProfileProvider'

import FullScreenDialog, { DialogSection } from '@components/FullScreenDialog'

import PromoSlides from './PromoSlides'
import backgroundImage from './images/background.svg';

import Mixpanel from '@utils/mixpanel'
import { useAPI } from '@hooks/useRequest'

const Promotion = () => {
  const userProfile = useContext(userProfileContext);
	const axios = useAPI();

  const [shouldSeePromotion, setShouldSeePromotion] = useState(false);

  const closePromotion = async () => {
		setShouldSeePromotion(false);
    await axios.post('/UserHasSeenStreaksPromotion')
  }

  useEffect(() => {
    if (userProfile.hasLoaded) {
      // User should only see streaks promotion
      // If they have already onboarded.
      setShouldSeePromotion((userProfile.hasOnboarded && !userProfile.hasSeenStreaksPromotion));
    }
  }, [userProfile?.hasLoaded])

  if (!shouldSeePromotion) {
    return <></>
  } else {
    Mixpanel.track('Promotion Viewed');
  };

  return (
    <FullScreenDialog
      backgroundImage={backgroundImage}
      name='promotion'
      alwaysFullScreen
      isOpen={shouldSeePromotion}
      view={'promoSlides'}
      hasToolbar={false}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    > 
      <DialogSection key='promoSlides'>
        <PromoSlides handleComplete={() => closePromotion()} />
      </DialogSection>
    </FullScreenDialog>
  )
}

export default Promotion
