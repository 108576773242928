import { useState, useMemo } from 'react'

const useAssessment = (assessment) => {  
  const assessmentId = useMemo(() => assessment.id, [ assessment ]);
  
  const instrumentLookup = useMemo(() => (assessment?.instruments ?? []).map(i => [i.id, i]), [ assessment?.instruments ]);
  const instrumentList = useMemo(() => instrumentLookup.map(([id]) => id), [ instrumentLookup ]);
  const instrumentMap = useMemo(() => Object.fromEntries(instrumentLookup), [ instrumentLookup ]);
  const instruments = useMemo(() => instrumentList.map(id => instrumentMap[id]), [ instrumentList, instrumentMap ]);

  const [ instrumentIndex, setInstrumentIndex ] = useState(0);
  const nextInstrument = () => Promise.resolve().then(() => setInstrumentIndex(instrumentIndex + 1))

  const instrumentId = useMemo(() => instrumentList[instrumentIndex], [ instrumentIndex, instrumentList ]);
  const instrument = useMemo(() => instrumentMap[instrumentId], [ instrumentMap, instrumentId ]);
  const healthDomain = useMemo(() => instrument?.healthDomain, [ instrument ]);

  const isAssessmentCompleted = useMemo(() => instrumentIndex === instrumentList.length - 1, [ instrumentList, instrumentIndex ]);
  const showResult = useMemo(() => instrumentList.length && instrumentIndex > instrumentList.length - 1, [ instrumentIndex, assessment ]);


  return {
    assessmentId,
    instrumentIndex,
    instrumentId,
    instruments,
    instrument,
    healthDomain,

    isAssessmentCompleted,
    showResult,

    nextInstrument,
  }
}

export default useAssessment