import { makeStyles } from '@material-ui/core';

import Typography from '@ui/Typography';
import { ReactComponent as EmptyIcon } from '../empty.svg';



interface TEmptySearchContainer  {
	handleClick: () => void;
}

const useStyles = makeStyles(theme => ({
	emptySearchContainer: {
        marginTop: theme.spacing(6),
		display: 'flex',
        flexDirection: 'column',
		alignItems: 'center',
	},
    emptyIcon: {
        width: theme.spacing(8.25),
        height: theme.spacing(8.25),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            width: theme.spacing(16),
            height: theme.spacing(16),
        },
    },
    text: {
        marginBottom: theme.spacing(1),
    },
    link:{
        textDecoration: 'underline',
        cursor: 'pointer',
    }
}),{name: 'EmptySearchContainer'})

const EmptySearchContainer = (props:TEmptySearchContainer) => {

    const { 
        handleClick
      } = props;


    const classes = useStyles();

	return (	
        <section className={classes.emptySearchContainer}>
            <EmptyIcon className={classes.emptyIcon} />
            <Typography className={classes.text} type='body' variant='primary' disableMargin>No results found.</Typography>
            <Typography type='body' variant='primary' disableMargin>Try another search or <span onClick={ handleClick } className={ classes.link }>return to browsing</span></Typography>
        </section>
						
	)
}

export default EmptySearchContainer