import React, { useContext } from 'react'

import useAsyncState, { AsyncState, GenericAsyncState } from '@hooks/useAsyncState';

import PageLoad from '@components/PageLoad';
import { useMemo } from 'react';
import { useImperativeHandle } from 'react';
import { createAsyncContext } from '@context/GenericProvider';
import ErrorSplash, { ErrorSplashProps } from '@components/ErrorSplash';

type OnSuccessHandle <Data = any> = (data: Data) => React.ReactElement;
type OnErrorHandle <AsyncStore, Err = Error> = (error: Err, store: AsyncStore) => React.ReactElement;

interface WaitForRef {
	refresh?: () => void
}

type BaseWaitForProps <Data, Err> = {
	onSuccess: OnSuccessHandle<Data>
	onError?: OnErrorHandle<GenericAsyncState<Data, Err>, Err>

	fallback?: React.ReactElement
	children?: never

	promise?: unknown
	context?: unknown
	store?: unknown
}

type WaitForPromiseProps <Data = any, Err = Error> = BaseWaitForProps<Data, Err> & {
	promise: () => Promise<Data | never>
	context?: never
	store?: never
}

type WaitForContextProps <Data = any, Err = Error> = BaseWaitForProps<Data, Err> & {
	promise?: never
	context: React.Context<GenericAsyncState<Data, Err>>
	store?: never
}

type WaitForStoreProps <Data = any, Err = Error> = BaseWaitForProps<Data, Err> & {
	promise?: never
	context?: never
	store: GenericAsyncState<Data, Err>
}


const defaultFallback = <PageLoad />;

//	TODO: DEPRECATE REF FORWARD. THIS IS DUMB
export const WaitForWithTypes = <Data extends unknown = any, Err extends unknown = Error>() => {
	type WaitForProps = WaitForPromiseProps<Data> | WaitForContextProps<Data, Err> | WaitForStoreProps<Data, Err>;

	const defaultError: OnErrorHandle<Err> = (error): React.ReactElement => <ErrorSplash {...(error as ErrorSplashProps)} />;

	const defaultPromise = () => Promise.reject('No promise for AsyncState was defined.');
	const defaultContext = createAsyncContext<Data, Err>();

	const WaitForComponent = React.forwardRef<WaitForRef, WaitForProps>((props, ref) => {
		const {
			context,
			promise,
			store: directStore,

			onSuccess,
			onError = defaultError,
			fallback = defaultFallback,
		} = props;

		const contextStore: GenericAsyncState<Data, Err> = useContext(context ?? defaultContext);
		const promiseStore: GenericAsyncState<Data, Err> = useAsyncState(promise ?? defaultPromise);

		const watchForChanges = [
			contextStore?.state,
			contextStore?.data,
			contextStore?.error,
			promiseStore?.state,
			promiseStore?.data,
			promiseStore?.error,
			directStore?.state,
			directStore?.data,
			directStore?.error
		]

		const finalStore: GenericAsyncState<Data, Err> = useMemo(() => {
			if (context) return contextStore;
			if (promise) return promiseStore;
			if (directStore) return directStore;
			throw new Error('No store interface was found. Provide one of context, promise or store.');
		}, watchForChanges);

		useImperativeHandle(ref, () => ({
			refresh: finalStore.reload
		}), [context, promise, directStore]);

		if (finalStore?.state === AsyncState.REJECTED) {
			const error: Err | undefined = finalStore.error;

			// 	We having problems with error type being set as (Error & Err) | undefined.
			//	No idea how this is being interpreted
			//	@ts-ignore
			return onError(error, finalStore);
		} else if (finalStore?.state === AsyncState.FULFILLED) {
			return onSuccess(finalStore.data as Data);
		} else {
			return fallback;
		}
	})

	return WaitForComponent;
}

const WaitFor = WaitForWithTypes<any, Error>();
export default WaitFor;