import React from 'react'
import { useAPI } from '@hooks/useRequest'
import GenericProvider, { AdditionalContext, createAsyncContext } from '@context/GenericProvider'

interface IQuestionnaire {
	id: string
	type: string
}

type TQuestionnaireProvider = {
	children?: React.ReactNode;
}

const questionnaireListContext = createAsyncContext<IQuestionnaire[]>();
export default questionnaireListContext;

export const QuestionnaireListProvider: React.FC<TQuestionnaireProvider> = (props) => {
	const {children} = props;
	const axios = useAPI();
	const getAssessments = () => axios.get('/Assessments');

	//	TODO: Figure out how to constrain this interface to the type of GenericAsyncState<Data>
	const additionalContext: AdditionalContext<IQuestionnaire[]> = {
		fullQuestionnaireId: ({ data }) => {
			return data?.find(item => item.type === 'fullquestionnaire')?.id
		}
	}

	return (
		<GenericProvider
			selfInit
			context={questionnaireListContext}
			promise={getAssessments}
			children={children}
			additionalContext={additionalContext}
		/>
	)
}
