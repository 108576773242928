import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import InnoWellLogo from '@components/InnoWellLogo';
import authContext from '@context/AuthProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1, // TODO: the header needs its own z-index value, not this magic number crap
    height: theme.spacing(7),
    display: 'flex',
    justifyContent: 'space-around',
    boxShadow: 'none',
  },
  toolBar: {
    alignSelf: 'flex-start',
    width: '100%',
  },
  headerMenu: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.color.navy[100]}`,
  },
  menuButton: {
    position: 'absolute',
    left: 0,
  }
}));

const AppHeader = (props) => {
  const {
    context,
  } = props;

  const authStore = useContext(authContext);
  const { isAuthenticated } = authStore.data ?? {};

  const openMenu = (event) => {    
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    
    if (context?.open) context.open();
  }

  const classes = useStyles();
  
  return (
    <>
      {
        isAuthenticated && (
          <Hidden mdUp>
            <AppBar position='sticky' color='inherit' className={ classes.root }>
              <Toolbar variant='dense' className={ classes.toolBar }>
                <nav className={classes.headerMenu}>
                  <IconButton edge='start' className={ classes.menuButton } color='inherit' aria-label='menu' onClick={ openMenu }>
                    <MenuIcon />
                  </IconButton>
                  <figure className={classes.logo}>
                    <Link to={'/'}><InnoWellLogo /></Link>
                  </figure>
                </nav>
              </Toolbar>
            </AppBar>
          </Hidden>
        )
      }
    </>
  );
}

export default AppHeader;
