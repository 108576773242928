import { InfoOutlined } from '@material-ui/icons'
import React from 'react'
import { NavItem } from '@components/AppMenu'

const AboutNavItem = () => {
  return (
    <NavItem
      label='About'
      icon={ <InfoOutlined /> }
      path='/about'
    />
  )
}

export default AboutNavItem
