import React from 'react'

import Markdown from 'markdown-to-jsx';
import Typography from '@ui/Typography';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import FeatureCard from '@components/Recommendations/FeatureCard';

const overrides = (classes) => ({
  h1: {
    component: Typography,
    props: {
      type: 'heading',
      variant: 'primary',
      component: 'h1',
      disableMargin: false,
    }
  },
  h2: {
    component: Typography,
    props: {
      type: 'heading',
      variant: 'primary',
      component: 'h2',
      disableMargin: false,
    }
  },
  h3: {
    component: Typography,
    props: {
      type: 'heading',
      variant: 'secondary',
      component: 'h3',
      disableMargin: false,
    }
  },
  h4: {
    component: Typography,
    props: {
      type: 'heading',
      variant: 'tertiary',
      component: 'h4',
      disableMargin: false,
    }
  },
  p: {
    component: Typography,
    props: {
      type: 'body',
      variant: 'primary',
      disableMargin: false,
    }
  },
  li: {
    component: Typography,
    props: {
      type: 'body',
      variant: 'primary',
      component: 'li',
      disableMargin: false,
    }
  },
  a: props => {
    const { children, href } = props;
    const isExternal = href.startsWith('http') && !href.startsWith(process.env.REACT_APP_URL);
    const isNotUrl = href.startsWith('tel:') || href.startsWith('mailto:') || href.startsWith('#');

    if (isExternal) return (
      //  cant use Link for external apps,
      //  since it uses history.push under the hood,
      //  it would just inject `/` infront of the href.
      <a
        href={href}
        children={children}
        target="_blank"
        rel="noreferrer"
      />
    )

    if (isNotUrl) return (
      <a
        href={href}
        children={children}
      />
    )
    
    else return (
      <Link
        to={href}
        children={children}
      />
    )
  },
  img: props => {
    const { alt = '', title = '', src } = props;
    return (
      <div className={classes.imageContainer}>
        <img
          alt={alt}
          title={title}
          src={src.startsWith('/') ? `${process.env.REACT_APP_CONTENT_URL}${src}` : src}
        />
      </div>
    )
  },
  div: props => {
    const {
      //  not sure which will be set
      class: _class,
      className: _className,
      ...otherProps
    } = props;

    /**
     * in this specific order, future proofs against
     * the markdown lib swaps back to `className`,
     * instead of `class` which they seem to have done.
     */
    const divProps = {
      ...otherProps,
      className: _className ?? _class,  //  prefer `className` over `class`.
    };

    if (divProps.className?.includes('summary'))
      return <FeatureCard {...divProps} className={classes.summary} />

    return <div {...divProps} /> // eslint-disable-line react/jsx-props-no-spreading
  }
})


const useStyles = makeStyles(theme => ({
  imageContainer: {
    width: '100%',
    margin: theme.spacing(2, 0),
    '& img': {
      display: 'block',
      margin: 'auto',
      width: '100%',
      maxWidth: 280,
    }
  },
  summary: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 3, 1),
    '& h3': {
      marginTop: theme.spacing(3)
    }
  }
}))

const MarkdownToJsx = props => {
  const {
    children,
    noWrapper = false
  } = props;

  const classes = useStyles();

  const options = {
    overrides: overrides(classes),
    wrapper: noWrapper === true ? React.Fragment : 'div',
  }

  return (
    <Markdown
      options={options}>
        { children }
    </Markdown>
  )
}

export default MarkdownToJsx
