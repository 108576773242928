import React from 'react'
import { Collapse, Container, makeStyles } from '@material-ui/core'
import FullBleed from '@components/FullBleed'

const useStyles = makeStyles(theme => ({
  notification: {
    backgroundColor: theme.palette.color.mint[20],
    '--bgColor': theme.palette.color.mint[20],
  },
  container: {
    padding: ({ thin }) => thin ? theme.spacing(0, 1.5) : '',
  }
}));

const AppNotificationWrapper = ({ fullBleed, children, className }) => fullBleed ? (<FullBleed className={className}>{children}</FullBleed>) : (<>{children}</>)

const AppNotification = (props) => {
  const {
    isOpen = false,
    thin,
    fullBleed = false,
    children,
  } = props;

  const classes = useStyles({ thin });
  
  return (
    <AppNotificationWrapper 
      className={ classes.notification }
      fullBleed={fullBleed}>
      <Collapse
        className={ classes.notification }
        in={ isOpen }
      >
        <Container className={ classes.container }>
          { children }
        </Container>
      </Collapse>
    </AppNotificationWrapper>
  )
}

export default AppNotification
