import React, { Suspense, useEffect } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import { grid } from '@ui/brandy';

import CoreProvider from '@context/CoreProvider';
import useToggle from '@hooks/useToggle';

import AppHeader from '@components/AppHeader';
import AppMenu from '@components/AppMenu';
import PageLoad from '@components/PageLoad';
import Onboarding from '@components/Onboarding';
import Promotion from '@components/Promotion';

import AppRoute from './AppRoute';
import { ModalRenderer } from '@context/ModalProvider';

import Mixpanel from '@utils/mixpanel';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  body: {
    width: '100%',
  },
  main: {
    display: 'grid',
    maxWidth: '100%',
    gridTemplateColumns: grid.page.rootMobile,
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: grid.page.rootDesktop,
    },

    /**
     * Annoyingly, this is very strict in useStyles,
     * so we need !important rules in fullBleed,
     * and other comps.
     */
    '& > section': {
      gridColumn: grid.page.content,
    },
  },
}), { name: 'AppShell' });


const NotEmptyRoutes = ({ routes }) => {
  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname === '/offline' && navigator.onLine)
        return history.push('/');

      else if (location.pathname !== '/offline' && !navigator.onLine)
        return history.push('/offline');

      Mixpanel.track('Visit Page', {
        location: location.pathname,
      });
    })
  }, [])

  return (
    <Switch>
      {
        routes.map((route) => (
          <AppRoute
            {...route}
            key={route.path}
          />
        ))
      }
    </Switch>
  )
};

const EmptyRoutes = () => (
  <Route path='*'>
    <h1>No routes are configured</h1>
  </Route>
);


const AppShell = (props) => {
  const {
    routes = [],
    footerNavItems = [],
    authProvider: AuthProvider,
  } = props;

  if (!AuthProvider) {
    throw new Error('AppShell requires an auth provider');
  }

  const menuContext = useToggle();
  const classes = useStyles();

  return (
    <CoreProvider authProvider={ AuthProvider }>
      <div className={ classes.root }>
        <AppHeader context={ menuContext } />

        <div className={ classes.body }>
          <AppMenu
            context={ menuContext }
            routes={ routes }
            footerNavItems={ footerNavItems }
          />

          <main className={classes.main}>
            <Suspense fallback={ <PageLoad /> }>
              <Onboarding />
              <Promotion />
              {
                routes?.length ?
                  <NotEmptyRoutes routes={ routes } /> :
                  <EmptyRoutes />
              }
            </Suspense>
          </main>
        </div>
      </div>

      <ModalRenderer />
    </CoreProvider>
  )
}

export default AppShell
