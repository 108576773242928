import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import authContext, { IAuthData, IAuthErr, AuthErrState } from '@context/AuthProvider';
import { WaitForWithTypes } from '@components/WaitFor';

import ErrorSplash from '@components/ErrorSplash';
import PageLoad from '@components/PageLoad';
import Button from '@ui/Button';

export type AppRouteProps = {
  path: string
  component: React.FC
  isPublic?: true
  label?: unknown
  icon?: unknown

  [otherProps: string]: unknown
}

export type MenuRouteProps = AppRouteProps & {
  icon: React.ReactNode
  label: string
  exact?: true
}


const WaitFor = WaitForWithTypes<IAuthData, IAuthErr>();

const ProtectedRoute: React.FC<AppRouteProps & RouteProps> = (props) => {
  const {
    component: RouteComponent,
    isPublic = false,
    ...otherProps
  } = props;

  /**
   * This should prevent the login from being called on public pages.
   */
  if (isPublic) return <Route
    {...otherProps}
    component={ RouteComponent }
  />

  return (
    <WaitFor
      context={authContext}
      fallback={
        <ErrorSplash
          heading={<PageLoad />}
          subheading={null}
          action={null}
        />
      }

      /**
       * Errors can be controlled from the authProvider,
       * from inside the useAsyncStateMap handler.
       */
      onError={ (error, store) => {
        const login = () => store?.data?.login?.() ?? window.location.reload();

        switch (error.err) {
          case AuthErrState.ACCOUNT_INVALID: return (
            <ErrorSplash
              heading="Hmm, we can't find that account."
              subheading="Sorry about that, looks like there's an error. Please contact your administrator to help us look."
              action={<Button onClick={login}>Sign in</Button>}
            />
          )

          case AuthErrState.CREATE_ACCOUNT_FAILED: return (
            <ErrorSplash
              heading="Looks like that didn't work."
              subheading="Sorry about that. Let's try again but contact your administrator if the error persists."
              action={<Button onClick={login}>Sign in</Button>}
            />
          )

          case AuthErrState.USER_SIGNED_UP: return (
            <ErrorSplash
              heading="Looks like you already have an account."
              subheading="You're ready to go. Log in using the button below to continue your Innowell journey."
              action={<Button onClick={login}>Sign in</Button>}
            />
          )


          case AuthErrState.INVITE_INVALID: return (
            <ErrorSplash
              heading="Hmm, we can't find your invitation"
              subheading="Please contact your administrator and we'll get that fixed."
              action={<Button onClick={login}>Sign in</Button>}
            />
          )

          case AuthErrState.INVITE_EXPIRED: return (
            <ErrorSplash
              heading="Your invitation has expired."
              subheading='Click the Sign in button and use "Find your account" to request a new link.'
              action={<Button onClick={login}>Sign in</Button>}
            />
          )

          case AuthErrState.POLICY_CHANGED: return (
            <ErrorSplash
              heading="We've never even seen this error before"
              subheading="We've encountered an unknown error. Sorry about that. Let's try again but contact your administrator if the error persists."
              action={<Button onClick={login}>Sign in</Button>}
            />
          )


          case AuthErrState.USER_INVALID: return (
            <ErrorSplash
              heading="Hmm, we can't find you."
              subheading="Sorry about that. Please contact your administrator to help us look."
              action={<Button onClick={login}>Sign in</Button>}
            />
          )

          case AuthErrState.UNKNOWN:
          default: return (
            <ErrorSplash
              heading="Oops! The page wasn't feeling well."
              subheading="Sorry about that, let's try again."
              action={null}
              errorCode={error.errorCode}
              errorId={error.errorId}
            />
          )
        }
      }}

      onSuccess={
        data => {
          if (data.isAuthenticated) return (
            <Route
              { ...otherProps }
              component={ RouteComponent }
            />
          )

          data.login();
          return (
            <ErrorSplash
              heading='Not logged in.'
              subheading=''
              action={<button type='button' onClick={data.login}>Login</button>}
            />
          )
        }
      }
    />
  )
}

export default ProtectedRoute;
