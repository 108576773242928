import React from 'react'
import { makeStyles, useMediaQuery } from '@material-ui/core'

import { ResultCardList } from '@components/ResultCard'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: "15px"
	},
	resultColumn: {
		width: '100%',
		'& .MuiCard-root': {
			height: '100%',
		},
		[theme.breakpoints.up('sm')]: {
			width:  `calc(50% - ${theme.spacing(2)}px)`,
		},
		'@media (min-width:680px)': {	//	TODO: introduce breakpoint between mobile and desktop around this size.
			width: `calc(25% - ${theme.spacing(2)}px)`,
		},
	}
}))

const HomeResults = (props) => {
	const classes = useStyles();
	const screenMedium = useMediaQuery(theme => theme.breakpoints.up('sm'));

	const { marks, onClick } = props;

	return (
		<div className={ classes.root }>
			<ResultCardList
				variant={screenMedium ? 'dial' : 'inline'}
				className={ classes.resultColumn }
				marks={ marks }

				onClick={ onClick }
			/>
		</div>
	)
}

export default HomeResults
