import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const StyledLinearProgress = withStyles((theme) => ({
  root: {
    height: theme.spacing(.75),
  },
  colorPrimary: {
    backgroundColor: theme.palette.color.navy[100],
  },
  bar: {
    background: theme.palette.color.dawn,
  },
}))(LinearProgress);

const SegmentedBar = (props) => {
  const {
    filled,
    max,
    
    className,
  } = props;

  const progress = Math.floor(100/max*filled);

  return (
    <StyledLinearProgress className={className} variant='determinate' value={progress}/>
  );
}

export default SegmentedBar
