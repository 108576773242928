import React from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Typography from '@ui/Typography';

const useStyles = makeStyles((theme) => ({
  navItem: {
    overflow: 'hidden',
    borderRadius: '0px 10px 10px 0px',
    padding: 0,
    marginRight: theme.spacing(2),
    minHeight: '0', // The mui MenuItem gets a minimum height on mobile which this undoes for all viewports
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: theme.palette.color.navy[100],
    },
  },
  hitArea: {
    padding: theme.spacing(1.25, 0, 1.25, 2.5),
    width: '100%',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  icon: {
    minWidth: theme.spacing(4.5),
    marginRight: theme.spacing(1.5),
    '& svg': {
      fill: theme.palette.color.navy[100],
      fontSize: theme.spacing(4),
      '.Mui-selected &': {
        fill: theme.palette.color.white,
      }
    },
  },
  label: {
    color: theme.palette.color.navy[100],
    '.Mui-selected &': {
      color: theme.palette.color.white,
    }
  },
}));


const NavItem = (props) => {
  const {
    icon,
    label,
    path = '',
    onClick,
  } = props;

  const classes = useStyles();
  const { pathname } = useLocation();
  const parentPathname = pathname.replace(/^\/([^/]*).*$/, '/$1');
  const isActive = parentPathname === path;

  const navItem = (
    <>
      <ListItemIcon className={ classes.icon }>
        { icon }
      </ListItemIcon>
      <Typography disableMargin type='label' variant='primary' component='span' className={ classes.label }>
        { label }
      </Typography>
    </>
  )

  if (typeof onClick === 'function') return (
    <MenuItem
      selected={ isActive }
      className={ clsx(classes.navItem, classes.hitArea) }
      onClick={ () => onClick() }
    >
      { navItem }
    </MenuItem>
  );

  return (
    <MenuItem
      selected={ isActive }
      className={ classes.navItem }
    >
      <Link
        to={ path }
        className={ clsx(classes.link, classes.hitArea) }
      >
        { navItem }
      </Link>
    </MenuItem>
  )
}

export default NavItem;
