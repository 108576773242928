import React from 'react'

import {
  makeStyles
} from '@material-ui/core'
import Typography from '@ui/Typography'

import { ReactComponent as AtRisk } from './at-risk.svg';
import { ReactComponent as Fair } from './fair.svg';
import { ReactComponent as Good } from './good.svg';

const useStyles = makeStyles(theme => ({
	progressContainer: {
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '70px',
	},
	progressLabel: {
		display: 'block',
		textAlign: 'center',
		position: 'absolute',
		//	this is uncomfortable for me.
		transform: 'translate(1px, -2px)',
		maxWidth: theme.spacing(7),
    	color: theme.palette.color.purple[100],
	},
}), {name: 'Dial'})

const Dial = (props) => {
  const {
    grade,
    label,
  } = props;
	const classes = useStyles();

	return (
    <div data-test='Dial' className={ classes.progressContainer }>
      { grade === 1 && <AtRisk className={classes.progress} /> }
      { grade === 2 && <Fair className={classes.progress} /> }
      { grade === 3 && <Good className={classes.progress} /> }

      <Typography data-test='Dial-Label' disableMargin className={ classes.progressLabel } component='span' type='label' variant='secondary'>{ label }</Typography>
    </div>
	)
}

export default Dial
