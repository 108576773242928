const reduceToReactElements = (allChildren = [], children = []) => {
  return children.reduce((all, child) => {
    if (!child) return all;

    if (Array.isArray(child)) {
      return reduceToReactElements(all, child);
    }

    if (child.$$typeof === Symbol.for('react.element')) {
      return [ ...all, child ];
    }

    console.warn(child, 'is not a react.element');
    return [ ...all ]
  }, allChildren);
}

const useEmutableChildren = (children, mapFn) => {
  const childList = reduceToReactElements(
    [],
    Array.isArray(children) ?
      children : [children]
  );

  try {
    return childList.map(mapFn);
  }
  catch {
    return childList;
  }
}

export default useEmutableChildren;