import { useCallback, useEffect, useRef } from "react";

const msFromMins = (mins: number) => mins * 60 * 1000;

const useTimer = (onTimeout: () => void) => {
	const timerRef = useRef<NodeJS.Timeout>();
	const triggerAt = useRef<number>();

	const checkTime = useCallback(() => {
		if (!triggerAt.current) return;
		const hasTimedout = Date.now() > triggerAt.current;
		
		if (hasTimedout) triggerTimeout();
		else timerRef.current = setTimeout(() => checkTime(), 1000);
	}, [])

	const triggerTimeout = useCallback(() => {
		onTimeout();
		cancelTimer();
	}, [])

	const startTimer = useCallback((timeoutInMins: number) => {
		cancelTimer();

		triggerAt.current = Date.now() + msFromMins(timeoutInMins);
		checkTime();
	}, [])

	const cancelTimer = useCallback(() => {
		if (timerRef.current) clearTimeout(timerRef.current);
	}, [])

	useEffect(() => cancelTimer, []);

	return {
		startTimer,
		cancelTimer,
	}
}

export default useTimer