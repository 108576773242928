import React from 'react'
import Link from '@material-ui/core/Link'
import ErrorSplash from '@components/ErrorSplash'

const Offline = () => {
  return (
    <ErrorSplash
      heading='You are offline.'
      subheading='Check your connection. You might be connected to wifi, but there is no internet. Once you are online again you can reload the page.'
      action={<Link href='/'>Retry Home</Link>}
    />
  )
}

export default Offline
