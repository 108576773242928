import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

import Typography from '@ui/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(4),
  },
  container: {
    position: 'relative',
    zIndex: 0,
    width: '100%',

    '&:after': {
      content: '""',
      position: 'absolute',
      height: '100%',
      top: 0,
      left: 0,
      right: 0,
      zIndex: -1,
    }
  },
  title: { 
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    color: theme.palette.grey[600],
    marginLeft: theme.spacing(1),
  },
  inlineTitle: {
    display: 'inline-flex',
  },
  centered: {
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))

export default function DialogSection(props) {
  const {
    centered,
    title,
    subtitle,
    children,
  } = props;

  const classes = useStyles();

  const classList = [classes.container];
  if (centered) classList.push(classes.centered);

  const hasSubtitle = (subtitle && subtitle.length > 0)

  return (
    <div className={ clsx(...classList) }>
        <div className={ classes.root }>
          {
            title && (
              <Typography type='heading' variant='secondary' className={ clsx(classes.title, (hasSubtitle) && classes.inlineTitle) }>
                { title }
              </Typography>
            )
          }
          {
            subtitle && (
              <Typography type='body' variant='secondary' color='lowEmphasis' className={ clsx(classes.subtitle, (hasSubtitle) && classes.inlineTitle) }>
                { subtitle }
              </Typography>
            )
          }
          { children }
        </div>
    </div>
  )
}
