import React, { ChangeEvent, useRef, useEffect,useState } from 'react'
import clsx from 'clsx';
import { makeStyles, useMediaQuery, Theme, TextField, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';

import {TSearchState} from '../Learn';

import Button from '@ui/Button'
import ChipNavigation from '@components/ChipNavigation';
import { useAPI } from '@hooks/useRequest'

import { ReactComponent as SearchIcon } from '../search.svg';

interface StyleProps { searchToggleActive: boolean; }

interface TLearnCardListHeader  {
    domains: string[];
    filter: string;
    setFilter: (filter: string) => void;
	setSearchResults: (searchResults: [] | null) => void;
	searchState:TSearchState;
	setSearchState: any;
	resetSearch: () => void;
}


const modalities = [
    {value: 'All', text: 'All'},
    {value: 'Article', text: 'Read'},
    {value: 'Podcast', text: 'Listen'},
];

const useStyles = makeStyles<Theme, StyleProps>((theme) => {
	const hoverStyles = {
	  '&:hover': {
		backgroundColor: ({ searchToggleActive }: StyleProps) =>
		  searchToggleActive ? theme.palette.color.mint[20] : theme.palette.color.mint[100],
	  },
	};
  
	return {
		headerSection: {
			display: 'flex',
			flexDirection: 'column',
			marginBottom: theme.spacing(3),
		},
		scrollRef: {
			marginBottom: theme.spacing(2),
		},
		tabSection: {
			display: 'flex',
		},
		searchButton: {
			backgroundColor: ({ searchToggleActive }) =>
				searchToggleActive ? theme.palette.color.mint[20] : theme.palette.color.mint[100],
			minWidth: theme.spacing(3.7),
			minHeight: theme.spacing(3.7),
			maxHeight: theme.spacing(3.7),
			padding: 0,
			marginRight: theme.spacing(0.5),
			border: `1px solid ${theme.palette.color.mint[100]}`,
			...hoverStyles, // Include the hover styles here
		},
		searchIcon: {
			width: theme.spacing(1.7),
		},
		searchFilterSection:{
			maxHeight: 0,
			overflow: 'hidden',
			transition: 'opacity 0.2s ease-in-out',
			opacity: 0,
			width: '100%',
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			borderBottom: `1px solid ${theme.palette.color.grey[100]}`,
			flexWrap: 'wrap',
			[theme.breakpoints.up('md')]: {
				flexWrap: 'nowrap'
			},
		},
		searchFilter:{
			maxHeight: '1000px', 
			opacity: 1,
			paddingBottom: theme.spacing(4)
		},		
		description:{
			width: '100%',
			marginBottom: theme.spacing(2),
			'& input':{
				padding: theme.spacing(2,1.5)
			},
			[theme.breakpoints.up('md')]: {
				width: '55%',
				marginBottom: theme.spacing(0),
			},
		},
		radioGroup:{
			width: '60%',
			flexWrap: 'nowrap',
			justifyContent: 'space-between',
			'& .MuiFormControlLabel-root':{
				marginBottom: theme.spacing(0)
			},
			'& .MuiFormControlLabel-root:last-child' : {
				marginRight: theme.spacing(0)
			},
			[theme.breakpoints.up('md')]: {
				width: '35%',
				justifyContent: 'center',
			},
		},
		radioItem:{
			'&.MuiRadio-root' :{
				paddingRight: theme.spacing(0.5),
			},

		},
		button:{
			width: '25%',
			height: theme.spacing(6),
			[theme.breakpoints.up('md')]: {
				width: '12%',
				marginBottom: theme.spacing(0),
			},
		},

	};
  }, { name: 'LearnCardListHeader' });



const LearnCardListHeader = (props:TLearnCardListHeader) => {

    const { 
        domains,
		searchState,
		setSearchState,
        filter,
        setFilter,
		setSearchResults,
		resetSearch
      } = props;

	const axios = useAPI();

	const scrollRef = useRef<HTMLDivElement>(null);

	const [shouldScroll, setShouldScroll] = useState(false);

	const mediumScreen = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));

	const classes = useStyles({ searchToggleActive: searchState.searchToggleActive });


	const handleSearchIcon = () => {
		setSearchState((prevState: TSearchState)  => ({
			...prevState,
			searchToggleActive: !prevState.searchToggleActive,
		}));
	}

	const handleDomainFilterChange = (filter:string) => {
		resetSearch();
		setFilter(filter);
	}

	const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value as 'All' | 'Article' | 'Podcast';
		setSearchState((prevState: TSearchState) => ({
			...prevState,
			modality: value
		}));
	}

	const handleSearch = async () => {
		setSearchState((prevState: TSearchState) => ({
			...prevState,
			isSearching: true
		}));
		if(searchState.searchText.length > 0) {
			await axios.post('/v1/LearningResourceSearch', {
				search: searchState.searchText,
				modality: searchState.modality,
				healthDomain: filter,
				skip:0
			}).then((response)=> setSearchResults(response.data));
		}
		else {
			setSearchResults(null);
		}
		setSearchState((prevState: TSearchState) => ({
			...prevState,
			isSearching: false
		}));
		setShouldScroll(true);
	}


    useEffect(() => {
        if (shouldScroll) {
			const currentScrollPosition = scrollRef?.current?.getBoundingClientRect().top;
			// Calculate the target scroll position (current position - 56 pixels) because of the existence of sticky header of 56px in the smaller screens
			const targetScrollPosition = currentScrollPosition && currentScrollPosition - 56;
			// Scroll to the calculated target position
			window.scrollTo({
				top: mediumScreen ? currentScrollPosition : targetScrollPosition,
				behavior: 'smooth',
			});
			setShouldScroll(false);
        }
    }, [handleSearch]);


	return (	
		<section className={classes.headerSection}>
			<div ref={scrollRef} className={classes.scrollRef}/>
			<div className={classes.tabSection} >
				<Button className={classes.searchButton} edge='start' color='inherit' aria-label='menu' onClick={handleSearchIcon} >
					<SearchIcon className={classes.searchIcon}/>
				</Button>
				<ChipNavigation
					items={['All', ...domains]}
					selected={filter}
					onChange={handleDomainFilterChange}
				/>
			</div>
			
			<div className={clsx(classes.searchFilterSection, searchState.searchToggleActive && classes.searchFilter)} >
				<TextField
					className={classes.description}
					maxRows={1}
					placeholder={'e.g. I want better sleep, Andrew Huberman'}
					InputLabelProps={{
						shrink: true,
					}}
					value={searchState.searchText}
					variant='outlined'
					onChange={ (e:ChangeEvent<HTMLInputElement>) => setSearchState((prevState: any) => ({
						...prevState,
						searchText: e.target.value,
					}))}
				/>
				<RadioGroup row className={classes.radioGroup}  aria-labelledby="modality-radio" name="modality-radio-group" onChange={handleRadioChange} value={searchState.modality} >
					{
						modalities.map(modality => {
							return (
								<FormControlLabel
									key={ modality.value }
									label={ modality.text }
									value={ modality.value }
									control={ <Radio className={classes.radioItem}/> }
								/>
							);
						})
					}
				</RadioGroup>
				<Button onClick={handleSearch} className={classes.button}>Search</Button>
			</div>
		</section>
						
	)
}

export default LearnCardListHeader