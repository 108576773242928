import React, { useState, useMemo } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';

import Typography from '@ui/Typography'
import Button from '@ui/Button'
import Header from '@components/Header';


import Mixpanel from '@utils/mixpanel'
import { useAPI } from '@hooks/useRequest';

const useStyles = makeStyles((theme) => ({
  slide: {
    margin: `0 auto ${theme.spacing(5)}px`,
    textAlign: 'center',
  },
  slideTitle: {
    color: theme.palette.color.navy[100],
  },
  slideImage: {
    display: 'block',
    marginBottom: theme.spacing(0),
    height: '320px',
    '& > img': {
      maxHeight: '300px',
      transform: 'translateX(-10px)'
    }
  },
  centeredButton: {
    margin: 'auto',
    display: 'block',
  },
  helperText: {
    color: theme.palette.color.navy[100],
    textAlign: 'center',
    marginTop: theme.spacing(2)
  }
}))

const Goals = (props) => {
  const {
    handleCompleteGoal
  } = props

  const classes = useStyles()
	const axios = useAPI();

  const [ goal, setGoal ] = useState('')
  const [ isSubmitting, setIsSubmitting ] = useState(false)

  const disableSave = useMemo(() => {
    if (goal.trim() === '') return true;
    return false;
  }, [goal])

  const handleChangeGoal = (event) => {
    setGoal(event.target.value)
  }

  const postGoal = async () => {
    await axios.post('/UserGoal', {
			goal: goal.trim()
		})
  }

  const handleSubmitGoal = async () => {
    setIsSubmitting(true)
    await postGoal()
    setIsSubmitting(false)
    Mixpanel.track('Save Goal', {
      origin: 'Onboarding'
    })
    handleCompleteGoal()
  }

  return (
    <>
      <Header promoHeader disableMargin/>
      <Container maxWidth='md'>
        <div className={classes.slide}>
          <figure className={classes.slideImage}>
          <img alt='goal' src={`${process.env.REACT_APP_URL}/images/star.svg`}/>
          </figure>

          <Typography type='heading' variant='primary' className={classes.slideTitle}>Set a wellness goal</Typography>
          <TextField
            label='I want to'
            multiline
            rows={4}
            InputLabelProps={{
              shrink: true,
            }}
            variant='outlined'
            helperText={
              <Typography type='body' variant='secondary' className={classes.helperText}>
                Write something as a reminder for your future self to help you stay motivated.
              </Typography>
            }
            onChange={handleChangeGoal}
          />
        </div>
        <Button className={classes.centeredButton} disabled={(disableSave || isSubmitting)} onClick={handleSubmitGoal} isLoading={isSubmitting}>
          Save
        </Button>
      </Container>
    </>
  )
}

export default Goals
