import React from 'react'
import ResultCard from '../ResultCard';

const ResultCardList = (props) => {
	const {
		answerSetId,
		marks = [],
		variant,
		className,
		noDate = false,
		onClick,
	} = props;

	return marks.map(mark => {
		const {
			instrumentId,
			grade,
			label,
			markTime,
		} = mark

		return (
			<div className={ className } key={ instrumentId }>
				<ResultCard
					title={label}
					grade={grade}
					variant={variant}
					date={ !noDate && markTime }

					onClick={ () => onClick?.(instrumentId, answerSetId ?? mark.assessmentAnswerId ?? mark.answerSetId) }
				/>
			</div>
		)
	})
}

export default ResultCardList
