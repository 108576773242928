import React, { useCallback, useMemo, useState, useRef } from "react";
import { useMsal } from '@azure/msal-react';

import { createAsyncContext } from '@context/GenericProvider';
import { AsyncState, useAsyncStateMap } from '@hooks/useAsyncState';
import { AccountInfo, AuthenticationResult, AuthError } from '@azure/msal-browser';

import { requests } from './msalConfig';

export type IAuthData = {
	account?: AccountInfo
	hasAccess: boolean
	hasAccount: boolean
	isAuthenticated: boolean

	login: () => void
	signup: (tokenHint: string) => void
	localsignin: () => void
	findAccount: () => void
	logout: () => void
	getAccess: () => Promise<string | void>
}

export enum AuthErrState {
	UNKNOWN = 'UNKNOWN',
	RESET_CANCELLED = 'RESET_CANCELLED',
	POLICY_CHANGED = 'POLICY_CHANGED',

	// InvitationExpired
	// InvitationNotFound
	// UserAlreadySignup
	// UserAlreadySignupViaOtherOrganisation
	INVITE_INVALID = 'INVITE_INVALID',

	// CreateUserAccountFailed
	CREATE_ACCOUNT_FAILED = 'CREATE_ACCOUNT_FAILED',

	// AccountNotFound
	// AccountNotActive
	ACCOUNT_INVALID = 'ACCOUNT_INVALID',

	//UserNotFound
	USER_INVALID = 'USER_INVALID',
	
	//UserNotFound
	USER_SIGNED_UP = 'USER_SIGNED_UP',

	INVITE_EXPIRED = "INVITE_EXPIRED"
}

export type IAuthErr = {
	err: AuthErrState
	errorCode?: string
	errorId?: string
}

const hasExpired = (token: AuthenticationResult) => {
	if (!token.expiresOn) return true;
	if (token.expiresOn < new Date(Date.now())) return true;
	return false;
}

type TAuthProvider ={
  children?: React.ReactNode;
} ;

const authContext = createAsyncContext<IAuthData, IAuthErr>();
export default authContext;

export const AuthProvider: React.FC<TAuthProvider> = (props) => {
	const {children} = props;
	const {
		instance,
		inProgress,
		accounts
	} = useMsal();

	const [waitingOnRedirect, setWaitOnRedirect] = useState(false);
	const [redirectResp, setRedirectResp] = useState<AuthenticationResult | AuthError>();
	const waitForRedirect = async () => {
		if (!waitingOnRedirect) {
			setWaitOnRedirect(true);

			try {
				const resp = await instance.handleRedirectPromise();
				if (resp) setRedirectResp(resp);
			}
			catch (e) {
				setRedirectResp(e as AuthError);
			}

			setWaitOnRedirect(false);
		}
	}

	const accessPromise = useRef<Promise<AuthenticationResult>>();
	const [access, setAccess] = useState<AuthenticationResult>();
	const hasAccess = useMemo(() => !!access && !hasExpired(access), [access]);

	const account: AccountInfo | undefined = useMemo(() => accounts[0], [accounts]);
	const hasAccount = useMemo(() => accounts.length > 0, [accounts]);

	const isAuthenticated = useMemo(() => accounts[0] && hasAccess, [accounts, hasAccess]);


	const login = useCallback(() => {
		Promise.resolve().then(() => {
			instance.loginRedirect(requests.federated_identity)
		})
	}, [requests.federated_identity]);

	const signup = useCallback((tokenHint: string): void => {
		instance.loginRedirect({
			...requests.sign_up,
			redirectUri: process.env.REACT_APP_URL,
			extraQueryParameters: {
				'id_token_hint': tokenHint
			}
		})
	}, [requests.sign_up]);

	const logout = useCallback(() => {
		instance.logoutRedirect({
			account: accounts[0],
			postLogoutRedirectUri: process.env.REACT_APP_URL,
		});
	}, [accounts]);

	const resetPass = useCallback(() => {
		instance.loginRedirect(requests.reset_pass)
	}, [requests.reset_pass]);


	const localsignin = useCallback(() => {
		instance.loginRedirect({
			...requests.sign_in,
			redirectUri: process.env.REACT_APP_URL
		})
	}, [requests.sign_in]);


	const findAccount = useCallback(() => {
		instance.loginRedirect(requests.find_account)
	}, [requests.find_account]);


	const renewAccess = async (): Promise<AuthenticationResult | undefined> => {
		let token: AuthenticationResult | undefined;
		if (accessPromise.current) {
			token = await accessPromise.current;
		} else {
			try {
				accessPromise.current = instance.acquireTokenSilent({
					...requests.current_policy(account),
					account: accounts[0],
					forceRefresh: false
				});

				token = await accessPromise.current;
			}
			catch (e) {
				login();
				console.error('handled', e);
			}
		}

		accessPromise.current = undefined;

		if (!token) return;
		setAccess(token);
		return token;
	};

	const getAccess = async (): Promise<string | undefined> => {
		let token;
		if (!access || hasExpired(access)) {
			token = await renewAccess();
		}
		else {
			token = access;
		}

		if (token) return token.accessToken;
	}


	const { errorCode = '', errorId = ''} = useMemo(() => {
		if (redirectResp instanceof AuthError) {
			const [errorId] = redirectResp.errorMessage.includes(':') ?
				redirectResp.errorMessage.split(':') :
				[`CorrelationId ${redirectResp.correlationId}`];

			return {
				errorId,
				errorCode: redirectResp.errorCode,
			}
		}
		return {};
	}, [redirectResp]);

	const store = useAsyncStateMap(() => {
		const data = {
			login,
			signup,
			logout,
			getAccess,
			localsignin,
			findAccount,

			account,
			hasAccess,
			hasAccount,
			isAuthenticated,
		}


		switch (inProgress) {
			case 'login':
			case 'logout':
			case 'startup':
			case 'acquireToken': {
				return {
					state: AsyncState.LOADING,
					data,
				}
			}

			case 'handleRedirect': {
				waitForRedirect();
				return {
					state: AsyncState.LOADING,
					data,
				}
			}

			case 'none': {

				if (waitingOnRedirect) {
					return {
						state: AsyncState.LOADING,
						data,
					}
				}

				if (hasAccount && !hasAccess) {
					getAccess();
					return {
						state: AsyncState.LOADING,
						data,
					}
				}

				//	policy changed outside of app scope (most likely from AAD side)
				if (errorCode === 'no_cached_authority_error') {
					// login();
					return {
						state: AsyncState.REJECTED,
						data,
						error: {
							err: AuthErrState.POLICY_CHANGED,
							errorCode,
							errorId,
						}
					}
				}

				if (redirectResp instanceof AuthError) {
					switch (errorId) {
						case 'AADB2C90077':	// user session expired on AAD side
						case 'AADB2C90091':	// cancelled forgot pass
						{
							login();
							return {
								state: AsyncState.LOADING,
								data,
							}
						}

						// forgot pass
						case 'AADB2C90118': {
							resetPass();
							return {
								state: AsyncState.LOADING,
								data,
							}
						}
						
						case 'AADB2C90037': 
						case 'AADB2C90304': 
						case 'AADB2C99059': {
							login();
							return {
								state: AsyncState.LOADING,
								data,
							}
						}

						case 'CreateUserAccountFailed':{
							return {
								state: AsyncState.REJECTED,
								data,
								error: {
									err: AuthErrState.CREATE_ACCOUNT_FAILED,
									errorCode,
									errorId,
								}
							}
						}

						case 'AccountNotFound':
						case 'AccountNotActive': {
							return {
								state: AsyncState.REJECTED,
								data,
								error: {
									err: AuthErrState.ACCOUNT_INVALID,
									errorCode,
									errorId,
								}
							}
						}

						case 'UserNotFound': {
							return {
								state: AsyncState.REJECTED,
								data,
								error: {
									err: AuthErrState.USER_INVALID,
									errorCode,
									errorId,
								}
							}
						}

						case 'AADB2C90208': {	// id_token_hint is expired
							return {
								state: AsyncState.REJECTED,
								data,
								error: {
									err: AuthErrState.INVITE_EXPIRED,
									errorCode,
									errorId,
								}
							}
						}

						case 'AADB2C90233':	// id_token_hint is invalid
						case 'InvitationExpired':
						case 'InvitationNotFound':{
							return {
								state: AsyncState.REJECTED,
								data,
								error: {
									err: AuthErrState.INVITE_INVALID,
									errorCode,
									errorId,
								}
							}
						}


						case 'UserAlreadySignup':
						case 'UserAlreadySignupViaOtherOrganisation': {
							return {
								state: AsyncState.REJECTED,
								data,
								error: {
									err: AuthErrState.USER_SIGNED_UP,
									errorCode,
									errorId,
								}
							}
						}

						default: {
							return {
								state: AsyncState.REJECTED,
								data,
								error: {
									err: AuthErrState.UNKNOWN,
									errorCode: redirectResp.errorCode,
									errorId,
								}
							}
						}
					}
				}

				return {
					state: AsyncState.FULFILLED,
					data,
				}
			}
		}

		return {
			state: AsyncState.REJECTED,
			data,
			error: {
				err: AuthErrState.UNKNOWN,
				errorCode: 'Unhandled MSAL state',
				errorId: inProgress
			}
		}
	}, [isAuthenticated, inProgress, access, accounts, getAccess, errorCode, errorId]);

	return (
		<authContext.Provider
			// @ts-ignore
			value={store}
			children={children}
		/>
	)
}
