import { useCallback, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

type PageFilter =  [string, (filter: string) => void];

const usePageFilter = (urlFilterParam: string, initialFilterValue?: string): PageFilter => {
	const history = useHistory();
	const location = useLocation();

	const currentUrl = useMemo(() => location.pathname, [location.pathname]);

	const currentParams = useMemo(() => {
		const urlParams = new URLSearchParams(location.search);
		const entries = urlParams.entries();
		return Object.fromEntries(entries);
	}, [location.search]);

	const currentFilter = useMemo(() => currentParams[urlFilterParam], [currentParams]);
	const activeFilter = useMemo(() => currentFilter ?? initialFilterValue, [currentFilter, initialFilterValue]);

	const setActiveFilter = useCallback((newFilter?: string) => {
		const _params = { ...currentParams };
		if (newFilter) _params[urlFilterParam] = newFilter;

		const urlParams = new URLSearchParams(Object.entries(_params));
		const query = urlParams.toString();

		history.replace(`${currentUrl}?${query}`);
	}, []);

	useEffect(() => {
		if (!currentFilter) setActiveFilter(initialFilterValue);
	}, [currentFilter])

	return [
		activeFilter,
		setActiveFilter,
	]
}

export default usePageFilter;