import React, { useMemo } from 'react';
import clsx from 'clsx';

import { makeStyles, Icon } from '@material-ui/core';

import Typography from '@ui/Typography';

import { ReactComponent as Act } from './icons/Act.svg';
import { ReactComponent as Listen } from './icons/Listen.svg';
import { ReactComponent as Read } from './icons/Read.svg';
import { ReactComponent as Watch } from './icons/Watch.svg';

const useStyles = makeStyles(theme => ({
	modality: {
		display: 'flex',
		alignItems: 'center',

		'& > svg': {
			// inherit text color property,
			// requires removing fill attributes from Svg elements
			fill: 'currentColor',
			fontSize: '1rem',
			marginRight: theme.spacing(.5),
			transform: 'translateY(-1px)',
		}
	},
}))

//	These types are defined by BE.
//	so we will maintain them in FE.
export type ModalityType = (
	| 'Activity'
	| 'Podcast'
	| 'Article'
	| 'Video'
)
type ModalityProps = {
	mode: ModalityType;
	className?: string;
	typographyVariant?: 'primary' | 'secondary';
}

const Modality: React.FC<ModalityProps> = (props) => {
	const { mode, className, typographyVariant='primary' } = props

	const variant = useMemo(() => {
		switch (mode) {
			case 'Activity': return (
				<>
					<Icon fontSize='small' component={Act} />
					Act
				</>
			);
			case 'Podcast': return (
				<>
					<Icon fontSize='small' component={Listen} />
					Listen
				</>
			);
			case 'Article': return (
				<>
					<Icon fontSize='small' component={Read} />
					Read
				</>
			);
			case 'Video': return (
				<>
					<Icon fontSize='small' component={Watch} />
					Watch
				</>
			);
		}
	}, [mode])

	const classes = useStyles();
	return (
		<Typography data-test='Modality' type='body' variant={typographyVariant} className={ clsx(classes.modality, className) }>
			{ variant }
		</Typography>
	)
}

export default Modality