import React from 'react'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '@utils/applicationinsights/applicationinsights';

const AppInsightsProvider = (props) => {
  const {
    children
  } = props;

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      {children}
    </AppInsightsContext.Provider>
  )
}

export default AppInsightsProvider;