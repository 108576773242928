import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Card,
  CardActionArea,
} from '@material-ui/core';

import { toDateString, dateFormats } from '@utils/date-time';

import InlineResult from './InlineResult';
import DialResult from './DialResult/DialResult';

const useStyles = makeStyles(theme => ({
  root: {
    margin: `0 auto`,
    backgroundColor: theme.palette.color.grey[20],
    border: `1px solid ${theme.palette.color.grey[60]}`,
    borderRadius: '20px 20px 0 20px'
  },
  actionArea: {
    height: '100%',
  }
}), {name:'ResultCard'});

const ActionWrapper = ({ action, wrapper, children }) => 
  action ? wrapper(children) : children;

const ResultUI = ({variant, ...otherProps}) => {
  switch (variant) {
    case 'inline':
      return <InlineResult {...otherProps}/>
    case 'dial':
      return <DialResult {...otherProps}/>  
    default:
      console.warn(`ResultCard variant '${variant}' is not valid. Rendering default variant 'inline' instead.`);
      return <InlineResult {...otherProps}/>  
  }
}
const ResultCard = (props) => {
  const {
    variant='inline',
    title,
    grade,
    date,
    onClick
  } = props;

  const classes = useStyles();

  return (
      <Card
        variant='outlined'
        elevation={0}
        className={classes.root}
      >
        <ActionWrapper
          action={ onClick }
          wrapper={ children =>
            <CardActionArea
              className={classes.actionArea}
              onClick={ onClick }
            >
              { children }
            </CardActionArea>
          }
        >
          <ResultUI
            variant={variant}
            title={title}
            grade={grade}
            date={date && toDateString(date, dateFormats.DATE_LONG)}
            hasAction={(typeof onClick === 'function')}
          />
        </ActionWrapper>
      </Card>
  )
}

export default ResultCard;
