import React, { useState } from 'react'
import SwipeableViews from 'react-swipeable-views';

import { makeStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Container from '@material-ui/core/Container';

import Typography from '@ui/Typography'
import Button, {ButtonGroup} from '@ui/Button'

import Mixpanel from '@utils/mixpanel'
import Header from '@components/Header';

import slides from './slideData.json'

const slideData = slides.data

const useStyles = makeStyles((theme) => ({
  
  slide: {
    textAlign: 'center',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    margin: '0 auto',
  },
  slideImage: {
    border: `3px solid ${theme.palette.color.purple[40]}`,
    borderRadius: '50%',
    marginBottom: theme.spacing(6.25),
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: `min(60vw,${theme.spacing(40)}px)`,
    maxHeight: `min(60vw,${theme.spacing(40)}px)`,
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: 'auto',
    }
  },
  title: {
    marginTop: theme.spacing(4),
  },
  stepper: {
    margin: theme.spacing(3.75, 0),
    justifyContent: 'space-around',
    backgroundColor: 'transparent',
    '& .MuiMobileStepper-dot': {
      width: '12px',
      height: '12px',
      margin: theme.spacing(0, 1.75),
      backgroundColor: theme.palette.color.grey[40],
    },
    '& .MuiMobileStepper-dotActive': {
      backgroundColor: theme.palette.color.navy[100],
    },
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
  }
}), {name: 'PromoSlides'})

const Promo = (props) => {
  const {
    handleComplete
  } = props

  const classes = useStyles();

  const [activeSlide, setActiveSlide] = useState(0);


  const SLIDES_LENGTH = slideData.length;
  const START_OF_SLIDES = (activeSlide === 0)
  const END_OF_SLIDES = (activeSlide === SLIDES_LENGTH - 1)

  const mixpanelTrackSlide = (slide, method) => {
    Mixpanel.track('View Promotion', {
      slide: slide,
      method: method,
    })
  }

  const handleNext = () => {
    if (!END_OF_SLIDES) setActiveSlide(activeSlide + 1)
    mixpanelTrackSlide(activeSlide + 1, 'next button')
  }

  const handlePrev = () => {
    if (!START_OF_SLIDES) setActiveSlide(activeSlide - 1)
    mixpanelTrackSlide(activeSlide - 1, 'back button')
  }

  const handleSlideChange = (slide) => {
    setActiveSlide(slide)
    mixpanelTrackSlide(slide, 'swipe')
  }

  const handleSkip = () => {
    Mixpanel.track('Clicked Skip Promotion', {
      slide: activeSlide
    })
    handleComplete()
  }

  const handleDone = () => {
    Mixpanel.track('Clicked Done in Promotion', {
      slide: activeSlide
    })
    handleComplete()
  }

  return (
    <>
      <Header promoHeader disableMargin/>
      <Container maxWidth='md'>
        <div className={classes.body}>
          <SwipeableViews
            axis='x'
            index={activeSlide}
            onChangeIndex={handleSlideChange}
            enableMouseEvents
          >
            {
              slideData.map((slide, index) => {
                return (
                  <div key={slide.title} className={classes.slide}>
                    <figure className={classes.slideImage}>
                      <img alt={slide.title} className={classes[`slideImage${index}`]} src={`${process.env.REACT_APP_URL}/images/${slide.image}`}/>
                    </figure>
                    <Typography className={classes.title} type='heading' variant='primary'>{slide.title}</Typography>
                    <Typography disableMargin className={classes.textColor} type='body' variant='primary'>{slide.body}</Typography>
                  </div>
                )
              })
            }
          </SwipeableViews>
          <MobileStepper
            variant='dots'
            steps={SLIDES_LENGTH}
            position='static'
            activeStep={activeSlide}
            className={classes.stepper}
          />
          <ButtonGroup className={classes.centered}>
            {
              (!START_OF_SLIDES) && 
              <Button
                theme='callout'
                aria-label='previous slide'
                onClick={handlePrev}
              >
                Back
              </Button>
            }
            {
              (END_OF_SLIDES) ? 
              <Button
                aria-label='complete promotion slides'
                onClick={handleDone}
              >
                Done
              </Button>
              :
              <Button
                aria-label='next slide'
                onClick={handleNext}
              >
                Next
              </Button>
            }
          </ButtonGroup>
          <div className={classes.centered}>
            <Button
              aria-label='skip promotion'
              theme='text'
              className={classes.centered}
              onClick={handleSkip}>
                Skip
            </Button>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Promo
