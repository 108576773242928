// TODO: Cleanup. This can live in context of the AppMenu component, it's not used anywhere else.

import React, { useEffect, useContext } from 'react'
import {
	ContactSupportOutlined
} from '@material-ui/icons';

import { NavItem } from '@components/AppMenu';
import userProfileContext from '@context/UserProfileProvider';


//  append the freshdesk script
const script = document.createElement('script');
script.type = 'text/javascript';
script.src = `https://aus-widget.freshworks.com/widgets/${process.env.REACT_APP_FRESHDESK_ID}.js`;
script.async = script.defer = true; // eslint-disable-line no-multi-assign
document.head.appendChild(script);

window.fwSettings = {
  'widget_id': process.env.REACT_APP_FRESHDESK_ID
};

//  this is Freshdesk code, from their embed instructions
//  !function () { if ("function" != typeof window.FreshworksWidget) { var n = function () { n.q.push(arguments) }; n.q = [], window.FreshworksWidget = n } }()

//  this is the clean version
if (typeof window.FreshworksWidget !== 'function') {
	const n = (...args) => { n.q = [ ...(n.q ?? []), args ] };
	window.FreshworksWidget = n;
}

window.FreshworksWidget('hide', 'launcher');
window.FreshworksWidget('hide', 'ticketForm', ['name', 'email']);


const FreshDeskOpen = (callback) => {
  window.FreshworksWidget('open');
  if (typeof callback === 'function') {
	  callback();
  }
};

const FeedbackNav = (props) => {
	const { callback } = props;

	const user = useContext(userProfileContext);

	window.FreshworksWidget('hide', 'launcher');
	useEffect(() => {
		//  this is Freshdesk stuff again
		window.FreshworksWidget('identify', 'ticketForm', {
			name: user.name,
			email: user.email,
		});
	}, [user]);

	return (
		<NavItem
			label='Feedback and Support'
			icon={ <ContactSupportOutlined /> }
			onClick={() => { FreshDeskOpen(callback) }}
		/>
	);
}

export default FeedbackNav
