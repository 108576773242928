import { useRef, useEffect } from 'react';
import useEffectCallback from './useEffectCallback';

const TIMEOUT_DELAY = 50;

const useScrollEvents = (options) => {
  const {
    scrollRef,

    //  return true if we should ignore scroll events
    shouldIgnoreEvents = () => {},

    onStart = () => {},
    onScroll = () => {},
    onStop = () => {},
  } = options ?? {}

  if (!scrollRef) throw new Error('options.scrollRef is required');

  const timer = useRef();

  const triggerStart = useEffectCallback(() => onStart());
  const triggerScroll = useEffectCallback(() => onScroll());
  const triggerStop = useEffectCallback(() => {
    timer.current = undefined;
    onStop();
  });

  const internalHandle = () => {
    if (shouldIgnoreEvents()) return;

    if (!timer.current) triggerStart();
    triggerScroll();
    
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(triggerStop, TIMEOUT_DELAY);
  }

  useEffect(() => {
    scrollRef.current.addEventListener('scroll', internalHandle);
    return () => scrollRef.current?.removeEventListener('scroll', internalHandle);
  }, [ ]);
}

export default useScrollEvents;