import React from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(5),

    '& .MuiButtonBase-root': {
      flexGrow: ({ balance }) => balance ? 1 : '',
      flexShrink: ({ balance }) => balance ? 0 : '',
      flexBasis: ({ balance }) => balance ? 0 : '',
      
      '&:not(:last-child)': {
        marginRight: theme.spacing(1.5),
      }
    }
  }
}))

const ButtonGroup = (props) => {
  const {
    children,
    balance,
    className
  } = props;

  const classes = useStyles({ balance });

  const classlist = clsx([
    classes.buttonGroup,
    className
  ]);

  return (
    <div className={ classlist }>
      {/* { childList } */}
      { children }
    </div>
  )
}

export default ButtonGroup
