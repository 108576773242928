import { Theme, useMediaQuery } from '@material-ui/core'
import React, { useMemo } from 'react'
import { color  } from '../../ui-kit/brandy'

const CoolBean: React.FC = () => {
	const small = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
	const size = useMemo(() => small ? 6 : 8, [small]);

	return (
		<>
			&nbsp;
			<svg viewBox='0 0 8 8' width={size} height={size}>
				<defs>
					<linearGradient id='daybreakGradient' x1='0' y1='0' x2='0' y2='1'>
						<stop stopColor={color.purple[100]} offset='0%' />
						<stop stopColor={color.pink[100]} offset='100%' />
					</linearGradient>
				</defs>
				<ellipse cx='4' cy='4' rx='4' ry='4' fill='url(#daybreakGradient)' />
			</svg>
		</>
	)
}

export default CoolBean