import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container';

import Typography from '@ui/Typography'
import Header from '@components/Header';
import { Button, ButtonGroup } from '@ui/Button'

const useStyles = makeStyles((theme) => ({
  slide: {
    textAlign: 'center',
    margin: `0 auto ${theme.spacing(5)}px`,
    '& > img': {
      marginBottom: theme.spacing(5)
    }
  },

  slideImage: {
    height: '320px',
    '& > img': {
      maxHeight: '300px',
      transform: 'translate(10px, -10px)'
    }
  },

  buttonGroup: {
    width: 'auto',
    display: 'block',
    margin: 'auto',
    textAlign: 'center',
    '& button': {
      minWidth: 150,
      borderColor: theme.palette.color.navy[100],
      color: theme.palette.color.navy[100],
    }
  },

  textColor: {
    color: theme.palette.color.navy[100],
  },
}))

const IntroAssessment = (props) => {
  const {
    handleSkipIntro,
    handleCompleteIntro,
  } = props

  const classes = useStyles();

  return (
    <>
      <Header promoHeader disableMargin/>
      <Container maxWidth='md'>
        <div className={classes.slide}>
          <figure className={classes.slideImage}>
            <img alt='Lets get started' src={`${process.env.REACT_APP_URL}/images/compass.svg`}/>
          </figure>

          <Typography className={classes.textColor} type='heading' variant='primary'>Let's get started</Typography>

          <Typography className={classes.textColor} type='body' variant='primary'>Now that we know what we're working on together, lets figure out where to begin.</Typography>
          <Typography className={classes.textColor} type='body' variant='primary'>Our 5-minute questionnaire will help us give you a tailored health snapshot and a specific area of your mental health to focus on.</Typography>
        </div>
        <ButtonGroup balance className={classes.buttonGroup}>
          <Button theme='callout' onClick={handleSkipIntro}>Later</Button>
          <Button theme='primary' onClick={handleCompleteIntro}>Let's Go</Button>
        </ButtonGroup>
      </Container>
    </>
  )
}

export default IntroAssessment
