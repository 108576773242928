import Typography from '@ui/Typography';
import React, { useMemo } from 'react';
import {
	Grid,
} from '@material-ui/core'

type ListItem = {
	id: string;
}

type GroupProps = {
	title?: string;
	className?: string;
	children?: React.ReactNode;
}

type GroupItemProps = {
	fullWidth: boolean | undefined;
	index: number;
	isFeatured?:boolean;
	children?: React.ReactNode;
}

const Group: React.FC<GroupProps> = ({ title, children, className }) => {

	return (
		<Grid className={className} container alignContent='space-between' spacing={1}>
			{ title &&
				<Grid item xs={12}>
					<Typography type='heading' variant='section'>{ title }</Typography>
				</Grid>
			}
			{ children }
		</Grid>

	)
};


const GroupItem:React.FC<GroupItemProps> = ({ fullWidth, index, children, isFeatured }) => {
	const isLargeCard = (index % 8 === 0 || index % 8 === 1);
	
	return (
		isFeatured ? (
			<Grid item
			xs={12}
			md={fullWidth ? 12 : 6}
		>
			{ children }
		</Grid>
		) : (

		<Grid item
			xs={fullWidth ? 12 : ((isLargeCard) ? 12 : 6)}
			md={fullWidth ? 12 : ((isLargeCard) ? 6 : 4)}
		>
			{ children }
		</Grid>
		)
	)
};

type GroupListProps = {
	items: ListItem[];
	groups: string[];
	selectedGroup: string | false | undefined;
	simple?: boolean | undefined;
	fullWidth?: boolean | undefined;
	gridLayout?: boolean | undefined;

	isItemInGroup: (group: string, item: any) => boolean;
	injectFirstItem?: () => React.ReactElement | false;

	renderItem: (item: any, key: string, index: number) => React.ReactElement;
	renderEmptyGroup: () => React.ReactElement;
	renderEmptyList: () => React.ReactElement;
	className?: string;
}

const GroupList: React.FC<GroupListProps> = props => {
	const {
		items,
		groups,
		selectedGroup,
		simple,
		fullWidth = false,
		gridLayout= false,

		isItemInGroup,
		injectFirstItem,

		renderItem,
		renderEmptyGroup,
		renderEmptyList,
		className,
	} = props;

	const groupedItems = useMemo(() => (
		groups.map(group => ({
			title: group,
			items: items.filter(item => (
				isItemInGroup(group, item)
			))
		}))
	), [items]);
	
	const generateContent = (group: { items: string | any[]; }) => group.items.length === 0 ? renderEmptyGroup() : injectFirstItem?.();

	const filteredGroups = useMemo(() => {
		if (simple && !selectedGroup) return [{title:'', items}];
		if (!selectedGroup) return groupedItems;
		return groupedItems.filter(g => g.title === selectedGroup).map(({ title, items }) => ({ items, title: '' }));
	}, [groupedItems, selectedGroup]);

	if (items.length === 0) return renderEmptyList();

	if (simple) return (
		<Group className={className} title='' key='featured'>
			{
				filteredGroups[0].items.length === 0 ?
					renderEmptyGroup() :
					injectFirstItem?.()
			}
			{
				filteredGroups[0].items.map((item, index) => (
					<GroupItem isFeatured={true} fullWidth={fullWidth} index={index}>{renderItem(item, `${item.id}`, index)}</GroupItem>
				))
			}
		</Group>
	)

	//	TODO: implement lazy loading items in this list, like in the admin
	return (
		<>
			{
				filteredGroups
					.map(group => (
						<Group className={className} title={group.title} key={group.title}>
							{
								gridLayout ? (
									<Grid container alignItems="stretch" spacing={2}>
									{
										generateContent(group)
									}
									{
										group.items.map(
											(item, index) => (renderItem(item, `${item.id}`, index))
										)

									}

									</Grid>
								) : 
								(
									generateContent(group)
								)
							}
							{!gridLayout &&
								group.items.map(
									(item, index) => (<GroupItem fullWidth={fullWidth} index={index}>{renderItem(item, `${item.id}`, index)}</GroupItem>)
								)
							}	
							
						</Group>
					))
			}
		</>
	)
}

export default GroupList