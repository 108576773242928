import { LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { AccountInfo } from '@azure/msal-browser';

const {
  REACT_APP_TENANT: tenant,
  REACT_APP_CUSTOM: customDomain,
  REACT_APP_APPLICATION_ID: clientId,
} = process.env;

const authorityUrl = (policy: string) => `https://${customDomain}/tfp/${tenant}.onmicrosoft.com/${policy}`;
const scopeUrl = (scope: string) => `https://${tenant}.onmicrosoft.com/${clientId}/${scope}`;

const scopes = [
	scopeUrl(process.env.REACT_APP_ACCESS_SCOPE),
]

export const requests = {
  sign_in: { 
    scopes,
    authority: authorityUrl(process.env.REACT_APP_SIGNIN_POLICY) },

  sign_up: {
    scopes,
    authority: authorityUrl('B2C_1A_signup_invitation')
  },
  
  reset_pass: {
    scopes,
    authority: authorityUrl(process.env.REACT_APP_RESETPASSWORD_POLICY)
  },

  federated_identity: {
    scopes,
    authority: authorityUrl(process.env.REACT_APP_FEDERATED_IDENTITY_POLICY)
  },

  find_account: {
    scopes,
    authority: authorityUrl(process.env.REACT_APP_FINDACCOUNT_POLICY)
  },

  /**
   * tries to renew access token against the authority that issued the id token.
   * @param account the currently logged in account.
   * @returns the request object for aad.
   */
  current_policy: (account: AccountInfo | undefined) => {
    if (!(account?.idTokenClaims as any)?.acr) return requests.federated_identity;

    const authority = authorityUrl((account?.idTokenClaims as any)?.acr);
    return { scopes, authority }
  }
}

const pca = new PublicClientApplication({
	auth: {
		clientId: process.env.REACT_APP_APPLICATION_ID,
		authority: authorityUrl(process.env.REACT_APP_FEDERATED_IDENTITY_POLICY),
		redirectUri: process.env.REACT_APP_URL,
		knownAuthorities: [`${tenant}.onmicrosoft.com`, `${customDomain}`],
	},
	cache: {
		cacheLocation: 'sessionStorage',
		storeAuthStateInCookie: false,
		secureCookies: false,
	},
	system: {
		loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        //  prevent logging for non-localhost envs
        if (process.env.REACT_APP_BuildNumber !== 'LOCALHOST') return;
        
        if (containsPii) {
            return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(`** MSAL **\n${message}`);
            return;

          case LogLevel.Warning:
            console.warn(`** MSAL **\n${message}`);
            return;

          // case LogLevel.Verbose:
          //   console.debug(`** MSAL **\n${message}`);  // eslint-disable-line
          //   return;

          // case LogLevel.Info:
          //   console.info(`** MSAL **\n${message}`);   // eslint-disable-line
          //   return;

          default: return;
        }
      },
    },
	},
});



export default pca;