// https://material-ui.com/components/cards/#card
// TODO: Update this component to follow similar structure to other ui components
import React from 'react';

import MuiCard from '@material-ui/core/Card';

import { makeStyles } from '@material-ui/core';
import Typography from '../Typography';

const useStyles = makeStyles(theme => ({
  label: {
    position: 'absolute',
    transform: 'translate(0%, -120%)',
    zIndex: 10,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0, 0.5),
  },

  noSurface: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: 'none',
  }
}), { name: 'Card' });

const Card = (props) => {
  const classes = useStyles();
  const {
    label = "",
    children,
    variant,
    ...otherProps
  } = props;

  return (
    <MuiCard
      classes={{
        root: classes[variant]
      }}
      {...otherProps}>
      {(label) && <Typography disableMargin type='label' variant='primary' component='span' className={classes.label}>{label}</Typography>}
      {children}
    </MuiCard>
  );
};

export default Card;