// This file contains theme settings that don't
// map 1:1 with material ui's theming object.
// These should be removed as we decide how to deprecate
// the values they replace.

const pxToRem = (pxValue:number) => `${pxValue/16}rem`;

type ColorConfig = {
  20?: string;
  40?: string;
  60: string;
  80: string;
  100: string;
}

type TypographyOptions = {
  fontWeight: number;
  fontSize: string;
  lineHeight: string;
  letterSpacing: number | string;
  fontFamily?: string;
}

export type GenericType = {
  [name: string]: string;
}

export type ColorOptions = {
  [name: string]: ColorConfig | string;
  dawn: string;
  white: string;
  black: string;
  transparent: string;
}

type GridConfig = {
  rootDesktop: string;
  rootMobile: string;
  containerDesktop: string;
  containerMobile: string;
  fullBleed: string;
  content: string;
}

export type ThriveTypographyOptions = {
  [name: string]: TypographyOptions;
}

export type GridOptions = {
  page: GridConfig
}

const color: ColorOptions = {
  purple: {
    20: '#F0E2FF',
    40: '#E2C6FF',
    60: '#D3A9FF',
    80: '#C58DFF',
    100: '#B670FF',
  },

  pink: {
    20: '#FFF3FF',
    40: '#FFE8FF',
    60: '#FFDCFF',
    80: '#FFD1FF',
    100: '#FFC5FF',
  },

  mint: {
    20: '#F2FBFC',
    40: '#E6F6FA',
    60: '#D9F2F7',
    80: '#CDEDF5',
    100: '#C0E9F2',
  },

  daybreak: {
    20: '#FFFBFA',
    40: '#FFF7F4',
    60: '#FFF3EF',
    80: '#FFEFE9',
    100: '#FFEBE4',
  },

  grey: {
    20: '#F9F9F9',
    40: '#F3F3F3',
    60: '#EEEEEE',
    80: '#E8E8E8',
    100: '#E2E2E2',
  },

  navy: {
    60: '#6866A4',
    80: '#363386',
    100: '#040068',
  },
  
  dawn: 'linear-gradient(180deg, #B670FF 0%, #FFC5FF 100%);',
  white: '#FFFFFF',
  black: '#000000',
  transparent: 'rgba(0,0,0,.65)'
}


const grid: GridOptions = {
  page: {
    rootDesktop: `[menuEnd] 180px [bleedStart] 20px [menuEnd] minmax(2rem, 1fr) [mainStart] min(880px, calc(100% - 4rem - 200px)) [mainEnd] minmax(2rem, 1fr) [bleedEnd]`,
    rootMobile: `[menuEnd] 0 [bleedStart] 0 [menuEnd] minmax(2rem, 1fr) [mainStart] min(880px, calc(100% - 4rem)) [mainEnd] minmax(2rem, 1fr) [bleedEnd]`,

    containerDesktop: `[bleedStart] 20px [menuEnd] minmax(2rem, 1fr) [mainStart] min(880px, calc(100% - 4rem - 200px)) [mainEnd] minmax(2rem, 1fr) [bleedEnd]`,
    containerMobile: `[bleedStart] 0 [menuEnd] minmax(2rem, 1fr) [mainStart] min(880px, calc(100% - 4rem)) [mainEnd] minmax(2rem, 1fr) [bleedEnd]`,

    fullBleed: 'bleedStart / bleedEnd',
    content: 'mainStart / mainEnd',
  },
}

const font = 'PangramSans, Helvetica, Arial, sans-serif';

const thriveTypography: ThriveTypographyOptions = {
  headingH1:{
    fontWeight: 400,
    fontFamily: 'PangramSansRounded, Helvetica, Arial, sans-serif',
    fontSize: pxToRem(38),
    lineHeight: pxToRem(63),
    letterSpacing: pxToRem(-.8),
  },
  headingH2:{
    fontWeight: 400,
    fontFamily: 'PangramSansRounded, Helvetica, Arial, sans-serif',
    fontSize: pxToRem(30),
    lineHeight: pxToRem(50),
    letterSpacing: pxToRem(-.3),
  },
  headingH3:{
    fontWeight: 400,
    fontFamily: 'PangramSansRounded, Helvetica, Arial, sans-serif',
    fontSize: pxToRem(20),
    lineHeight: pxToRem(30),
    letterSpacing: pxToRem(-.15),
  },
  headingH4:{
    fontWeight: 400,
    fontFamily: 'PangramSansRounded, Helvetica, Arial, sans-serif',
    fontSize: pxToRem(16),
    lineHeight: pxToRem(28),
    letterSpacing: pxToRem(-.05),
  },
  paragraphXl:{
    fontWeight: 400,
    fontSize: pxToRem(20),
    lineHeight: pxToRem(38),
    letterSpacing: 0,
  },
  paragraphLg:{
    fontWeight: 400,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(27),
    letterSpacing: 0,
  },
  paragraphMd:{
    fontWeight: 400,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(24),
    letterSpacing: 0,
  },
  labelLg:{
    fontWeight: 400,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(16),
    letterSpacing: pxToRem(.2),
  },
  labelMd:{
    fontWeight: 400,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(16),
    letterSpacing: pxToRem(.2),
  },
  labelSm:{
    fontWeight: 400,
    fontSize: pxToRem(11),
    lineHeight: pxToRem(16),
    letterSpacing: pxToRem(.2),
  },
}


export { color, grid, font, thriveTypography };