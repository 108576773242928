import React, { useMemo } from 'react'
import { BrowserRouter } from 'react-router-dom';

import Theme from './Theme';
import AppInsightsProvider from './AppInsightsProvider';

import { ModalProvider } from './ModalProvider';
import { AssessmentModalProvider } from './AssessmentModalProvider';
import { UserProfileProvider } from './UserProfileProvider';
import { InstallableProvider } from './InstallableProvider';
import { HealthDomainProvider } from './HealthDomainProvider';
import { GoalProvider } from './GoalProvider';
import { LearnSearchProvider } from './LearnSearchProvider';
import { StorageProvider } from './StorageProvider';
import { RecommendationProvider } from './RecommendationProvider';
import { QuestionnaireListProvider } from './QuestionnaireListProvider';

// Add new providers here. Be careful of sqeuence.
const providerOrder = [
  StorageProvider,
  AppInsightsProvider,
  Theme,
  ModalProvider,
  AssessmentModalProvider,
  BrowserRouter,
  UserProfileProvider,
  GoalProvider,
  LearnSearchProvider,
  InstallableProvider,
	RecommendationProvider,
  HealthDomainProvider,
	QuestionnaireListProvider,
];

const CoreProvider = (props) => {
  const {
    authProvider: AuthProvider,
    children
  } = props;

  // Each provider wraps the next in the `orderProvider` list.
  // !! do not add new providers here. !!
  const content = useMemo(() => (
    [ AuthProvider, ...providerOrder ].reverse().reduce((child, Curr) => (
      <Curr children={ child } />
    ), children)
  ), [ AuthProvider, children ]);

  return content;
}

export default CoreProvider
