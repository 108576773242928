import React, { useEffect, useContext, useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom';

import {
  makeStyles,
  useMediaQuery,
  Drawer,
  MenuList,
} from '@material-ui/core'

import { ExitToAppOutlined } from '@material-ui/icons';

import authContext from '@context/AuthProvider';
import InnoWellLogo from '@components/InnoWellLogo';

import NavItem from './NavItem';

const useStyles = makeStyles((theme) => {
  const drawerWidth = theme.spacing(25);
  const drawerBorderRadius = theme.spacing(0,2.5,2.5,0);
  const drawerBar = {
    minHeight: theme.spacing(7),
    width: '100%',
  }

  return (
    {
      root: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: drawerWidth,
        flexShrink: 0,
        flexBasis: drawerWidth,
      },
      drawerPaper: {
        width: drawerWidth,
        borderRadius: drawerBorderRadius,
      },
      drawerContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        overflow: 'auto',
      },
      drawerHeader: {
        ...drawerBar,
        alignSelf: 'flex-start',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: theme.spacing(5, 2.5),
        '& svg': {
          maxWidth: theme.spacing(15),
        }
      },
      drawerFooter: {
        ...drawerBar,
        alignSelf: 'flex-end',
      },
      drawerBody: {
        flexBasis: theme.spacing(12),
        flexGrow: 14,
        flexShrink: 0,
      },
      mainNav: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
      },
      listItemIcon: {
        minWidth: theme.spacing(4.5),
      },
    }
  )
});

const AppMenu = (props) => {
  const {
    context = {},
    routes = [],
    footerNavItems = []
  } = props;

  const history = useHistory();

  const authStore = useContext(authContext);
  const {
    logout,
    isAuthenticated,
  } = authStore.data;


  const isPermanentDrawer = useMediaQuery(theme => theme.breakpoints.up('md'));
  const { isShowing = false } = context;

  const isOpen = useMemo(() => {
    if (!isAuthenticated) return false;
    if (isPermanentDrawer) return true;
    return isShowing;
  }, [isPermanentDrawer, isAuthenticated, isShowing]);

  const drawVariant = useMemo(() => {
    if (!isAuthenticated) return 'temporary';
    return isPermanentDrawer ? 'permanent' : 'temporary';
  }, [isPermanentDrawer, isAuthenticated])

  useEffect(
    () => {
      if (isPermanentDrawer) return;
      return history.listen(context.close);
    },
    [context.close, history, isPermanentDrawer]
  )

  const onLogout = () => {
    logout();
    if (context.close) context.close();
  }

  const classes = useStyles();
  return (
    <Drawer
      anchor='left'
      variant={ drawVariant }
      open={ isOpen }
      onClose={ () => context.close() }
      className={ classes.root }
      classes={{ paper: classes.drawerPaper }}
    >
      <div className={ classes.drawerContainer }>
        <div className={ classes.drawerHeader }>
          <Link to={'/'}><InnoWellLogo /></Link>
        </div>

        <div className={ classes.drawerBody}>
          <MenuList className={ classes.mainNav }>
            {
              routes
                .filter(route => !!route.label)
                  .map(route => {
                    const { label } = route;
                    return (
                      <NavItem
                        key={ label }
                        { ...route }
                      />
                    )
                  })
            }
          </MenuList>
        </div>

        <div className={ classes.drawerFooter }>
          <MenuList>
            {
              footerNavItems.map((FooterNavItem, index) => (
                <FooterNavItem
                  key={ `footnav-${index}` }
                  closeNav={ () => isPermanentDrawer ? false : context.close }
                  callback={ () => context.close() }
                />
              ))
            }
            <NavItem
              label='Sign Out'
              onClick={ onLogout }
              icon={ <ExitToAppOutlined/> }
            />
          </MenuList>
        </div>
      </div>
    </Drawer>
  )
}

export default AppMenu
