import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import SplashLayout from './SplashLayout';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      paddingTop: theme.spacing(1),
    },

    position: {
      position: 'absolute',
      top: `45%`,
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }
}));

const Splash = (props) => {
  const {
    children,
    disablePosition = false,
    ...otherProps
  } = props;

  const classes = useStyles();

  return (
    <section className={ clsx(classes.root, (!disablePosition) && classes.position)}>
      <SplashLayout { ...otherProps } >
        { children }
      </SplashLayout>
    </section>
  )
}

export default Splash;
