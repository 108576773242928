import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react'
import useToggle from '@hooks/useToggle'

const WithConfirmation = Component => {
	const Confirm = (props, ref) => {
		const { isShowing, open, close } = useToggle();
		const [componentProps, setComponentProps] = useState({});

		const handleAccept = useRef();
		const handleCancel = useRef();

		const getConfirmation = () => (
			new Promise((resolve, reject) => {
				handleAccept.current = resolve;
				handleCancel.current = reject;
				open();
			})
				.then(() => true)
				.catch(() => false)
				.finally(close)
		)

		const addProps = ({ ...newProps }) => setComponentProps({
			...componentProps,
			...newProps
		})

		const removeProps = () => setComponentProps({})

		useImperativeHandle(ref, () => ({
			getConfirmation,
			addProps,
			removeProps,
		}))

		return (
			<Component
				isOpen={ isShowing }
				onAccept={ (e) => handleAccept.current(e) }
				onCancel={ (e) => handleCancel.current(e) }

				{ ...componentProps }
			/>
		)
	}

	return forwardRef(Confirm)
}

export default WithConfirmation
