import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'

import Button from '@ui/Button'
import Typography from '@ui/Typography'

const ExitModal = (props) => {
  const { isOpen = false, onCancel, onAccept } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" disableTypography>
        <Typography disableMargin type='heading' variant='tertiary'>
          Discard questionnaire?
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Typography disableMargin>
          Discarding will delete any saved progress.
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button theme='link' onClick={ onCancel }>
          CANCEL
        </Button>
        <Button theme='link' onClick={ onAccept }>
          DISCARD
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ExitModal
