import React, { useContext, useEffect, useRef } from 'react'

import GoalNotification from '@components/GoalNotification';
import userProfileContext from '@context/UserProfileProvider';

const HomeNotifications = (props) => {
	const { delay = 2000 } = props;

	const userProfile = useContext(userProfileContext);

	const goalRef = useRef();
	const installRef = useRef();

	const goalReady = useRef(false);
	const installReady = useRef(false);

	const onGoalReady = () => goalReady.current = true;


	const openNotification = () => {
		if (goalReady.current === true)
			return goalRef.current.open();

		if (installReady.current === true)
			return installRef.current.open();
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			if (userProfile.hasOnboarded) openNotification()
		}, delay);
		return () => clearTimeout(timer);
	}, [userProfile.hasLoaded]);

	return (
		<>
			<GoalNotification
				ref={goalRef}
				onReady={ onGoalReady }
			/>
		</>
	)
}

export default HomeNotifications
