import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import AppShell from '@components/AppShell';
import routes from './routes';

import FeedbackItem from '@components/FeedbackNavItem';
import AboutNavItem from '@components/AboutNavItem';

import { MsalProvider } from '@azure/msal-react';
import { pca, AuthProvider } from './context/AuthProvider';

const navFooterItems = [
  AboutNavItem,
  FeedbackItem
]

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <AppShell
        authProvider={ AuthProvider }
        routes={ routes }
        footerNavItems={ navFooterItems }
      />
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();