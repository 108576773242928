import React from 'react'
import { makeStyles } from '@material-ui/core'
import Typography from '@ui/Typography';
import { Button } from '@ui/Button';
import Splash from '@components/Splash';

const useStyles = makeStyles(theme => ({
  root: {
  },
  topPad: {
    marginTop: theme.spacing(2.5)
  }
}))

export type ErrorSplashProps = {
  children?: never
  errorCode?: string
  errorId?: string

  heading?: string | React.ReactElement
  subheading?: string | null
  action?: React.ReactElement<typeof Button> | null
}

const ErrorSplash: React.FC<ErrorSplashProps> = props => {
  const {
    heading = 'Well this is embarrassing...',
    subheading = 'We\'re not sure what caused this error, but we are looking into it!',
    action = <Button href='/'>Go back to Home page</Button>,
    errorCode,
    errorId,
  } = props;

  const classes = useStyles();

  if (typeof errorCode !== 'undefined' || typeof errorId !== 'undefined')
    console.warn(`Unhandled ErrorState. ErrorCode: '${errorCode}'; ErrorId: '${errorId}'`)

  return (
    <Splash>
      <Typography type='heading' variant='secondary'>
        { heading }
      </Typography>

      <Typography type='body' variant='primary'>
        { subheading }
      </Typography>

      {
        action && (
            <div className={ classes.topPad }>
              { action }
            </div>
        )
      }

      <div className={ classes.topPad }>

        { (errorCode || errorId) && <code>ErrorCode: '{errorCode}'; ErrorID: '{errorId}'</code> }
      </div>
    </Splash>
  )
}

export default ErrorSplash