import { makeStyles } from '@material-ui/core';
import Typography from '@ui/Typography';
import Modality from '@ui/Modality'
import {ISearchResource} from '../../Learn'
import BookmarkIcon from '@components/BookmarkIcon'

import useBookmark from '@hooks/useBookmark';

import SnackToast from '@components/SnackToast';

interface TSearchResultCard {
    handleOpenResource?: () => void;
    searchItem: ISearchResource
}

const useStyles = makeStyles(theme => ({
	cardContainer: {
        padding:theme.spacing(2,0),
        display: 'flex',
        borderBottom: `1px solid ${theme.palette.color.grey[100]}`,
        cursor: 'pointer',
	},
    infoContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    title:{
        flex:2, 
        marginTop: theme.spacing(1)
    },
    sub:{
        flex:1,
    },
    imageContainer:{
        minWidth: theme.spacing(11.5),
        maxWidth: theme.spacing(11.5),
        height: theme.spacing(17.25),
        marginRight: theme.spacing(2.5),
        [theme.breakpoints.up('md')]: {
            minWidth: theme.spacing(33),
            height: theme.spacing(21),
            marginRight: theme.spacing(4.25),
        },
    },
    image:{
        minWidth: '100%',
        minHeight: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit:'cover',
    },
    modalityContainer:{
        margin: theme.spacing(1,0,0,0),
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    }
}),{name: 'SearchResultCard'})


const SearchResultCard = (props:TSearchResultCard) => {
    
    const { 
        searchItem,
        handleOpenResource
    } = props;
    
    const {id,image,title,source,subtitle,modality,isBookmark,bookmarkId} = searchItem;  
    const {individualLearnContent,handleBookmarkClick,handleUnBookmarkClick,snack} = useBookmark({isBookmark:isBookmark,modality:modality, id:id,bookmarkId:bookmarkId});

    
    const handleIconClick = (e: React.MouseEvent) =>{
        e.stopPropagation();
        individualLearnContent.isBookmark ? handleUnBookmarkClick() : handleBookmarkClick();
    }
    const classes = useStyles();

	return (
        <>	
            <section data-test='SearchResultCard' onClick={handleOpenResource} className={classes.cardContainer}>
                <div className={classes.imageContainer}>
                    <img data-test='WellbookCard-image' className={classes.image} src={`${process.env.REACT_APP_CONTENT_URL}${image.largeImageUrl}`} alt='' />
                </div>
                <div className={classes.infoContainer}>
                    <Typography data-test='WellbookCard-title' className={classes.title} disableMargin type='body' variant='primary'>
                        {title}
                    </Typography>
                    <Typography data-test='WellbookCard-subTitle' className={classes.sub} disableMargin type='body' variant='secondary'>
                        {modality === 'Article' ? source : subtitle}
                    </Typography>
                    <div className={classes.modalityContainer}>
                        <Modality data-test='WellbookCard-modality' typographyVariant='secondary' mode={modality} />
                        <BookmarkIcon dataTest='BookmarkIcon' onClick={handleIconClick} isBookmark={individualLearnContent.isBookmark}/>
                    </div>
                </div>
            </section>
            <SnackToast
            message={  
                <Typography disableMargin type='body' variant='secondary'>
                Saved to Wellbook
                </Typography>
            }
            isOpen={ snack.isSnackOpen }
            onClose={ snack.closeSnack }
            />
        </>
						
	)
}

export default SearchResultCard