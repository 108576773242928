import React from 'react';
import { makeStyles, Paper } from '@material-ui/core';

import SplashLayout from '../SplashLayout';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(4, 0)
  }
}));

const HeroCard = (props) => {
  const {
    title,
    image,
    imageAlt,
    children,
    ...otherProps
  } = props;

  const classes = useStyles();
  
  const asset = typeof image === 'string' ?
    <img
      alt={ imageAlt }
      src={`${ process.env.REACT_APP_URL }${ image }`} 
    /> :
    image;

  return (
    <Paper className={ classes.root } variant='outlined' elevation={1}>
      <SplashLayout disablePosition
        title={ title }
        classes={{ root: classes.splashLayout }}
        image={ asset }
        { ...otherProps }>
          { children }
      </SplashLayout> 
    </Paper>
  )
}

export default HeroCard
