import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core';

import Typography from '@ui/Typography';
import Button from '@ui/Button';

import Dial from '@components/ResultCard/DialResult/Dial'
import DialResult from '@components/ResultCard/DialResult'

import Suggestions from '../Suggestions'
import FeatureCard from '../FeatureCard';

import type { IRecommendationDataMark } from '../Types';

const useStyles = makeStyles(theme => ({
	base: {
		display: 'flex',
		flexDirection: 'column',
	},

	body: {
		textAlign: 'center',
		order: 2,
	},

	dial: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		marginBottom: theme.spacing(1.5)
	},

	[theme.breakpoints.up('sm')]: {
		base: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		body: {
			order: 'unset',
			textAlign: 'unset',
		},
	},

	heading: {
		marginBottom: theme.spacing(1.875),
	},

	healthDomain: {
		marginBottom: theme.spacing(2.5),
	},

	divider: {
		margin: theme.spacing(2.5, 0),
	},
}), { name: 'FocusedRecommendations' })

type FocusedRecommendationsProps = {
	data: IRecommendationDataMark
	insideAssessment?: true
	onViewAnswers?: (instrumentId: string, answerSetId: string) => void
	onNavigate?: () => void
}

const FocusedRecommendations: React.FC<FocusedRecommendationsProps> = (props) => {
	const {
		data,

		insideAssessment,
		onViewAnswers,
		onNavigate,
	} = props

	const mark = useMemo(() => data.assessmentMark ?? data.recommendingMark, [data.assessmentMark, data.recommendingMark]);
	const { healthDomain, grade } = mark;

	const classes = useStyles();

	const handleViewAnswers = () => {
		if (typeof onViewAnswers !== 'function') return;

		const {
			instrumentId = '',
			assessmentAnswerId = ''
		} = data.recommendingMark ?? {}

		onViewAnswers(instrumentId, assessmentAnswerId)
	}

	return useMemo(() => {
		if (data.affirmationWord) return (
			<>
				<FeatureCard>
					<div className={classes.base}>
						<div className={classes.body}>
							<Typography data-test='FocusedRecommendations-Affirmation' className={classes.healthDomain} type='heading' variant='primary'>
								{data.affirmationWord}
							</Typography>

							<Typography data-test='FocusedRecommendations-Heading' className={classes.heading} type='label' variant='primary' component='p'>
								Your <span data-test='Heading-HealthDomain'>{healthDomain}</span> results are good!
							</Typography>
							<Typography data-test='FocusedRecommendations-Subheading' className={classes.heading} type='label' variant='primary' component='p'>
								Don’t forget to check-in again in 14 days
							</Typography>
						</div>

						<DialResult grade={grade} />
					</div>
				</FeatureCard>

				<Suggestions
					allGood
					onNavigate={ onNavigate }
					focusDomain={data.focusedHealthDomain}
					recommendations={data.recommendations}
				/>
			</>
		)

		return (
			<FeatureCard>
				<div className={classes.base}>
					<div className={classes.body}>
						<Typography data-test='FocusedRecommendations-Heading' className={classes.heading} type='label' variant='primary' component='p'>
							Your wellbeing focus is
						</Typography>

						<Typography data-test='Heading-FocusedHealthDomain' className={classes.healthDomain} type='heading' variant='primary' component='p'>
							{data.focusedHealthDomain}
						</Typography>

						{
							!insideAssessment && (
								<Button theme='link' onClick={handleViewAnswers}>
									<Typography disableMargin type='label'
									variant='primary' component='span'>
										View your latest answers &gt;
									</Typography>
								</Button>
							)
						}
					</div>
					<div className={classes.dial}>
						<Dial
							grade={grade.value}
							label={grade.label}
						/>
					</div>
				</div>

				<hr className={classes.divider} />

				<Suggestions
					borderWidth={4.5}
					contained
					onNavigate={ onNavigate }
					focusDomain={data.focusedHealthDomain}
					recommendations={data.recommendations}
				/>
			</FeatureCard>
		)
	}, [data.affirmationWord, classes])
}

export default FocusedRecommendations
