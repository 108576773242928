import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';

import Typography from '@ui/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    maxWidth: 'auto',
    minWidth: 'auto',
    margin: theme.spacing(2.5, 'auto'),
    width: '100%',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.spacing(52.5),
      minWidth: theme.spacing(52.5),
    },
  },
  media: {
    backgroundColor: 'transparent',
    width: theme.spacing(10),
    height: theme.spacing(10),
    margin: theme.spacing(0, 'auto', 2.5),
    overflow: 'visible',
    '& img': {
      width: '100%',
      height: 'auto',
    }
  },
  title: {
    marginBottom: theme.spacing(1.5),
  },
}));

const SplashLayout = (props) => {
  const {
    image,
    title,
    children,
    action,
    disablePosition = false
  } = props;
  
  const classes = useStyles({ disablePosition });

  return (
    <>
      <section className={ classes.root }>
        {
          (image) && (
            <Avatar className={ classes.media }>
              { image }
            </Avatar>
          )
        }
        <Typography type='heading' variant='secondary' className={ classes.title } >
            { title }
        </Typography>
        <div className={ classes.content }>
          { children }
          { action }
        </div>
      </section>
    </>
  )
}

export default SplashLayout
