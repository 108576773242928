import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import Typography from '@ui/Typography';
import Mixpanel from '@utils/mixpanel';

import SearchResultCard from './SearchResultCard';
import {ISearchResource} from '../Learn'
import { ReactComponent as CrossIcon } from '../cross.svg';



interface TSearchResults  {
	handleReset: () => void;
    searchResults: Array<ISearchResource> | null;
}

const useStyles = makeStyles(theme => ({
	searchResultsContainer: {
        marginTop: theme.spacing(2),
		display: 'flex',
        flexDirection: 'column',
        width: '100%',
	},
    crossIcon: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        cursor: 'pointer'
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(4)
    },
    link:{
        textDecoration: 'underline',
        cursor: 'pointer',
    }
}),{name: 'SearchResults'})

const SearchResults = (props:TSearchResults) => {

    const { 
        handleReset,
        searchResults
      } = props;

    const history = useHistory();

    const classes = useStyles();
   
    
    const handleOpenResource = (resource: ISearchResource) => () => {
		Mixpanel.track('Access Learn Content', {
			entryPoint: 'Learn',
			modality: resource.modality,
			name: resource.title,
			healthDomains: resource.healthDomains,
			id: resource.id,
		});
		history.push(`/learn/${resource.modality}/${resource.id}`, { isBackToHistory:true });
	}

	return (	
        <section className={classes.searchResultsContainer}>
            <div className={classes.headerContainer}>
                <Typography type='heading' variant='secondary' disableMargin>{`Displaying ${searchResults?.length} ${searchResults && searchResults?.length > 1 ? 'results' : 'result'} `}</Typography>
                <CrossIcon  onClick={handleReset} className={classes.crossIcon} />
            </div>
            {
                searchResults && searchResults?.map((item) => <SearchResultCard searchItem={item} handleOpenResource={handleOpenResource(item)} />)
            }
            
        </section>
						
	)
}

export default SearchResults