import clsx from 'clsx';
import { makeStyles } from '@material-ui/core'
import { ReactComponent as Bookmark } from './bookmark.svg';
import { ReactComponent as Unbookmark } from './unbookmark.svg';


interface TBookmarkIcon {
    isBookmark: boolean | undefined;
    onClick?: (e: React.MouseEvent) => void ;
    className?: string;
    dataTest?: string;
}

const useStyles = makeStyles(theme => ({
    icon:{
        cursor:'pointer'
    }
}))

const BookmarkIcon = (props:TBookmarkIcon) => {

  const { 
    isBookmark,
    onClick,
    className,
    dataTest
  } = props;

  const classes = useStyles();

  return (
    isBookmark ? (
        <Bookmark data-test={dataTest} onClick={onClick} className={clsx(classes.icon, className)} />
        ) : (
        <Unbookmark data-test={dataTest} onClick={onClick} className={clsx(classes.icon, className)} />
    )
  );
};

export default BookmarkIcon;
