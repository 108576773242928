import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'

import useToggle from '@hooks/useToggle';
import authContext from './AuthProvider'
import { useAPI } from '@hooks/useRequest';

const goalContext = createContext()
export default goalContext

export const GoalProvider = (props) => {
	const { children } = props;

	const axios = useAPI();
	const authStore = useContext(authContext);
	const { isAuthenticated } = authStore.data;
	const [goal, setGoal] = useState();

	const {
		isShowing: isOpen,
		open, close,
	} = useToggle();

	const getGoalNotification = async () => {
		if (!isAuthenticated) return;
		
		const data = await axios.get('/Notification/Goal')

		setGoal(data.notification);
		if (data.displayNotification) {
			open();
		}
	}

	const onDismiss = async () => {
		close();
		await axios.post('/Notification/Goal');
	}

	useEffect(getGoalNotification, [ isAuthenticated ])

	const ctx = useMemo(() => ({
		isOpen,
		goal,
		onDismiss,
		close,
		setGoal,
	}), [ isOpen, goal, close ])

	return (
		<goalContext.Provider value={ctx}>
			{ children }
		</goalContext.Provider>
	)
}
