import React, { useEffect, useRef } from 'react'
import clsx from 'clsx';

import { makeStyles, Theme } from '@material-ui/core'
import useResponsively from '@hooks/useResponsively';

type StyleProps = {}
const useStyles = makeStyles<Theme, StyleProps>(theme => ({
	// These styles contain a scrollbar hiding hack.
	// - root has its height set, and overflow hidden.
	// - navList, a child of root, has its padding pushed out
	//   by the height of the scrollbar so that it overflows,
	//   and gets hidden away.
	outer: {
		overflowY: 'hidden',
	},
	scrollElem: {
		overflowX: 'scroll',
		overflowY: 'hidden',
		padding: theme.spacing(0, 0, 4),
	},
	inner: {
		display: 'inline-flex',
		flexDirection: 'row',
		flexShrink: 0,
		flexGrow: 0,
		flexWrap: 'nowrap',
		gap: theme.spacing(1),
	}
}), { name: 'HorizontalScroll' })

interface HorizontalScrollProps {
	children: React.ReactNode;
	outerClassName?: string;
	innerClassName?: string;
	margin?: number;
}
const HorizontalScroll: React.FC<HorizontalScrollProps> = (props) => {
	const {
		children,
		outerClassName,
		innerClassName,
	} = props;

	const scrollElem = useRef<HTMLDivElement>(null);
	const container = useRef<HTMLDivElement>(null);
	const { height } = useResponsively(container);

	useEffect(() => {
		const handleScroll = (e: WheelEvent) => {
			if (!scrollElem.current) return;
			if (e.deltaY === 0) return;

			//	fix bug that prevents virtical scrolling.
			if (scrollElem.current.scrollLeft + e.deltaY < 0) {
				scrollElem.current.scrollLeft = 0;
				return;
			}

			const endOfScroll = scrollElem.current.scrollWidth - scrollElem.current.offsetWidth;
			if (scrollElem.current.scrollLeft + e.deltaY > endOfScroll) {
				scrollElem.current.scrollLeft = endOfScroll;
				return;
			}

			e.preventDefault();
			scrollElem.current.scrollLeft += e.deltaY;
		}

    scrollElem.current?.addEventListener('wheel', handleScroll);
		return () => { scrollElem.current?.removeEventListener('wheel', handleScroll) };
	}, [ scrollElem.current, container.current ])

	const classes = useStyles();
	return (
		<div className={ clsx(classes.outer, outerClassName) } style={{ height }}>
			<div className={ classes.scrollElem } ref={ scrollElem }>
				<div className={ clsx(classes.inner, innerClassName) } ref={ container }>
					{ children }
				</div>
			</div>
		</div>
	)
}

export default HorizontalScroll
