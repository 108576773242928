import { useCallback, useEffect } from "react";
import useEffectCallback from "./useEffectCallback";

type EventHandler = () => void;
type EventTrigger = () => void;

const eventStore = new Map<string, EventHandler[]>()

// TODO: Add event properties to the trigger that passes the values to the handlers.

export const useEventHandler = (eventNames: string | string[], handler?: EventHandler, deps: any[] = []): void => {
	const handlerMap = useCallback(() => {
		//	makes sure the eventNames is an array of unique strings.
		const eventList = Array.isArray(eventNames) ?
			[...(new Set<string>(eventNames))] :
			[eventNames];

		//	maps the unique events to their list in the store
		return eventList.map<[string, EventHandler[]]>(event => {
			//	ensures if the event was defined above,
			//	then there is at least an empty array for it.
			return [event, eventStore.get(event) ?? []]
		});
	}, [eventNames]);

	useEffect(() => {
		if (handler) {
			handlerMap()
				.forEach(([event, handlers]) => 
					eventStore.set(event, [...handlers, handler])
				);
		}

		return () => {
			handlerMap()
				.forEach(([event, handlers]) => {
					eventStore.set(
						event,
						handlers.filter(storedHandler => storedHandler !== handler)
					)
				});
		}
	}, [...deps, eventNames, handler]);
}


export const useEventTrigger = (eventName: string, deps?: any[]): EventTrigger => {
	const trigger = useEffectCallback(() => {
		const handlers = eventStore.get(eventName) ?? [];
		handlers.map(handler => handler());
	}, deps ?? [])

	return trigger as EventTrigger
}