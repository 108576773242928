import { useContext,useState } from 'react';
import { useAPI } from '@hooks/useRequest';
import { useSnackTime } from '@components/SnackToast';
import learnSearchContext from '@context/LearnSearchProvider';

const useBookmark = (resource) => {

  const axios = useAPI();
  const snack = useSnackTime(5000);

  const learnSearchCtx = useContext(learnSearchContext);
	const {searchResults} = learnSearchCtx;
  const [individualLearnContent, setIndividualLearnContent] = useState(resource);


  
  const searchResultsUpdate = (data) => {
    if(searchResults){
      const index = searchResults.findIndex(result => result.id === individualLearnContent.id);
      if (index !== -1) {
        searchResults[index].isBookmark = data ? true : false;
        searchResults[index].bookmarkId = data ? data.bookmarkId : undefined;
        searchResults[index].bookmarkDate = data ? data.bookmarkDate : undefined;
      }
    }
  }

  const handleBookmarkClick = async () =>{
    snack.openSnack();
    const data = await axios.post('/v1/Wellbook/Bookmark', {
      learnContentId: individualLearnContent.id,
      modality: individualLearnContent.modality
		})
    setIndividualLearnContent({...individualLearnContent,isBookmark: true,bookmarkId:data.bookmarkId, bookmarkDate:data.bookmarkDate })
    searchResultsUpdate(data);
  }

  const handleUnBookmarkClick = async () =>{
    await axios.post('/v1/Wellbook/Bookmark/Remove', {
			bookmarkId: individualLearnContent.bookmarkId,
		})
    setIndividualLearnContent({...individualLearnContent,isBookmark: false})
    searchResultsUpdate();
  }

  return {
    handleBookmarkClick,
    handleUnBookmarkClick,
    setIndividualLearnContent,
    individualLearnContent,
    snack
  }
};

export default useBookmark;