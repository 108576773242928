import React, { useState, useEffect, useMemo, useContext } from 'react'
import { Container, makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { useAPI } from '@hooks/useRequest'
import assessmentModalContext from '@context/AssessmentModalProvider'
import Mixpanel from '@utils/mixpanel'
import clsx from 'clsx'

import Header from '@components/Header'
import Recommendations from '@components/Recommendations'
import AppNotification from '@components/AppNotification'
import Markdown from '@components/MarkdownToJsx';

import Typography from '@ui/Typography'
import Button from '@ui/Button'

import HistoricAssessmentResults from '../HistoricAssessmentResults'
import InstrumentResults from '../InstrumentResults'
import Footer from '../Footer'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    width: '100%',
  },
	wrapper: {
		width: '100%',
		height: '100%',
		overflow: 'hidden',
		display: 'flex',
		justifyContent: 'stretch',
	},
	main: {
		overflowY: 'auto',
		width: '100%',
	},
	seekHelp: {
		position: 'relative',
		padding: theme.spacing(2, 0, 2.25),
	},
	seekHelpCopy: {
		marginBottom: theme.spacing(1.5)
	},
	dismiss: {
		display: 'flex',
		justifyContent: 'space-between',
		'& span': {
			display: 'flex',
			alignItems: 'center',
			cursor: 'pointer',
			verticalAlign: 'center',
		},
	},
}), {name: 'AssessmentResult'})

const actionTypeMap = (type, action) => {
	switch (type) {
		case 'Email': return `mailto:${action}`;
		case 'Tel': return `tel:${action}`;
		default: return action;
	}
}

const AssessmentResult = (props) => {
	const {
		initialInstrument,
		answerSets = {},
		insideAssessment = false,
	} = props;

	const axios = useAPI();
	const classes = useStyles();
	const modal = useContext(assessmentModalContext);

	//	Recommendations for Result page.
	const [recData, setRecData] = useState();
	const [ instrumentId, setInstrumentId ] = useState(initialInstrument);
	const unsetInstrumentId = () => setInstrumentId(undefined);
	const handleClickInstrument = instrumentId => setInstrumentId(instrumentId);

	const answerSetId = useMemo(() => answerSets?.answerSetId, [ answerSets?.answerSetId ]);

	const shouldBackButtonShow = useMemo(() => {
		if (!insideAssessment) return false;
		if (!instrumentId) return false;
		return true;
	}, [insideAssessment, answerSets?.marks, instrumentId]);

	const handleClickSeekHelp = () => {
		axios.post('/SeekHelpActionTaken');
	}

	const loadFullResultsPage = async () => {
		const recData = await axios.get(`/Recommendation/ByAssessment/${answerSets.assessmentId}/6`)
		setRecData(recData);

		if (recData.seekHelp) {
			setTimeout(() => setSeekHelp(true), 500);
		}
	}

	const loadSpecificResultAnswers = async () => {
		//	Set initial ID, in some cases
		if (answerSets?.marks?.length === 1)
			setInstrumentId(answerSets?.marks[0].instrumentId)
	}

	useEffect(() => {
		//	Control back button
		modal.updateModalProps({
			onBack: shouldBackButtonShow ? unsetInstrumentId : undefined
		})

		//	Mixpanel Tracking
		if (instrumentId) {
			Mixpanel.track('View answers', {
				entryPoint: (() => {
					if (insideAssessment) return 'Questionnaire Results'
					if (window.location.pathname === '/') return 'Homepage'
					if (window.location.pathname === '/health-tracker') return 'Health Tracker'
				})(),
				instrumentName: answerSets?.marks?.filter(mark => mark.instrumentId === instrumentId)[0].label,
				questionnaireType: (answerSets?.assessmentTitle === 'Full Questionnaire') ? answerSets?.assessmentTitle : 'Questionnaire',
			});
		}
	}, [instrumentId, shouldBackButtonShow]);

	const seekHelp = recData?.seekHelp;
	const [isShowingSeekHelp, setSeekHelp] = useState(false);
	useEffect(() => {
		if (!seekHelp) return;
		setTimeout(() => setSeekHelp(true), 500);
	}, [seekHelp]);

	useEffect(async () => {
		insideAssessment ?
			loadFullResultsPage() :
			loadSpecificResultAnswers();
	}, []);


	return (
		<div className={classes.root}>
			<div className={classes.wrapper}>
				<div className={classes.main}>
					<AppNotification isOpen={isShowingSeekHelp}>
						{
							seekHelp && (
								<div className={classes.seekHelp}>
									<Typography type='heading' className={clsx(classes.seekHelpCopy, classes.dismiss)}>
										{seekHelp.title}
										<span onClick={ () => setSeekHelp(false) }>
											<CloseIcon fontSize='small' />
										</span>
									</Typography>
                                    {seekHelp.markdownDescription ? 
                                        (
                                            <Markdown noWrapper children={seekHelp.markdownDescription} />
                                        ) : 
                                        (
                                            <Typography className={classes.seekHelpCopy}>
                                                {seekHelp.description}
                                            </Typography>
                                        )
                                    }
									{
										seekHelp.actions[0] && (
											<Button
												onClick={() => {
													handleClickSeekHelp();
													if (seekHelp.actions[0].type === 'Link') {
														modal.close();
													}
												}}
												target="_blank"
												href={actionTypeMap(seekHelp.actions[0].type, seekHelp.actions[0].action)}
												theme='callout'>
												{seekHelp.actions[0].label}
											</Button>
										)
									}
								</div>
							)
						}
					</AppNotification>

					{(!instrumentId) && (<Header title='Results'/>)}

					<Container maxWidth="md">
						{
							!instrumentId && (
								<>
									{
										recData?.recommendations?.length && (
											<Recommendations
												insideAssessment
												data={recData}
												onNavigate={ modal.close }
												onViewAnswers={ handleClickInstrument }
											/>
										)
									}

									<HistoricAssessmentResults
										answerSetId={ answerSetId }
										marks={ answerSets?.marks }
										assessmentSubmissionTime={ !insideAssessment && answerSets?.assessmentSubmissionTime }

										onClick={ handleClickInstrument }
									/>
								</>
							)
						}

						{
							instrumentId && (
								<InstrumentResults
									key={ instrumentId }
									answerSetId={ answerSetId }
									instrumentId={ instrumentId }

									onClick={ handleClickInstrument }
								/>
							)
						}
					</Container>
				</div>
			</div>
			<Footer
				centered
				isOpen
				maxWidth="md"
				onClickPrev={modal.close}
				progress={{ max: 1, filled: 1 }}
			/>
		</div>
	)
}

export default AssessmentResult
