import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core';
import Typography from '@ui/Typography';
import { Link } from 'react-router-dom'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';


interface TBackButton {
  includeBackArrow: boolean;
  title?: string;
  handleBack?: () => void;
  isBackToHistory?: boolean;
}

const goBack = () => {
  history.back();
}

const useStyles = makeStyles((theme) => ({
  back: {
    marginTop: theme.spacing(8.75),
    marginBottom: theme.spacing(2.5),
  },
  backArrow: {
    fontSize: theme.spacing(1.5),
    marginRight: theme.spacing(1)
  },
  link: {
		color: theme.palette.color.navy[100],
	}
}));

const BackButton = (props:TBackButton) => {

  const { 
    includeBackArrow, 
    title="Back",
    handleBack,
    isBackToHistory
  } = props;

  const history = useHistory();
  const classes = useStyles();

  const handleDefaultBack = (e:React.MouseEvent<HTMLAnchorElement>) => {
    if(!isBackToHistory)
    {
      history.push('/');
    }
    else{
      e.preventDefault();
      goBack();
    }
  }

  return (
    <Typography type='body' variant='primary' className={classes.back}>
      <Link onClick={handleBack ? handleBack : handleDefaultBack} className={classes.link} to={''} >{includeBackArrow && <ArrowBackIosIcon className={classes.backArrow} />} {title} </Link>
    </Typography>
  );
};

export default BackButton;
