import React from 'react'
import clsx from 'clsx'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { CardActionArea } from '@material-ui/core'
import Card from '@ui/Card'

interface styleProps { }

const useStyles = makeStyles<Theme, styleProps>(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		
		width: theme.spacing(25),
		height: theme.spacing(37.5),
		borderRadius: theme.spacing(2.5),
	},

	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
	}
}))

interface RecommendationCardProps {
	className?: string;
	onClick?: () => unknown;
	children?: React.ReactNode;
}

const RecommendationCard: React.FC<RecommendationCardProps> = (props) => {
	const { onClick, className, children } = props;
	const useWrapper = typeof onClick === 'function';
	
	const classes = useStyles({ useWrapper });
	
	return (
		<Card
			elevation={1}
			className={clsx(classes.root, className)}
			onClick={ () => onClick?.() }
		>
			{
				useWrapper ? (
					<CardActionArea className={classes.container}>
						{ children }
					</CardActionArea>
				) : children
			}
		</Card>
	)
}

export default RecommendationCard
