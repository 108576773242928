// https://material-ui.com/components/buttons/#button
// by default the button is medium 
import React from 'react';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  MuiButton: {
    root: {
      visibility: ({ hide }) => hide ? 'hidden' : '',
      marginTop: ({ gutterTop }) => {
        if (gutterTop === true) return theme.spacing(2.5);
        if (typeof gutterTop === 'number') return theme.spacing(gutterTop);
        return 0;
      },
    }
  },
  loadingSpinner: {
    marginLeft: theme.spacing(1),
  },
}))

const themes = {
  primary: {
    color: 'primary',
    variant: 'contained',
  },
  secondary: {
    color: 'secondary',
    variant: 'contained',
  },
  callout: {
    variant: 'outlined'
  },
  link: {
    variant: 'text',
  },
  wide: {
    fullWidth: true,
  },
  large: {
    size: 'large',
  },
  small: {
    size: 'small',
  },
}

const Button = (props) => {
  const {
    children,
    theme = 'primary',
    gutterTop = 0,
    isLoading = false,

    href,
    onClick,

    hide = false,
    wide = false,
    large = false,
    medium=false,
    small = false,

    classes,
    className,
    ...otherProps
  } = props;

  const classStyles = useStyles({ gutterTop, hide });
  const history = useHistory();
  const isLarge = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const classList = clsx(
    classStyles.root,
    classes,
    className,
  );

  const internalProps = { ...themes[theme] };
  //condition to handle when we specifically pass medium or large 
  if ((isLarge && !medium) || large) Object.assign(internalProps, themes.large);
  if (small) Object.assign(internalProps, themes.small);
  if (wide) Object.assign(internalProps, themes.wide);

  if (isLoading) otherProps.disabled = true;

  const handleClick = e => {
    if (typeof onClick === 'function') onClick(e);

    if (typeof href === 'string') {
      const isHttp = href.startsWith('http:') || href.startsWith('https:');
      const isMail = href.startsWith('mailto:');
      const isNumb = href.startsWith('tel:');

      if (!isHttp && !isMail && !isNumb) {
        history.push(href);
        e.preventDefault();
      }
    }
  }

  return (
    <MuiButton
      { ...internalProps }
      {...otherProps}
      className={ classList }

      href={href}
      onClick={handleClick}>
        { children }
        { isLoading && <CircularProgress size={16} className={ classStyles.loadingSpinner } /> }
    </MuiButton>
  );
}

export default Button;